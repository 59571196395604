import { Color } from '@solid/shared/styles/colors';
import { Button } from '@solid/shared/ui';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import PlanBadge from '../common/PlanBadge';

export const FavoriteButton = styled(Button)`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 1.75rem;
  height: 1.75rem;
  min-width: 0;
  color: ${Color.Blue_500_Primary};

  &:hover {
    background-color: ${Color.Neutral_200_OffWhite} !important;
  }
`;

export const PlanTag = styled(PlanBadge)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

export const Question = styled.h3`
  font-weight: ${props => props.theme.fontWeight.semibold};
  margin: 0.5rem 0 1.5rem;
  font-size: 0.875rem;
  min-height: 45px;
`;

export const FlagWrapper = styled.div`
  width: 60px;
  margin: 0 auto;
`;

export const StartButton = styled(Button)`
  margin-top: auto;
  min-width: 0;
`;

export const Checkup = styled.div<{ $isLocked: boolean; $hasShadow: boolean; $isGhost?: boolean }>`
  position: relative;
  border-radius: ${props => props.theme.borderRadius.small};
  background-color: ${Color.Neutral_0_White};
  padding: 2.25rem 0.5rem 1.25rem;
  margin: 0.5rem 0.5rem;
  flex: 1 0 10rem;
  text-align: center;
  border-top: 5px solid transparent;
  color: ${Color.Neutral_1000_Black};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  box-shadow: ${props => (props.$hasShadow ? '0 4px 8px 0 rgba(6, 28, 51, 0.13)' : 'none')};

  ${props =>
    props.$isGhost &&
    css`
      height: 0;
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
      border: none;
    `}

  ${props =>
    props.$isLocked &&
    css`
      background-color: ${transparentize(0.6, Color.Neutral_0_White)};

      > * {
        opacity: 0.4;
      }

      .btn,
      ${PlanTag} {
        opacity: 1;
      }
    `}
`;

export const UnlistedOverlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.9) 40%,
    rgba(255, 255, 255, 0.9) 70%,
    rgba(255, 255, 255, 0.8) 100%
  );
  align-items: center;
  justify-content: center;
  overflow: hidden;

  p {
    font-size: 1.5rem;
    font-weight: bold;
    transform: rotateZ(-10deg);
    padding: 0.875rem;
    color: ${Color.Blue_700};
  }
`;
