import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../navigation/Footer';
import Navbar from '../navigation/Navbar';
import * as Styled from './FourOhFourPage.styles';
import Layout from './Layout';

const FourOhFourPage: React.FC = () => {
  return (
    <>
      <Navbar />
      <Layout>
        <Styled.Container>
          <h1>Seite nicht gefunden</h1>
          <p>Leider können wir die Seite nicht finden, die Sie gesucht haben.</p>
          <Link to='/'>
            <Styled.Btn>Zurück zur Startseite</Styled.Btn>
          </Link>
        </Styled.Container>
      </Layout>
      <Footer />
    </>
  );
};

export default FourOhFourPage;
