import React from 'react';
import * as Styled from './LoadingSpinner.styles';

interface LoadingIndicatorProps {
  themeColor?: Styled.LoadingIndicatorProps['$themeColor'];
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ themeColor = 'contrast' }) => {
  return <Styled.Indicator $themeColor={themeColor} />;
};

export default LoadingIndicator;
