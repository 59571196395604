import { KirbyCheck, KirbyCheckGroup, KirbyInfoGeneral, KirbyInfoPlus } from '@solid/shared/kirby';
import { useQuery } from '@tanstack/react-query';
import {
  KIRBY_CACHE_KEY,
  getKirbyCheckGroups,
  getKirbyChecks,
  getKirbyInfoGeneral,
  getKirbyInfoPlus,
  getKirbyPlaceholderDataFromCache,
} from '../services/kirby.service';
import { useContext } from 'react';
import { AuthContext } from '../contexts/auth.context';

export const useKirbyCheckGroups = () => {
  const { auth } = useContext(AuthContext);
  return useQuery(['kirby-check-groups', auth?.user.id], getKirbyCheckGroups, {
    placeholderData: getKirbyPlaceholderDataFromCache(KIRBY_CACHE_KEY.CHECK_GROUPS) as KirbyCheckGroup[],
    staleTime: Infinity,
  });
};

export const useKirbyInfoGeneral = () =>
  useQuery(['kirby-info-general'], getKirbyInfoGeneral, {
    placeholderData: getKirbyPlaceholderDataFromCache(KIRBY_CACHE_KEY.INFO_GENERAL) as KirbyInfoGeneral,
    staleTime: Infinity,
  });

export const useKirbyInfoPlus = () => {
  const { auth } = useContext(AuthContext);
  return useQuery(['kirby-info-plus', auth?.user.id], getKirbyInfoPlus, {
    placeholderData: getKirbyPlaceholderDataFromCache(KIRBY_CACHE_KEY.INFO_PLUS) as KirbyInfoPlus,
    staleTime: Infinity,
    enabled: auth?.user.id !== undefined,
  });
};

export const useKirbyChecks = () => {
  const { auth } = useContext(AuthContext);
  return useQuery(['kirby-checks', auth?.user.id], () => getKirbyChecks(), {
    placeholderData: getKirbyPlaceholderDataFromCache(KIRBY_CACHE_KEY.CHECKS) as KirbyCheck[],
    staleTime: Infinity,
    enabled: auth?.user.id !== undefined,
  });
};
