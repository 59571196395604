import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useSearchParam = (param: string) => {
  const location = useLocation();

  return useMemo<string | null>(() => {
    return new URLSearchParams(location.search).get(param);
  }, [location.search, param]);
};
