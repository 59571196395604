import QueryClientProvider from '@solid/shared/QueryClientProvider';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RouterProvider } from 'react-router-dom';
import PaddleListener from './components/global/PaddleListener';
import PaymentPopupContainer from './containers/PaymentPopupContainer';
import UserContainer from './containers/UserContainer';
import { router } from './router';
import { setupPaddle } from './services/payment.service';
import { ThemeProvider } from 'styled-components';
import { theme } from '@solid/shared/styles/theme';

export const AppWrapper: React.FC = () => {
  useEffect(() => {
    setupPaddle();
  }, []);

  return (
    <QueryClientProvider>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>SOLID</title>
        </Helmet>
        <UserContainer>
          <PaymentPopupContainer>
            <RouterProvider router={router} />
            <PaddleListener />
          </PaymentPopupContainer>
        </UserContainer>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default AppWrapper;
