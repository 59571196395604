import styled, { css } from 'styled-components';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

// TODO properly convert to styled-components
export const Footer = styled.footer`
  background: ${props => props.theme.gradient.base};
  padding: 4rem 0;
  @media (max-width: 767.98px) {
    text-align: center;
  }
  @media print {
    display: none;
  }
`;

export const FooterWrapper = styled.div`
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
`;

export const SmallWrapper = styled.div`
  width: 950px;
  max-width: 100%;
  margin: 0 auto;
  @media (min-width: 768px) {
    padding: 0 15px;
  }
`;

export const AdjustedLogo = styled(Logo)`
  display: block;
  width: 120px;
`;

export const Line = styled.hr`
  margin: 50px 0;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background: none;
`;

export const Topics = styled.div`
  display: flex;
  @media (max-width: 767.98px) {
    flex-direction: column;
  }

  h5 {
    color: white;
    margin-bottom: 1rem;
    @media (max-width: 767.98px) {
      font-size: 1.125em;
    }
  }

  p,
  a {
    line-height: 1.63;
    color: white;
    font-weight: ${props => props.theme.fontWeight.light};
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Col = styled.div<{ $type?: 'aeb' }>`
  margin: 0 0 1rem;
  @media (min-width: 768px) {
    margin: 0 3rem 0 0;
  }
  &:last-of-type {
    margin-right: 0;
  }

  ${props =>
    props.$type === 'aeb' &&
    css`
      @media (min-width: 768px) {
        margin-left: auto;
      }
    `};
`;

export const SocialLink = styled.a`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.6;
  }

  img {
    display: block;
    width: 2rem;
    height: 2rem;
  }
`;
