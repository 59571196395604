import React from 'react';
import Layout from '../global/Layout';
import * as Styled from './SeminarPage.styles';

interface SeminarPageProps {
  url: string;
  title: string;
}

const SeminarPage: React.FC<SeminarPageProps> = ({ title, url }) => {
  return (
    <Layout showSidebar>
      <h1>{title}</h1>
      <Styled.IFrame title='SOLID Seminare' src={url} />
    </Layout>
  );
};

export default SeminarPage;
