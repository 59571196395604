import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { Color } from '@solid/shared/styles/colors';

export const TextareaContainer = styled.div<{ $isLocked: boolean }>`
  position: relative;

  ${props =>
    props.$isLocked &&
    css`
      border-bottom: none;
      > textarea {
        resize: none;
      }
    `}
`;

export const NoteTextarea = styled.textarea`
  max-height: 22rem;
  resize: vertical;
  padding: ${props => props.theme.formElementsPaddingX};
`;

export const ReadOnly = styled.div`
  width: 100%;
  padding: 0.75rem 0.5rem;
  margin-top: 0.5rem;
  border-radius: ${props => props.theme.borderRadius.small};
  background: ${transparentize(0.95, Color.Blue_500_Primary)};
  white-space: pre-wrap;
`;

export const PaywallOverlay = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2.5rem;
  background: ${transparentize(0.95, Color.Blue_500_Primary)};
  z-index: 1;

  > p:first-child {
    margin-top: 0;
    text-align: center;
    font-size: 1rem;
  }
  > button {
    border-radius: ${props => props.theme.borderRadius} !important;
  }
`;
