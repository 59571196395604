export enum Color {
  Blue_100 = '#D0EAFD',
  Blue_200 = '#A0D5FA',
  Blue_300 = '#70C0F8',
  Blue_400 = '#58B5F7',
  Blue_500_Primary = '#40AAF5',
  Blue_600 = '#3C9ADE',
  Blue_700 = '#2F6898',
  Blue_800 = '#264769',
  Blue_900 = '#223752',
  Blue_1000_Deep = '#1D263A',

  Neutral_0_White = '#FFFFFF',
  Neutral_100 = '#f8f8f8',
  Neutral_200_OffWhite = '#F3F3F3',
  Neutral_300 = '#E4E4E4',
  Neutral_400 = '#D5D5D5',
  Neutral_500 = '#CACACA',
  Neutral_600 = '#979797',
  Neutral_700 = '#6A6A6A',
  Neutral_800 = '#4C4C4C',
  Neutral_900 = '#2D2D2D',
  Neutral_1000_Black = '#000000',

  Green_100 = '#C8F3E0',
  Green_200 = '#90E6C1',
  Green_300 = '#58DAA2',
  Green_400 = '#3CD493',
  Green_500_Success = '#20CD83',
  Green_600 = '#1CB473',
  Green_700 = '#189A63',
  Green_800 = '#148153',
  Green_900 = '#106742',
  Green_1000 = '#083421',

  Red_100 = '#FDCCCD',
  Red_200 = '#FCB2B4',
  Red_300 = '#FA989A',
  Red_400 = '#F86467',
  Red_500_Danger = '#F53034',
  Red_600 = '#B82427',
  Red_700 = '#9A1E21',
  Red_800 = '#7B181A',
  Red_900 = '#5D1214',
  Red_1000 = '#3E0C0D',

  Yellow_100 = '#FFECD0',
  Yellow_200 = '#FFE2B8',
  Yellow_300 = '#FFD8A0',
  Yellow_400 = '#FFC470',
  Yellow_500_Warning = '#FFB040',
  Yellow_600 = '#C08430',
  Yellow_700 = '#A06E28',
  Yellow_800 = '#805820',
  Yellow_900 = '#604218',
  Yellow_1000 = '#402C10',
}
