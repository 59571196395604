import styled from 'styled-components';

export const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  padding-top: calc(${props => props.theme.navbarHeightMobile} + 1.5rem);
  padding-bottom: 1.5rem;
  min-height: 50vh;
  margin: 0 auto;

  @media (${props => props.theme.breakpoint.md.up}) {
    padding-top: calc(${props => props.theme.navbarHeight} + ${props => props.theme.wrapperPadding});
    padding-bottom: ${props => props.theme.wrapperPadding};
  }

  @media (${props => props.theme.breakpoint.lg.up}) {
    flex-direction: row;
  }

  @media print {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

export const Content = styled.main`
  flex-grow: 1;
`;
