import { Color } from '@solid/shared/styles/colors';
import { transparentize } from 'polished';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  border: solid 1px ${Color.Blue_500_Primary};
  border-width: 0 0 1px 0;

  @media (${props => props.theme.breakpoint.lg.up}) {
    position: sticky;
    top: ${props => props.theme.navbarHeightShrinked};
    flex: 0 0 240px;
    width: 240px;
    border-width: 0 1px 0 0;
  }

  @media (${props => props.theme.breakpoint.md.down}) {
    max-height: none !important;
  }
`;

export const Top = styled.div`
  overflow: auto;
`;

export const Item = styled(NavLink)<{ $isSubItem?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  margin: 6px 0;
  border: none;
  border-left: 2px solid transparent;
  background: transparent;
  color: ${Color.Blue_100};
  font-weight: ${props => props.theme.fontWeight.regular};
  line-height: 1.25;
  transition: 0.2s ease;
  appearance: none;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background: ${transparentize(0.9, Color.Blue_500_Primary)};
    text-decoration: none;
  }

  &:active {
    background: ${transparentize(0.7, Color.Blue_500_Primary)};
  }

  svg {
    margin-right: 16px;
  }

  &.active {
    border-left: 2px solid ${Color.Blue_500_Primary};
  }

  ${props =>
    props.$isSubItem &&
    css`
      margin: 0 0 2px 16px;
      width: calc(100% - 16px);
      padding: 10px 20px 10px 26px;
    `}
`;

export const Bottom = styled.div<{ $isScrollable?: boolean }>`
  padding-top: 8px;
  z-index: 1;
  ${props =>
    props.$isScrollable &&
    css`
      box-shadow: 0px -22px 20px -22px rgba(${Color.Neutral_800}, 0.65);
    `}
`;

export const Badge = styled.span`
  margin-left: auto;
`;
