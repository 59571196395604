import styled from 'styled-components';
import { Color } from '@solid/shared/styles/colors';
import Icon from '@mdi/react';
import { transparentize } from 'polished';

export const Group = styled.div`
  border-radius: ${props => props.theme.borderRadius.small};
  overflow: hidden;
  margin-bottom: 1rem;
  color: ${Color.Neutral_800};
  background-color: ${Color.Neutral_0_White};

  @media (${props => props.theme.breakpoint.sm.up}) {
    margin-bottom: 1.5rem;
  }

  h4 {
    margin: 0;
  }
`;

export const GroupHeader = styled.button`
  display: flex;
  width: 100%;
  border: none;
  flex-direction: row;
  align-items: center;
  padding: 1rem 1.25rem;
  cursor: pointer;
  appearance: none;
  background: transparent;
  transition: ${props => props.theme.transitionTiming} background-color;

  &:hover {
    background-color: ${Color.Neutral_200_OffWhite};
  }

  &:focus {
    box-shadow: inset 0 0 0 3px rgba(${Color.Neutral_600}, 0.65) !important;
  }
`;

export const Chevron = styled(Icon)<{ $isOpen: boolean }>`
  margin-right: 0.5rem;
  transition: ${props => props.theme.transitionTiming} transform;
  transform: ${props => `rotate(${props.$isOpen ? 90 : 0}deg)`};
`;

export const FlagWrapper = styled.div`
  margin: -0.5rem 0.5rem -0.5rem 0;
  display: flex;

  img,
  svg {
    width: 40px;
    height: 40px;
  }
`;

export const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  min-width: 26px;
  padding: 0 8px;
  margin-left: auto;
  line-height: 1;
  border-radius: 999rem;
  background-color: ${transparentize(0.8, Color.Blue_500_Primary)};
  font-size: 14px;
`;

export const EmptyStateWrapper = styled.div`
  flex-grow: 1;
`;

export const ChecksContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.75rem 0.75rem 1rem;
  border-top: 1px solid ${transparentize(0.95, Color.Neutral_1000_Black)};
  background-color: ${Color.Neutral_200_OffWhite};

  @media (${props => props.theme.breakpoint.sm.up}) {
    justify-content: flex-start;
  }
`;

export const Checkup = styled.div`
  box-shadow: 0 0 0 10px rgba(${Color.Red_500_Danger}, 0.5);
`;
