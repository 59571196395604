import { Color } from '@solid/shared/styles/colors';
import styled from 'styled-components';

export const Headline = styled.h2`
  text-align: center;
  margin: 0 auto;
`;

export const Container = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 20px;
  margin-top: 2rem;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const InfoText = styled.div`
  flex-basis: 50%;

  hr {
    border-color: ${Color.Neutral_500};
    background-color: ${Color.Neutral_500};
  }
`;

export const ResultContainer = styled.div`
  margin-top: 3rem;
`;

export const CurrentHitInfo = styled.div`
  text-align: center;
  font-size: ${props => props.theme.fontSize.h3Mobile};
`;

export const NoResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const NoResult = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 4.5rem;
  margin-top: 3rem;

  > span {
    font-size: ${props => props.theme.fontSize.h3Mobile};
    font-weight: ${props => props.theme.fontWeight.light};
    line-height: 1;
    @media (min-width: 768px) {
      font-size: ${props => props.theme.fontSize.h3};
    }
  }

  > svg {
    margin-top: 0.25rem;
    margin-left: 1rem;
    width: 4rem;
    height: 4rem;

    path {
      stroke-width: 4;
    }
  }
`;
