import { Button } from '@solid/shared/ui';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 52rem;
  margin: 0 auto;
  text-align: center;
`;

export const Btn = styled(Button)`
  margin-top: 2rem;
`;
