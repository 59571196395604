import { mdiAccountEdit, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import {
  ORGANIZATION_ROLE,
  ORGANIZATION_ROLE_COLOR,
  ORGANIZATION_ROLE_ICONS,
  ORGANIZATION_ROLE_NAME,
} from '@solid/shared';
import CreateInviteForm from '@solid/shared/components/org/CreateInviteForm';
import EditMemberForm from '@solid/shared/components/org/EditMemberForm';
import { DataGridColumn } from '@solid/shared/services/data-grid.service';
import {
  DELETE_MODE,
  OrganizationListMembersEntry,
  deleteOrganizationInvite,
  deleteOrganizationMember,
  getOrganizationInvites,
  getOrganizationMembers,
} from '@solid/shared/services/organization.service';
import { ActionsContainer, BadgesContainer } from '@solid/shared/styles/react-data-grid.styles';
import theme from '@solid/shared/styles/theme';
import { Badge, Button, Popup } from '@solid/shared/ui';
import ConfirmationDialog from '@solid/shared/ui/ConfirmationDialog';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { formatDistanceToNow } from 'date-fns';
import dateFnsLocaleDe from 'date-fns/locale/de';
import ms from 'ms';
import React, { useContext, useMemo, useState } from 'react';
import DataGrid, { Column, Row } from 'react-data-grid';
import { toast } from 'react-hot-toast';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth.context';
import { useOrganizationAbility } from '../../hooks/useOrganizationAbility';
import * as Styled from './OrgMembersPage.styles';

enum RowType {
  Member = 'Member',
  Invite = 'Invite',
}

enum OrgMemberBadge {
  Invited = 'Invited',
  Deleted = 'Deleted',
  Inactive = 'Inactive',
}

const OrgMemberBadgeLabel: Record<OrgMemberBadge, string> = {
  [OrgMemberBadge.Invited]: 'Eingeladen',
  [OrgMemberBadge.Deleted]: 'Inaktiv',
  [OrgMemberBadge.Inactive]: 'Untätig',
};

const OrgMemberBadgeTitle: Record<OrgMemberBadge, string> = {
  [OrgMemberBadge.Invited]: 'Dieser Nutzer wurde eingeladen, dieser Organisation beizutreten.',
  [OrgMemberBadge.Deleted]:
    'Dieser Nutzer wurde aus der Organisation entfernt. Abgeschlossene Prüfungen bleiben jedoch erhalten.',
  [OrgMemberBadge.Inactive]: 'Dieser Nutzer hat noch keine Prüfungen abgeschlossen.',
};

interface Row {
  id: string;
  type: RowType;
  name: string;
  email: string;
  role: ORGANIZATION_ROLE;
  createdAt: Date;
  expiresAt: Date | null;
  deletedAt: Date | null;
  lastActive: Date | null;
  absolvedChecksCount: number | null;
  badges: OrgMemberBadge[];
}

function getRowId(rowId: string) {
  return parseInt(rowId.split('-')[1]);
}

const OrgMembersPage: React.FC = () => {
  const { auth } = useContext(AuthContext);

  const ability = useOrganizationAbility();

  const [editMember, setEditMember] = useState<OrganizationListMembersEntry | null>(null);
  const [createInviteFormVisible, setCreateInviteFormVisible] = useState(false);
  const [deleteEntryPopupVisible, setDeleteEntryPopupVisible] = useState(false);
  const [deleteEntry, setDeleteEntry] = useState<Row | null>(null);
  const [deleteMode, setDeleteMode] = useState<DELETE_MODE>(DELETE_MODE.SOFT);

  const {
    data: invites,
    refetch: refetchInvites,
    isLoading: isLoadingInvites,
  } = useQuery(['organization-invites', auth.org.id], () => getOrganizationInvites(auth.org.id), {
    retry: false,
    staleTime: ms('30sec'),
  });

  const {
    data: members,
    refetch: refetchMembers,
    isLoading: isLoadingMembers,
  } = useQuery(['organization-members', auth.org.id], () => getOrganizationMembers(auth.org.id), {
    retry: false,
    staleTime: ms('30sec'),
  });

  const handleOnDelete = async () => {
    setDeleteEntryPopupVisible(false);

    let promise: Promise<unknown>;
    if (deleteEntry.type === RowType.Invite) {
      promise = deleteOrganizationInvite(auth.org.id, getRowId(deleteEntry.id)).then(() => refetchInvites());
    } else {
      promise = deleteOrganizationMember(auth.org.id, getRowId(deleteEntry.id), { mode: deleteMode }).then(() =>
        refetchMembers()
      );
    }
    toast.promise(promise, {
      loading: `Lösche Eintrag "${deleteEntry.email}"...`,
      success: `Eintrag "${deleteEntry.email}" erfolgreich gelöscht.`,
      error: e => `Eintrag "${deleteEntry.email}" konnte nicht gelöscht werden: ${e.message}`,
    });

    return promise;
  };

  const currentMemberCount = members?.filter(member => !member.deletedAt).length ?? 0;

  const columns = useMemo(
    (): readonly (Column<Row> & DataGridColumn<Row>)[] => [
      {
        key: 'type',
        name: 'Typ',
        width: 100,
        renderCell: props => (props.row.type === RowType.Invite ? 'Einladung' : 'Mitglied'),
      },
      {
        key: 'name',
        name: 'Name',
        resizable: true,
      },
      {
        key: 'email',
        name: 'E-Mail-Adresse',
        width: 200,
        resizable: true,
      },
      {
        key: 'role',
        name: 'Rolle',
        width: 150,
        renderCell: props => (
          <BadgesContainer>
            <Badge color={ORGANIZATION_ROLE_COLOR[props.row.role]}>
              <Icon
                path={ORGANIZATION_ROLE_ICONS[props.row.role]}
                size={0.75}
                style={{ margin: '-2px 3px -2px -2px' }}
              />
              <span>{ORGANIZATION_ROLE_NAME[props.row.role]}</span>
            </Badge>
          </BadgesContainer>
        ),
      },
      {
        key: 'lastActive',
        name: 'Letzte Aktivität',
        width: 100,
        renderCell: props =>
          props.row.type === RowType.Member && props.row.lastActive ? props.row.lastActive.toLocaleDateString() : '-',
      },
      {
        key: 'absolvedChecksCount',
        name: 'Prüfungen',
        width: 100,
        renderCell: props => props.row.absolvedChecksCount,
      },
      {
        key: 'createdAt',
        name: 'Erstelldatum / Beitrittsdatum',
        width: 100,
        renderCell: props => props.row.createdAt.toLocaleDateString(),
      },
      {
        key: 'expiresAt',
        name: 'Ablaufdatum',
        width: 100,
        renderCell: props =>
          props.row.type === RowType.Invite ? (
            <time dateTime={props.row.expiresAt.toISOString()} title={props.row.expiresAt.toLocaleString()}>
              {formatDistanceToNow(props.row.expiresAt, { locale: dateFnsLocaleDe, addSuffix: true })}
            </time>
          ) : (
            '-'
          ),
      },
      {
        key: 'badges',
        name: 'Status',
        width: 100,
        resizable: true,
        renderCell: entry => (
          <BadgesContainer>
            {entry.row.badges.map(badge => (
              <Badge title={OrgMemberBadgeTitle[badge]}>{OrgMemberBadgeLabel[badge]}</Badge>
            ))}
          </BadgesContainer>
        ),
      },
      {
        key: '_actions',
        name: 'Aktionen',
        width: 180,
        renderCell: props => {
          if (props.row.deletedAt) {
            return null;
          }
          const member = props.row.type === RowType.Member ? members?.find(m => m.id === getRowId(props.row.id)) : null;

          const isSelf = member?.userId === auth.user.id;
          const isLocalAdmin = member?.role === ORGANIZATION_ROLE.LOCAL_ADMIN;
          return (
            <ActionsContainer>
              {member && (
                <Button isSmall hasIconOnly onClick={() => setEditMember(member)} disabled={isSelf}>
                  <Icon path={mdiAccountEdit} size={0.75} />
                </Button>
              )}
              <Button
                isSmall
                hasIconOnly
                themeColor='danger'
                onClick={() => {
                  setDeleteEntry(props.row);
                  setDeleteEntryPopupVisible(true);
                }}
                disabled={isSelf || isLocalAdmin}>
                <Icon path={mdiTrashCan} size={0.75} />
              </Button>
            </ActionsContainer>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invites, members]
  );

  const rows = useMemo((): readonly Row[] => {
    if (!invites || !members) {
      return [];
    }

    return [
      ...invites.map(invite => ({
        id: `${RowType.Invite}-${invite.id}`,
        type: RowType.Invite,
        name: '-',
        email: invite.email,
        role: invite.role,
        createdAt: invite.createdAt,
        expiresAt: invite.expiresAt,
        deletedAt: null,
        lastActive: null,
        absolvedChecksCount: 0,
        badges: [OrgMemberBadge.Invited],
      })),
      ...members.map(member => {
        const badges: OrgMemberBadge[] = [];

        if (member.absolvedChecksCount === 0) {
          badges.push(OrgMemberBadge.Inactive);
        }
        if (member.deletedAt) {
          badges.push(OrgMemberBadge.Deleted);
        }
        return {
          /**
           * Makes sure the id is unique and doesn't collide with invites.
           * Also sorts the member rows after the invite rows.
           */
          id: `${RowType.Member}-${member.id}`,
          type: RowType.Member,
          name: member.user ? `${member.user.firstName} ${member.user.lastName}` : '-',
          email: member.user ? member.user.email : '-',
          role: member.role,
          createdAt: member.createdAt,
          expiresAt: null,
          deletedAt: member.deletedAt,
          lastActive: member.user?.lastActive ?? null,
          absolvedChecksCount: member.absolvedChecksCount,
          badges,
        };
      }),
    ].sort((a, b) => {
      if (a.type === b.type) {
        return getRowId(b.id) - getRowId(a.id);
      }
      return a.type === RowType.Invite ? -1 : 1;
    });
  }, [invites, members]);

  if (ability.cannot('read', 'Organization', 'members')) {
    return <Navigate to='/' />;
  }

  return (
    <>
      {(isLoadingInvites || isLoadingMembers) && <p>Lade Daten...</p>}
      <Styled.InfoActionsRow>
        <Styled.InfoContainer>
          <Styled.UserProgressBarContainer>
            <Styled.Members style={{ width: `${(currentMemberCount / auth.org.maxSeats) * 100}%` }} />
            <Styled.PendingInvites style={{ width: `${(invites?.length / auth.org.maxSeats) * 100}%` }} />
          </Styled.UserProgressBarContainer>
          <Styled.UserProgressBarInfo>
            <Styled.MembersInfo>
              {currentMemberCount ?? '-'} {currentMemberCount === 1 ? 'Mitglied' : 'Mitglieder'}
            </Styled.MembersInfo>
            <Styled.PendingInvitesInfo>
              {invites?.length ?? '-'} offene {invites?.length === 1 ? 'Einladung' : 'Einladungen'}
            </Styled.PendingInvitesInfo>
            <Styled.TotalMembersInfo>
              {currentMemberCount + invites?.length} / {auth.org.maxSeats}
            </Styled.TotalMembersInfo>
          </Styled.UserProgressBarInfo>
        </Styled.InfoContainer>
        <button className='btn is-primary' onClick={() => setCreateInviteFormVisible(true)}>
          Einladungen erstellen
        </button>
      </Styled.InfoActionsRow>

      {invites && members && (
        <DataGrid
          rowKeyGetter={row => row.id}
          className={classNames('rdg-light')}
          columns={columns}
          rows={rows}
          rowHeight={40}
          style={{ maxWidth: '840px', borderRadius: theme.borderRadius.small }}
          renderers={{
            renderRow: (key, props) =>
              props.row.deletedAt ? (
                <Styled.DeletedRow title={`Gelöscht am ${props.row.deletedAt.toLocaleString()}`} key={key} {...props} />
              ) : (
                <Row key={key} {...props} />
              ),
          }}
        />
      )}

      <Popup
        isOpen={createInviteFormVisible}
        onClose={() => {
          setCreateInviteFormVisible(false);
          refetchInvites();
          refetchMembers();
        }}>
        <CreateInviteForm
          org={auth.org}
          invites={invites ?? []}
          currentMemberCount={currentMemberCount}
          onClose={() => {
            setCreateInviteFormVisible(false);
            refetchInvites();
            refetchMembers();
          }}
        />
      </Popup>
      <Popup
        isOpen={!!editMember}
        onClose={() => {
          setEditMember(null);
          refetchInvites();
          refetchMembers();
        }}>
        <EditMemberForm
          orgId={auth.org.id}
          member={editMember}
          onClose={() => {
            setEditMember(null);
            refetchInvites();
            refetchMembers();
          }}
        />
      </Popup>

      <Popup isOpen={deleteEntryPopupVisible} onClose={() => setDeleteEntryPopupVisible(false)}>
        <ConfirmationDialog
          headline={`${deleteEntry?.type === RowType.Member ? 'Nutzer' : 'Einladung'} löschen`}
          onCancel={() => setDeleteEntryPopupVisible(false)}
          onConfirm={() => handleOnDelete()}
          confirmButtonProps={{ themeColor: 'danger', children: 'Löschen' }}>
          Sind Sie sicher, dass Sie den Eintrag "{deleteEntry?.email}" löschen möchten?
          {deleteEntry?.type === RowType.Member && (
            <div className='form-field'>
              <Styled.DeleteDialogControl className='control'>
                <Styled.DeleteDialogRadioCard
                  className='radio'
                  htmlFor={`delete-mode-${DELETE_MODE.SOFT}`}
                  $isChecked={deleteMode === DELETE_MODE.SOFT}>
                  <input
                    type='radio'
                    name='delete-mode'
                    id={`delete-mode-${DELETE_MODE.SOFT}`}
                    checked={deleteMode === DELETE_MODE.SOFT}
                    onChange={() => setDeleteMode(DELETE_MODE.SOFT)}
                  />
                  <span>
                    <b>Inaktiv</b>
                    <br />
                    Sie sind dabei den Nutzenden inaktiv zu schalten. Die zugehörigen Prüfungen bleiben im
                    Prüfungsarchiv, der Nutzeraccount wird deaktiviert.
                  </span>
                </Styled.DeleteDialogRadioCard>
              </Styled.DeleteDialogControl>
              <Styled.DeleteDialogControl className='control'>
                <Styled.DeleteDialogRadioCard
                  className='radio'
                  htmlFor={`delete-mode-${DELETE_MODE.PERMANENT}`}
                  $isChecked={deleteMode === DELETE_MODE.PERMANENT}>
                  <input
                    type='radio'
                    name='delete-mode'
                    id={`delete-mode-${DELETE_MODE.PERMANENT}`}
                    checked={deleteMode === DELETE_MODE.PERMANENT}
                    onChange={() => setDeleteMode(DELETE_MODE.PERMANENT)}
                  />
                  <span>
                    <b>Permanente Löschung</b>
                    <br />
                    Hiermit informieren Sie das Solid Team, dass Sie einen Nutzenden mit zugehörigen Prüfungen löschen
                    möchten. Bis das geschehen ist, wird der Nutzer als inaktiv markiert.
                  </span>
                </Styled.DeleteDialogRadioCard>
              </Styled.DeleteDialogControl>
            </div>
          )}
        </ConfirmationDialog>
      </Popup>
    </>
  );
};

export default OrgMembersPage;
