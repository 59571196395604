import { mdiStar, mdiStarOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { CHECK_TYPES_NEEDED_PLANS, PLAN, getCheckTypeFromSlug } from '@solid/shared';
import { Button } from '@solid/shared/ui';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { PaymentPopupContext } from '../../contexts/payment-popup.context';
import { AuthContext } from '../../contexts/auth.context';
import { KirbyCheckGroupEntry } from '@solid/shared/kirby';
import { canUseFavorites } from '../../services/setting.service';
import * as Styled from './CheckCard.styles';

interface CheckCardProps {
  check: KirbyCheckGroupEntry;
  isFavorite: boolean;
  onFavoriteStateChange: () => void;
  hasShadow?: boolean;
}

const CheckCard: React.FC<CheckCardProps> = ({ check, isFavorite, onFavoriteStateChange, hasShadow }) => {
  const { auth } = useContext(AuthContext);
  const checkType = getCheckTypeFromSlug(check.slug as string);
  const canStartCheck = CHECK_TYPES_NEEDED_PLANS[checkType].includes(auth.user.plan);
  const paymentPopup = useContext(PaymentPopupContext);

  return (
    <Styled.Checkup key={check.slug} $isLocked={!canStartCheck} $hasShadow={hasShadow}>
      {canStartCheck && canUseFavorites(auth.user.plan) && (
        <Styled.FavoriteButton
          hasIconOnly
          variant='transparent'
          onClick={() => onFavoriteStateChange()}
          title={isFavorite ? 'Prüfung von Favoriten entfernen' : 'Prüfung zu Favoriten hinzufügen'}>
          <Icon size='1.25rem' path={isFavorite ? mdiStar : mdiStarOutline} />
        </Styled.FavoriteButton>
      )}

      {check.flag && (
        <Styled.FlagWrapper>
          <img src={check.flag} alt='' />
        </Styled.FlagWrapper>
      )}

      <Styled.PlanTag plan={CHECK_TYPES_NEEDED_PLANS[checkType].includes(PLAN.FREE) ? PLAN.FREE : PLAN.PLUS} />

      <Styled.Question dangerouslySetInnerHTML={{ __html: check.question }}></Styled.Question>

      {canStartCheck ? (
        <Link to={`/checks/new/${check.slug}`}>
          <Styled.StartButton>Jetzt prüfen</Styled.StartButton>
        </Link>
      ) : (
        <Button onClick={() => paymentPopup.setOpen(true)}>Freischalten</Button>
      )}

      {check.active === false && (
        <Styled.UnlistedOverlay>
          <p>Befindet sich noch im Aufbau ...</p>
        </Styled.UnlistedOverlay>
      )}
    </Styled.Checkup>
  );
};

export default CheckCard;
