import { Color } from '@solid/shared/styles/colors';
import { Row } from 'react-data-grid';
import styled from 'styled-components';

export const DeletedRow = styled(Row)`
  .rdg-cell {
    cursor: help;
    text-decoration: line-through;
    opacity: 0.6;
  }
`;

export const InfoActionsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  gap: 20px;
  margin-bottom: 32px;
`;

export const InfoContainer = styled.div`
  min-width: 290px;
  flex-grow: 1;
`;

export const UserProgressBarContainer = styled.div`
  display: flex;
  height: 24px;
  background-color: ${Color.Blue_800};
  border-radius: ${props => props.theme.borderRadius.small};
  overflow: hidden;
`;

export const Members = styled.div`
  background-color: ${Color.Blue_500_Primary};
`;

export const PendingInvites = styled.div`
  background-color: ${Color.Blue_100};
`;

export const UserProgressBarInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const MembersInfo = styled.div`
  color: ${Color.Blue_500_Primary};
`;

export const PendingInvitesInfo = styled.div`
  color: ${Color.Blue_100};
`;

export const TotalMembersInfo = styled.div`
  margin-left: auto;
`;

export const DeleteDialogControl = styled.label`
  border-bottom: none !important;
`;

export const DeleteDialogRadioCard = styled.label<{ $isChecked: boolean }>`
  display: flex;
  align-items: flex-start;
  transition: ${props => props.theme.transitionTiming};
  padding: 14px 20px;
  border: 2px solid transparent;
  border-color: ${props => (props.$isChecked ? Color.Blue_500_Primary : Color.Neutral_200_OffWhite)};
  border-radius: ${props => props.theme.borderRadius.small};
  cursor: pointer;
  margin: 5px 0;

  &:hover {
    border-color: ${props => (props.$isChecked ? Color.Blue_600 : Color.Neutral_300)};
  }

  input {
    margin: 3px 15px 0 0 !important;
    border-radius: 9999rem !important;
  }
`;
