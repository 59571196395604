import * as Sentry from '@sentry/react';
import { CHECK_TYPE, CHECK_TYPES_DISABLED, getCheckTypeFromSlug } from '@solid/shared';
import { LoadingSpinner } from '@solid/shared/ui';
import { useQuery } from '@tanstack/react-query';
import { makeWidget } from '@typeform/embed';
import ms from 'ms';
import { useEffect, useRef, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { getCheckResult, getSignedTypeformUrl } from '../../services/check.service';
import Layout from '../global/Layout';
import CheckIndividualTest from './invidiual-test/CheckIndividualTest';
import CheckIndividualMultiTest from './individual-multi-test/CheckIndividualMultiTest';
import NotificationMessage from '@solid/shared/ui/NotificationMessage';
import * as Styled from './NewCheckPage.styles';

const NewCheckPage: React.FC = () => {
  const [responseId, setResponseId] = useState<string>();
  const [errorGettingCheck, seErrorGettingCheck] = useState(false);
  const { checkType: checkTypeAsSlug } = useParams<{ checkType: string }>();
  const ref = useRef<HTMLDivElement>(null);

  const checkType = getCheckTypeFromSlug(checkTypeAsSlug);

  // Loads result of the form, triggered upon submission
  const { data: checkResult, isLoading: isLoadingResult } = useQuery(
    ['checkResult', responseId],
    () => getCheckResult(responseId as string),
    {
      enabled: !!responseId,
      retry: true,
      retryDelay: () => ms('3sec'),
    }
  );

  useEffect(() => {
    (async () => {
      if (!ref || !ref.current) {
        return;
      }
      try {
        const signedUrl = await getSignedTypeformUrl(checkType);

        if (!signedUrl) {
          // Some checks do not require a typeform, e.g. the individual test
          return null;
        }

        makeWidget(ref.current, signedUrl, {
          onSubmit: e => setResponseId(e.response_id),
          opacity: 0,
          buttonText: 'Prüfung starten',
        });
      } catch (e) {
        seErrorGettingCheck(true);
        Sentry.captureException(e);
        console.error(e);
      }
    })();
  }, [checkType, ref]);

  if (!checkType || CHECK_TYPES_DISABLED.includes(checkType) || errorGettingCheck) {
    return (
      <Layout>
        <div style={{ maxWidth: '300px', margin: '0 auto' }}>
          <NotificationMessage state='danger'>Die Prüfung wurde nicht gefunden</NotificationMessage>
        </div>
        <Styled.BtnContainer>
          <Link className='btn is-primary is-outlined' to='/'>
            Zurück zur Übersicht
          </Link>
        </Styled.BtnContainer>
      </Layout>
    );
  }

  if (responseId) {
    return (
      <Layout>
        {isLoadingResult && (
          <Styled.AbsolvedContainer>
            <h1>Prüfung abgeschlossen</h1>
            <p>
              Wir werten Ihr Ergebnis aus.
              <br />
              Dieser Prozess kann bis zu 30 Sekunden dauern.
            </p>
            <LoadingSpinner />
          </Styled.AbsolvedContainer>
        )}
        {checkResult && <Navigate to={`/checks/${responseId}`} />}
      </Layout>
    );
  }

  return (
    <Layout>
      <Styled.Typeform ref={ref}></Styled.Typeform>

      {checkType === CHECK_TYPE.INDIVIDUAL_TEST && <CheckIndividualTest />}

      {checkType === CHECK_TYPE.INDIVIDUAL_TEST_MULTI && <CheckIndividualMultiTest />}

      {checkType !== CHECK_TYPE.INDIVIDUAL_TEST && (
        <Styled.BtnContainer>
          <Link className='btn is-primary is-outlined' to='/'>
            Cancel
          </Link>
        </Styled.BtnContainer>
      )}
    </Layout>
  );
};

export default NewCheckPage;
