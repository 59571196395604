import { CHECK_TYPE } from './check';

export enum USER_SETTING {
  CHECK_FAVORITES = 'CHECK_FAVORITES',
}

export interface UserSetting {
  id: number;
  /**
   * User that this setting belongs to.
   */
  userId: number;
  /**
   * Type of check that was completed.
   */
  type: USER_SETTING;
  /**
   * Value stored in the db, needs to be parsed using `getUserSettingValue`.
   */
  value: string;
}

type UserSettingValueCheckFavorites = CHECK_TYPE[];

export type UserSettingValue = UserSettingValueCheckFavorites;

export interface UserSettingValueMap {
  [USER_SETTING.CHECK_FAVORITES]: UserSettingValueCheckFavorites;
}

export function getUserSettingValue<T extends USER_SETTING>(_type: T, value: string): UserSettingValueMap[T] | null {
  try {
    const parsed = JSON.parse(value);
    return parsed as UserSettingValueMap[T];
  } catch (e) {
    return null;
  }
}
