import { Notification } from '@solid/shared/ui';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { InferType } from 'yup';
import { ReactComponent as IconDownload } from '../../../assets/images/icons/download.svg';
import {
  AddressMatchSchema,
  IndividualTestCSVParseError,
  parseIndividualTestCSVData,
} from '../../../services/check.service';
import * as Styled from './CheckIndividualMultiTestFileUpload.styles';

type Address = InferType<typeof AddressMatchSchema>;

interface CheckIndividualMultiTestFileUploadProps {
  onSubmit: (data: Address[], fileName: string) => void;
}

const VALID_FILE_EXTENSIONS = ['.xlsx', '.xls'];

const CheckIndividualMultiTestFileUpload: React.FC<CheckIndividualMultiTestFileUploadProps> = ({ onSubmit }) => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [fileName, setFileName] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string | null>();

  const handleFileUpload: React.ChangeEventHandler<HTMLInputElement> = event => {
    const file = event.target.files[0];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop();
      if (!VALID_FILE_EXTENSIONS.includes(`.${fileExtension}`)) {
        setErrorMessage(
          `Invalid file format (.${fileExtension}).
          Please use an Excel file (${VALID_FILE_EXTENSIONS.join(' / ')}).`
        );
        return;
      }

      setFileName(fileName);

      const reader = new FileReader();
      reader.onload = async e => {
        try {
          const workbook = XLSX.read(e.target.result, { type: 'buffer' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const csvData = XLSX.utils.sheet_to_csv(sheet, { FS: ';' });
          const parsedData = await parseIndividualTestCSVData(csvData);

          setAddresses(parsedData);
          setErrorMessage(null);
        } catch (error) {
          if (error instanceof IndividualTestCSVParseError) {
            setErrorMessage(`${error.message} Please use the example file as a template.`);
            } else {
            setErrorMessage('Error reading the file.');
          }
          setAddresses([]);
        }

        event.target.value = null;
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <Styled.Wrapper>
      <h4>Please select the Excel file to be checked:</h4>

      <input type='file' id='fileInput' accept={VALID_FILE_EXTENSIONS.join(',')} onChange={handleFileUpload} hidden />

      <Styled.UploadWrapper>
        <Styled.UploadButton $themeColor='primary' htmlFor='fileInput'>
          Upload File
        </Styled.UploadButton>

        <Styled.ExampleFileDownloadLink href='/assets/individual-test-multi/example-file-check.xlsx'>
          <span>Example File</span>
          <IconDownload />
        </Styled.ExampleFileDownloadLink>
      </Styled.UploadWrapper>

      {(addresses.length === 0 || errorMessage) && (
        <Styled.NotificationInfo state='info'>
          Formatprüfung: Die geforderte Syntax finden Sie in der Beispieldatei. Die Bezeichnung der Spalten sind für die
          Prüfung wichtig. Mögliche Spaltenüberschriften werden aufgelistet.
          <hr />
          Required syntax: The required syntax can be found in the example file. The names of the columns are important
          for the check. Possible column headings are listed.
        </Styled.NotificationInfo>
      )}

      {addresses.length > 0 && (
        <p>
          Uploaded file: <i>{fileName}</i>
          <br />
          Amount of addresses: {addresses.length}
        </p>
      )}
      {errorMessage && <Notification state='danger'>{errorMessage}</Notification>}
      {addresses.length > 0 && !errorMessage && (
        <button className='btn is-primary' onClick={() => onSubmit(addresses, fileName)}>
          Check file
        </button>
      )}
    </Styled.Wrapper>
  );
};

export default CheckIndividualMultiTestFileUpload;
