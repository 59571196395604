import { RegisterSchema } from '@solid/shared/validation/auth';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router';
import * as authService from '../../services/auth.service';
import styles from '../common/Forms.module.scss';
import PrivacyField from './PrivacyField';
import ToSField from './ToSField';

interface RegisterFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface RegisterFormProps {
  invite?: {
    email: string;
    token: string;
  };
}

const RegisterForm: React.FC<RegisterFormProps> = ({ invite }) => {
  const navigate = useNavigate();

  const {
    mutate: register,
    error,
    isLoading,
  } = useMutation<void, Error, RegisterFormValues>(async values => {
    await authService.register(values);
    navigate('/confirm-email');
  });

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: invite?.email ?? '',
        inviteToken: invite?.token ?? '',
        password: '',
        confirmPassword: '',
      }}
      onSubmit={values => register(values)}
      validationSchema={RegisterSchema}>
      {({ isValid }) => (
        <Form>
          <div className='form-field'>
            <label htmlFor='register-first-name'>Vorname</label>
            <div className='control'>
              <Field type='text' name='firstName' id='register-first-name' autoComplete='given-name' required />
            </div>
            <ErrorMessage component='p' className='help is-error' name='firstName' />
          </div>

          <div className='form-field'>
            <label htmlFor='register-last-name'>Nachname</label>
            <div className='control'>
              <Field type='text' name='lastName' id='register-last-name' autoComplete='family-name' required />
            </div>
            <ErrorMessage component='p' className='help is-error' name='lastName' />
          </div>

          <div className='form-field'>
            <label htmlFor='register-email'>E-Mail-Adresse</label>
            <div className='control'>
              <Field type='email' name='email' id='register-email' autoComplete='email' required disabled={!!invite} />
            </div>
            <ErrorMessage component='p' className='help is-error' name='email' />
          </div>
          <div className='form-field'>
            <label htmlFor='register-password'>Passwort</label>
            <div className='control'>
              <Field
                type='password'
                name='password'
                id='register-password'
                autoComplete='new-password'
                required
                placeholder='min. 8 Zeichen'
              />
            </div>
            <ErrorMessage component='p' className='help is-error' name='password' />
          </div>

          <div className='form-field'>
            <label htmlFor='register-confirm-password'>Passwort (Wdh.)</label>
            <div className='control'>
              <Field
                type='password'
                name='confirmPassword'
                id='register-confirm-password'
                autoComplete='new-password'
                required
              />
            </div>
            <ErrorMessage component='p' className='help is-error' name='confirmPassword' />
          </div>

          <ToSField />

          <PrivacyField />

          <div className='form-field'>{error && <div className='notification is-error'>{error.message}</div>}</div>

          <div className='form-field'>
            <button
              className={classNames('btn is-primary', styles.submitBtn, {
                'is-loading': isLoading,
              })}
              type='submit'
              disabled={isLoading || !isValid}>
              Registrieren
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
