import { allowedCountries, UserWithOrgMember } from '@solid/shared';
import { Yup } from '@solid/shared/services/validation.service';
import { apiClient } from '@solid/shared/services/api.service';
import { AuthState } from '../contexts/auth.context';
import { ValidationError } from 'yup';

export function isStandaloneAccount(auth: AuthState) {
  return auth.orgMember === null;
}
export function isOrganizationAccount(auth: AuthState) {
  return auth.orgMember !== null;
}

export async function getUserProfile() {
  const response = await apiClient.get<{ user: UserWithOrgMember }>('/v1/users/profile');
  return response.data.user;
}

interface PasswordChangePayload {
  password: string;
}

interface NameChangePayload {
  firstName: string;
  lastName: string;
}

function validateVatId(this: Yup.TestContext<Yup.AnyObject>, value: string): void | boolean | ValidationError {
  const country = this.parent.companyCountry;

  if (!country) {
    return this.createError({ message: 'Bitte wählen Sie ein Land' });
  }

  if (!value) {
    return false;
  }

  const trimmed = value.toUpperCase().replace(/\s/g, '');

  const countryConfig: Record<(typeof allowedCountries)[number], { regex: RegExp; example: string }> = {
    DE: {
      regex: /^(DE)[0-9]{9}$/,
      example: 'DE123456789',
    },
    AT: {
      regex: /^(AT)U[0-9]{8}$/,
      example: 'ATU12345678',
    },
    CH: {
      regex: /^(CHE)[0-9]{9}(IVA|TVA|MWST)$/,
      example: 'CHE123456789MWST',
    },
  };

  if (!countryConfig[country].regex.test(trimmed)) {
    return this.createError({
      message: `Bitte geben Sie Ihre Umsatzsteuer-Identifikationsnummer für ${country} im Format "${countryConfig[country].example}" an`,
    });
  }

  return true;
}

export const UserCompanyDetailsSchema = Yup.object().shape({
  companyName: Yup.string().required('Bitte geben Sie einen Namen an'),
  companyStreet: Yup.string().required('Bitte geben Sie eine Straße an'),
  companyZipCode: Yup.string().required('Bitte geben Sie eine Postleitzahl an'),
  companyCity: Yup.string().required('Bitte geben Sie einen Ort an'),
  companyCountry: Yup.string().oneOf(allowedCountries).required('Bitte wählen Sie ein Land'),
  companyDifferentBillingAddress: Yup.boolean(),
  companyBillingName: Yup.string().when('companyDifferentBillingAddress', {
    is: true,
    then: () => Yup.string().required('Bitte geben Sie einen Namen an'),
  }),
  companyBillingStreet: Yup.string().when('companyDifferentBillingAddress', {
    is: true,
    then: () => Yup.string().required('Bitte geben Sie eine Straße an'),
  }),
  companyBillingZipCode: Yup.string().when('companyDifferentBillingAddress', {
    is: true,
    then: () => Yup.string().required('Bitte geben Sie eine Postleitzahl an'),
  }),
  companyBillingCity: Yup.string().when('companyDifferentBillingAddress', {
    is: true,
    then: () => Yup.string().required('Bitte geben Sie einen Ort an'),
  }),
  companyBillingCountry: Yup.string().when('companyDifferentBillingAddress', {
    is: true,
    then: () => Yup.string().oneOf(allowedCountries).required('Bitte wählen Sie ein Land'),
  }),
  companyVatId: Yup.string().test(
    'validVatId',
    'Bitte geben Sie eine gültige Umsatzsteuer-Identifikationsnummer an',
    validateVatId
  ),
  companyEmail: Yup.string().email('Ungültige E-Mail-Adresse').required('Bitte geben Sie eine E-Mail-Adresse an'),
});

export const OrgCompanyDetailsSchema = Yup.object().shape({
  companyName: Yup.string().required('Bitte geben Sie einen Namen an'),
  companyStreet: Yup.string().required('Bitte geben Sie eine Straße an'),
  companyZipCode: Yup.string().required('Bitte geben Sie eine Postleitzahl an'),
  companyCity: Yup.string().required('Bitte geben Sie einen Ort an'),
  companyCountry: Yup.string().oneOf(allowedCountries).required('Bitte wählen Sie ein Land'),
  companyDifferentBillingAddress: Yup.boolean(),
  companyBillingName: Yup.string().required('Bitte geben Sie einen Namen an'),
  companyBillingStreet: Yup.string().required('Bitte geben Sie eine Straße an'),
  companyBillingZipCode: Yup.string().required('Bitte geben Sie eine Postleitzahl an'),
  companyBillingCity: Yup.string().required('Bitte geben Sie einen Ort an'),
  companyBillingCountry: Yup.string().oneOf(allowedCountries).required('Bitte wählen Sie ein Land'),
  companyVatId: Yup.string().test(
    'validVatId',
    'Bitte geben Sie eine gültige Umsatzsteuer-Identifikationsnummer an',
    validateVatId
  ),
  companyEmail: Yup.string().email('Ungültige E-Mail-Adresse').required('Bitte geben Sie eine E-Mail-Adresse an'),
});

export async function updateUser(
  userId: number,
  payload: PasswordChangePayload | NameChangePayload | Yup.InferType<typeof UserCompanyDetailsSchema>
) {
  await apiClient.patch(`/v1/users/${userId}`, payload);
}

export async function deleteUser(userId: number, password: string) {
  await apiClient.delete(`/v1/users/${userId}`, { auth: { username: '', password } });
}

export async function getForumNotifications() {
  const response = await apiClient.get<{ amount: number }>('/v1/users/forum/notifications');
  return response.data.amount;
}
