import ms from 'ms';
import React, { useContext, useEffect, useState } from 'react';
import { paddleEvent } from '../../services/payment.service';
import { AuthContext } from '../../contexts/auth.context';

/**
 * This components listens to Paddle events and acts accordingly.
 * After successfully submitting a payment, it periodically checks if the user plan has changed.
 */
const PaddleListener: React.FC = () => {
  const { refetchAuthState } = useContext(AuthContext);
  const [refetchCounter, setRefetchCounter] = useState(3);

  useEffect(() => {
    const sub = paddleEvent.subscribe(data => {
      // Automatically refetch user information upon payment completion
      if (data.event === 'Checkout.PaymentComplete') {
        setRefetchCounter(3);
      }
    });
    setRefetchCounter(3);
    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (refetchCounter <= 0) {
      return;
    }
    refetchAuthState().then(() => setTimeout(() => setRefetchCounter(cur => cur - 1), ms('5sec')));
  }, [refetchCounter, refetchAuthState]);

  return null;
};

export default PaddleListener;
