import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import countries from 'i18n-iso-countries';
import { Yup } from '@solid/shared/services/validation.service';
import { AddressMatchSchema } from '../../../services/check.service';
import styles from './CheckIndividualTestSearchForm.module.scss';
import { useEffect } from 'react';

type Values = Yup.InferType<typeof AddressMatchSchema>;

interface CheckIndividualTestSearchFormProps {
  onSubmit?: (values: Values) => void;
  prefillValues?: Values;
}

const FormWatcher: React.FC<{ prefillValues: Values }> = ({ prefillValues }) => {
  const { setValues } = useFormikContext();

  useEffect(() => {
    if (prefillValues) {
      setValues(prefillValues);
    }
  }, [prefillValues, setValues]);

  return null;
};

const CheckIndividualTestSearchForm: React.FC<CheckIndividualTestSearchFormProps> = ({
  onSubmit,
  prefillValues,
}) => {
  const isReadOnly = !!prefillValues;

  return (
    <Formik
      initialValues={
        prefillValues ??
        ({
          name: '',
          street: '',
          zipCode: '',
          city: '',
          country: '',
        } as Values)
      }
      onSubmit={values => onSubmit(values)}
      validationSchema={AddressMatchSchema}>
      <div className={styles.formContainer}>
        <FormWatcher prefillValues={prefillValues} />

        <Form className={classNames(styles.form)}>
          <div className='form-field'>
            <label htmlFor='name'>Name*</label>
            <div className='control'>
              <Field
                type='text'
                name='name'
                id='name'
                autoComplete='organization'
                placeholder='John Doe'
                required
                readOnly={isReadOnly}
              />
            </div>
            <ErrorMessage component='p' className='help is-danger' name='name' />
          </div>

          <div className='form-field'>
            <label htmlFor='street'>Straße und Hausnr. / Street address</label>
            <div className='control'>
              <Field type='text' name='street' id='street' readOnly={isReadOnly} />
            </div>
            <ErrorMessage component='p' className='help is-danger' name='street' />
          </div>

          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='city'>Stadt / City</label>
              <div className='control'>
                <Field type='text' name='city' id='city' readOnly={isReadOnly} />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='city' />
            </div>
            <div className='form-field'>
              <label htmlFor='zipCode'>PLZ / ZIP Code</label>
              <div className='control'>
                <Field type='text' name='zipCode' id='zipCode' readOnly={isReadOnly} />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='zipCode' />
            </div>
          </div>

          <div className='form-field'>
            <label htmlFor='country'>Land / Country</label>
            <div className='control'>
              <Field component='select' type='select' name='country' id='country' disabled={isReadOnly}>
                <option value=''>Please choose...</option>
                {Object.entries(countries.getNames('de'))
                  .sort((a, b) => a[1].localeCompare(b[1]))
                  .map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
              </Field>
            </div>
            <ErrorMessage component='p' className='help is-danger' name='country' />
          </div>

          {isReadOnly === false && (
            <div className='form-field'>
              <button className='btn is-primary' type='submit'>
                Check address
              </button>
            </div>
          )}
        </Form>
      </div>
    </Formik>
  );
};

export default CheckIndividualTestSearchForm;
