import { InvoiceDetails, isPaidPlan, PAYMENT_METHOD } from '@solid/shared';
import { ApiError } from '@solid/shared/services/api.service';
import { Button, LoadingSpinner } from '@solid/shared/ui';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import ms from 'ms';
import React, { useContext, useEffect, useMemo } from 'react';
import { AuthContext } from '../../contexts/auth.context';
import { PaymentPopupContext } from '../../contexts/payment-popup.context';
import { useOrganizationAbility } from '../../hooks/useOrganizationAbility';
import { getInvoices, getManagementURLs, openPaddlePopup, paddleEvent } from '../../services/payment.service';
import { isOrganizationAccount, isStandaloneAccount } from '../../services/user.service';
import CurrentPlan from '../common/CurrentPlan';
import styles from './PlanSettings.module.scss';

const PlanSettings: React.FC = () => {
  const { auth } = useContext(AuthContext);
  const paymentPopup = useContext(PaymentPopupContext);

  const hasPlan = isPaidPlan(auth.user.plan);
  const hasManualSubscription =
    hasPlan && (isOrganizationAccount(auth) || auth.user.paymentMethod === PAYMENT_METHOD.MANUALLY);

  const ability = useOrganizationAbility();

  const {
    data: managementUrls,
    isLoading: isLoadingUrls,
    refetch: refetchManagementUrls,
    error: managementUrlsError,
  } = useQuery<
    {
      editBillingInfoUrl: string;
      cancellationUrl: string;
    },
    ApiError
  >(['management-urls'], () => getManagementURLs(), {
    enabled: hasPlan && !hasManualSubscription,
    staleTime: ms('15min'),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const {
    data: invoices,
    error: errorInvoices,
    isLoading: isLoadingInvoices,
  } = useQuery<InvoiceDetails[], ApiError>(['invoices'], () => getInvoices(), {
    staleTime: ms('15min'),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const cancellationMailToLink = useMemo(() => {
    let subject = '';
    let body = '';
    let content = '';

    if (isStandaloneAccount(auth)) {
      const companyName = auth.user.companyBillingName ? auth.user.companyBillingName : auth.user.companyName;
      subject = `SOLID | Kündigung PLUS Abonnement für "${companyName}"`;
      content = `ich möchte mein Abo für die PLUS-Variante von SOLID kündigen. 
Meine User-ID ist: ${auth.user.id}

Meine Gründe sind:`;
    } else if (isOrganizationAccount(auth)) {
      const orgName = auth.org.companyBillingName ? auth.org.companyBillingName : auth.org.companyName;
      subject = `SOLID | Kündigung PLUS Abonnement für Organisation "${orgName}"`;
      content = `ich möchte das Abonnement der PLUS-Variante von SOLID für die Organisation "${orgName}" kündigen. 
Meine User-ID ist: ${auth.user.id}
Organisations-ID: ${auth.org.id}

Meine Gründe sind:`;
    }

    body = `Sehr geehrte Damen und Herren,

${content}

Ich kann SOLID PLUS im bezahlten Zeitraum, also bis zum Jahresende, noch weiter nutzen. 
Eine Kündigungsbestätigung wird mir zugehen.

Mit freundlichen Grüßen`;

    return `mailto:solid_accounting@aeb.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }, [auth]);

  useEffect(() => {
    const sub = paddleEvent.subscribe(event => {
      if (event.event === 'Checkout.Close') {
        refetchManagementUrls();
      }
    });
    return () => sub.unsubscribe();
  }, [refetchManagementUrls]);

  if (managementUrlsError) {
    return (
      <div className={styles.wrapper}>
        <div className='notification is-error'>{managementUrlsError.message}</div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.currentPlanWrapper}>
        <div className={styles.wrapperLeft}>
          <div className={styles.headline}>Aktueller Plan</div>

          <CurrentPlan className={styles.currentPlanText} />

          {hasPlan && (
            <div className={styles.endsText}>
              Zeitdauer unbefristet.
              <br />
              {auth.user.subscriptionEnd && `Bei Kündigung bis: ${auth.user.subscriptionEnd.toLocaleDateString()}`}
            </div>
          )}
        </div>
        <div>
          {!hasPlan && (
            <button className='btn is-primary' onClick={() => paymentPopup.setOpen(true)}>
              Upgraden
            </button>
          )}
          {hasPlan &&
            (hasManualSubscription ? (
              <Button
                themeColor='danger'
                variant='outlined'
                disabled={isOrganizationAccount(auth) && ability.can('read', 'Organization', 'billing') === false}
                onClick={() => (window.location.href = cancellationMailToLink)}>
                Kündigen
              </Button>
            ) : (
              <Button
                themeColor='danger'
                variant='outlined'
                isLoading={isLoadingUrls}
                onClick={() => openPaddlePopup(managementUrls.cancellationUrl)}
                disabled={managementUrls?.cancellationUrl === null}>
                {managementUrls?.cancellationUrl === null ? 'Gekündigt' : 'Kündigen'}
              </Button>
            ))}
        </div>
      </div>

      {hasPlan && (
        <div className={styles.paymentMethodWrapper}>
          <div className={styles.headline}>Bezahlmethode</div>
          {hasManualSubscription ? (
            <p>
              <i>Auf Rechnung</i>
            </p>
          ) : (
            <button
              className={classNames('btn is-primary', { 'is-loading': isLoadingUrls })}
              onClick={() => openPaddlePopup(managementUrls.editBillingInfoUrl)}
              disabled={managementUrls?.editBillingInfoUrl === null}>
              {managementUrls?.editBillingInfoUrl === null ? 'Aktuelles Abonnement gekündigt' : 'Bezahlmethode ändern'}
            </button>
          )}
        </div>
      )}

      <div className={styles.invoicesWrapper}>
        <div className={styles.headline}>Rechnungen</div>
        <div className={styles.invoicesContainer}>
          {isLoadingInvoices && (
            <div className={styles.invoicesLoadingContainer}>
              <LoadingSpinner />
            </div>
          )}
          {invoices?.length > 0 &&
            invoices.map((inv, i) => (
              <a href={inv.receiptUrl} target='_blank' rel='noreferrer' key={i} className={styles.invoice}>
                <span>
                  Rechnung #{inv.orderId} ({inv.amount} {inv.currency}, {inv.createdAt.toLocaleDateString()})
                </span>
                <br />
                {inv.comment && <span className={styles.invoiceComment}>{inv.comment}</span>}
              </a>
            ))}
          {invoices?.length === 0 && <p>Rechnungen werden hier angezeigt.</p>}
          {hasManualSubscription && (
            <p>
              Sollten Sie die Bezahlmethode "Auf Rechnung" gewählt haben, werden Ihre Rechnungen manuell erstellt und
              versandt.
            </p>
          )}
          {errorInvoices && <p>{errorInvoices.message}</p>}
        </div>
        <p className={styles.invoicesInfoText}>
          Sie haben Fragen zu Ihrer Rechnung? Melden sich sich unter{' '}
          <a href='mailto:info@solid-app.de'>info@solid-app.de</a> direkt bei uns.
        </p>
      </div>
    </div>
  );
};

export default PlanSettings;
