import * as Sentry from '@sentry/react';
import ally from 'ally.js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import packageJson from '../../../package.json';
import { environment } from './environments/environment';
import AppWrapper from './app/AppWrapper';

// Initialize Sentry integration
if (environment.sentry.dsn) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    release: `${packageJson.name}@${packageJson.version}`,
    environment: environment.mode,
  });
}

// Initialize ally focus detection
ally.style.focusSource();

const root = createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <AppWrapper />
  // </React.StrictMode>
);
