import { PLAN } from '@solid/shared';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth.context';

const PaidPlanRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { auth } = useContext(AuthContext);

  if ([PLAN.PLUS, PLAN.PLUS_TRIAL].includes(auth.user?.plan)) {
    return children;
  } else {
    return <Navigate to='/' />;
  }
};

export default PaidPlanRoute;
