import styled from 'styled-components';

export const Typeform = styled.div`
  &:not(:empty) {
    margin-top: -0.5rem;

    @media (${props => props.theme.breakpoint.md.up}) {
      margin-top: -1rem;
    }
  }
  iframe {
    height: 800px;
    min-height: 75vh;
    max-height: 100vh;
    height: auto;
  }
`;

export const AbsolvedContainer = styled.div`
  text-align: center;
`;

export const BtnContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  @media (${props => props.theme.breakpoint.md.up}) {
    margin-top: 2rem;
  }
`;
