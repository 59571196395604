import { Organization, OrganizationMember, User } from '@solid/shared';
import React from 'react';

type StandaloneAccount = { user: User; org: null; orgMember: null };
type OrganizationAccount = { user: User; org: Organization; orgMember: OrganizationMember };

/**
 * Current auth state:
 * - `null`: not logged in
 * - `undefined`: loading
 * - `StandaloneAccount`: logged in user is a standalone user
 * - `OrganizationAccount`: logged in user is part of an organization
 */
export type AuthState = StandaloneAccount | OrganizationAccount | null | undefined;

export const AuthContext = React.createContext<{
  auth: AuthState;
  refetchAuthState: () => Promise<unknown>;
}>({
  auth: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetchAuthState: null,
});
