import { transparentize } from 'polished';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Color } from '../styles/colors';

export const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  box-shadow: inset 0 -2px 0 0 ${Color.Blue_800};
  margin-bottom: 32px;
`;

export const Tab = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border-bottom: 2px solid transparent;
  color: ${Color.Blue_100};
  transition: 0.2s ease;

  > svg {
    margin: -3px 8px -3px 0;
  }

  &:hover {
    background: ${transparentize(0.9, Color.Blue_500_Primary)};
    text-decoration: none;
  }

  &:active {
    background: ${transparentize(0.7, Color.Blue_500_Primary)};
  }

  &.active {
    background: ${transparentize(0.9, Color.Blue_500_Primary)};
    border-bottom: 2px solid ${Color.Blue_500_Primary};
  }
`;
