import styled from 'styled-components';

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${props => props.theme.fontWeight.bold};

  svg {
    margin-right: 0.5rem;
  }
`;

export const Ul = styled.ul`
  margin: 0.5rem 0;
  padding-left: 2rem;
`;
