import { IndividualTestMatch } from '@solid/shared';
import { Yup } from '@solid/shared/services/validation.service';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconProhibtion } from '../../../assets/images/icons/checkup-prohibition-filled.svg';
import { ReactComponent as IconSuccess } from '../../../assets/images/icons/checkup-success-filled.svg';
import { AddressMatchSaveSchema } from '../../../services/check.service';
import stylesNoteForm from '../CheckNoteForm.module.scss';
import styles from './SaveForm.module.scss';

export type Values = Yup.InferType<typeof AddressMatchSaveSchema>;

export interface SaveFormProps {
  onSubmit: (values: Values) => void;
  matches: IndividualTestMatch[];
  btnSaveLabel?: string;
}

/**
 * Automatically sets the form state based on the current result:
 * - If no matches are found, we automatically set the "isHit" value to `false`
 * - If matches were found and the "isHit" value was set, we reset it to it's initial state so that the user has to actively choose again
 */
const MatchesWatcher: React.FC<{ matches: IndividualTestMatch[]; isHitRef: HTMLInputElement }> = ({
  matches,
  isHitRef,
}) => {
  const { setFieldValue, values, resetForm } = useFormikContext<Values>();

  useEffect(() => {
    resetForm();
    if (matches.length === 0) {
      setFieldValue('isHit', false);
      setFieldValue('note', '');
      setFieldValue('hasMatches', false);
    } else {
      setFieldValue('hasMatches', true);
    }
  }, [matches, setFieldValue, resetForm]);

  useEffect(() => {
    if (isHitRef) {
      isHitRef.indeterminate = (values.isHit as any) === '';
    }
  }, [values, isHitRef]);

  return null;
};

const SaveForm: React.FC<SaveFormProps> = ({
  matches,
  onSubmit,
  btnSaveLabel = 'Save result',
}) => {
  const isHitRef = useRef<HTMLInputElement>();

  return (
    <Formik
      initialValues={
        // @ts-expect-error (We need to use an empty string to not prefill the `isHit` toggle)
        {
          note: '',
          isHit: '',
        } as Values
      }
      validationSchema={matches.length > 0 ? AddressMatchSaveSchema : Yup.object()}
      onSubmit={values => onSubmit(values)}>
      {({ values, setFieldValue, setFieldTouched }) => (
        <Form className={styles.wrapper}>
          <MatchesWatcher matches={matches} isHitRef={isHitRef.current} />

          {matches.length > 0 && (
            <>
              <div className='form-field'>
                <label htmlFor='note'>Kommentar / comment*</label>
                <div className='control'>
                  {/* TODO can we add a useful placeholder text here? */}
                  <Field as='textarea' rows={4} name='note' className={classNames(stylesNoteForm.noteTextarea)} />
                </div>
                <ErrorMessage component='p' className='help is-danger' name='note' />
              </div>

              <div className='form-field'>
                <label htmlFor='isHit'>Ergebnis / result*</label>
                <div className={classNames('form-field', styles.isHitContainer)}>
                  <div
                    className={classNames(styles.isHitOption, { [styles.isHitOptionActive]: values.isHit === false })}
                    onClick={() => setFieldValue('isHit', false)}>
                    <div className={styles.isHitIcon}>
                      <IconSuccess />
                    </div>
                    <span>No match</span>
                  </div>
                  <div className={classNames('toggle', styles.isHitToggle)}>
                    <label>
                      <input
                        type='checkbox'
                        id='isHit'
                        name='isHit'
                        checked={values.isHit === true}
                        ref={isHitRef}
                        onBlur={() => setFieldTouched('isHit', true)}
                        onChange={e => setFieldValue('isHit', e.currentTarget.checked)}
                      />
                    </label>
                  </div>
                  <div
                    className={classNames(styles.isHitOption, { [styles.isHitOptionActive]: values.isHit === true })}
                    onClick={() => setFieldValue('isHit', true)}>
                    <span>Match</span>
                    <div className={styles.isHitIcon}>
                      <IconProhibtion />
                    </div>
                  </div>
                </div>
                <ErrorMessage component='p' className='help is-danger' name='isHit' />
              </div>
            </>
          )}

          <div className={classNames(styles.btnContainer)}>
            <Link className='btn is-primary is-outlined' to='/'>
              Cancel
            </Link>
            <button className='btn is-primary' type='submit'>
              {btnSaveLabel}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SaveForm;
