import { mdiEmail } from '@mdi/js';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { ORGANIZATION_ROLE, ORGANIZATION_ROLE_NAME, Organization, OrganizationInvite } from '../../organization';
import {
  InviteResult,
  createOrganizationMultiInvite,
  getEmailsFromMultilineText,
} from '../../services/organization.service';
import { Yup } from '../../services/validation.service';
import { StyledButton } from '../../ui';
import Button from '../../ui/Button';
import NotificationMessage from '../../ui/NotificationMessage';
import { CreateOrganizationMultiInvite } from '../../validation/organization';
import * as Styled from './CreateInviteForm.styles';
import OrganizationRoleInfo from './OrganizationRoleInfo';

interface CreateInviteFormProps {
  org: Organization;
  invites: OrganizationInvite[];
  currentMemberCount: number;
  onClose: () => void;
}

const CreateInviteForm: React.FC<CreateInviteFormProps> = ({ org, invites, currentMemberCount, onClose }) => {
  const [results, setResults] = useState<InviteResult[]>([]);

  const formik = useFormik({
    initialValues:
      // @ts-expect-error (needed for initial state)
      {
        emails: '',
        role: '',
      } as Yup.InferType<typeof CreateOrganizationMultiInvite>,
    onSubmit: async (values, actions) => {
      await submitForm(values);
      actions.resetForm();
    },
    validationSchema: CreateOrganizationMultiInvite,
    validateOnBlur: true,
  });

  // Reset fields when switching orgs
  useEffect(() => {
    formik.resetForm();
  }, [org.id]);

  const emails = getEmailsFromMultilineText(formik.values.emails);

  const submitForm = async (values: Yup.InferType<typeof CreateOrganizationMultiInvite>) => {
    setResults([]);
    const res = await createOrganizationMultiInvite(org.id, values);
    setResults(res);
  };

  const activeInvitesCount = useMemo(() => invites.filter(invite => invite.expiresAt > new Date()).length, []);
  const successResults = results.filter(result => result.error === undefined);
  const errorResults = results.filter(result => result.error !== undefined);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Styled.Container>
        <div className='form-field'>
          <label htmlFor='create-org-invite-emails'>E-Mail-Adressen</label>
          <div className='control'>
            <textarea
              name='emails'
              id='create-org-invite-emails'
              autoComplete='off'
              placeholder='erika.mustermann@example.com&#10;max.mustermann@example.com&#10;...'
              rows={8}
              autoFocus
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.emails}></textarea>
          </div>
          {formik.errors.emails && formik.touched.emails && <p className='help is-error'>{formik.errors.emails}</p>}
        </div>
        <div className='form-field'>
          <label htmlFor='create-org-invite-role'>Rolle</label>
          <div className='control'>
            <select
              name='role'
              id='create-org-invite-role'
              multiple={false}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}>
              <option value='' disabled>
                Bitte auswählen...
              </option>
              {Object.values(ORGANIZATION_ROLE).map(role => (
                <option key={role} value={role}>
                  {ORGANIZATION_ROLE_NAME[role]}
                </option>
              ))}
            </select>
          </div>
          {formik.errors.role && formik.touched.role && <p className='help is-error'>{formik.errors.role}</p>}
          <OrganizationRoleInfo role={formik.values.role} marginTop />
        </div>
      </Styled.Container>

      {results.length > 0 && (
        <div>
          {successResults.length > 0 && (
            <NotificationMessage state='success'>
              {successResults.map(result => `${result.email} - Erfolgreich eingeladen`).join('\n')}
            </NotificationMessage>
          )}
          {errorResults.length > 0 && (
            <NotificationMessage state='danger'>
              {errorResults.map(result => `${result.email} - ${result.error}`).join('\n')}
            </NotificationMessage>
          )}
        </div>
      )}

      <p>
        Nutzeranzahl nach Einladung:{' '}
        <b>
          {currentMemberCount}{' '}
          {emails.length + activeInvitesCount > 0 && (
            <Styled.Invites style={{ cursor: 'help' }} title='Offene Einladungen'>
              (+{activeInvitesCount + emails.length}
              <Styled.InvitesIcon path={mdiEmail} size='12px' />){' '}
            </Styled.Invites>
          )}
        </b>
        <br />
        Aktuell verfügbare Nutzeranzahl: <b>{org.maxSeats}</b>
      </p>
      {currentMemberCount + emails.length > org.maxSeats && (
        <NotificationMessage state='warning'>
          Es sind nicht genügend freie Plätze in dieser Organisation vorhanden, um diese Einladungen durchzuführen. Die
          Organisation wird automatisch in den nächst höheren Plan hochgestuft.
        </NotificationMessage>
      )}

      <StyledButton.ButtonsContainer>
        <Button themeColor='grey' type='button' onClick={() => onClose()}>
          Abbrechen
        </Button>
        <Button themeColor='primary' type='submit' isLoading={formik.isSubmitting} disabled={formik.isSubmitting}>
          Einladungen senden
        </Button>
      </StyledButton.ButtonsContainer>
    </form>
  );
};

export default CreateInviteForm;
