import React from 'react';
import { ORGANIZATION_ROLE, ORGANIZATION_ROLE_ICONS, ORGANIZATION_ROLE_NAME } from '../../organization';
import Icon from '@mdi/react';
import * as Styled from './OrganizationRoleInfo.styles';

interface OrganizationRoleInfoProps {
  role: ORGANIZATION_ROLE;
  marginTop?: boolean;
}

const OrganizationRoleInfo: React.FC<OrganizationRoleInfoProps> = ({ role, marginTop }) => {
  let infoPoints: string[] = [];

  switch (role) {
    case ORGANIZATION_ROLE.MEMBER:
      infoPoints = ['Kann nur eigene Prüfungen einsehen'];
      break;
    case ORGANIZATION_ROLE.MANAGER:
      infoPoints = [
        `Alles von Rolle "${ORGANIZATION_ROLE_NAME[ORGANIZATION_ROLE.MEMBER]}"`,
        'Kann alle Prüfungen der Organisation einsehen',
      ];
      break;
    case ORGANIZATION_ROLE.LOCAL_ADMIN:
      infoPoints = [
        `Alles von Rolle "${ORGANIZATION_ROLE_NAME[ORGANIZATION_ROLE.MANAGER]}"`,
        'Kann Prüfungen löschen',
        'Kann Einladungen versenden, auslesen und löschen',
        'Kann Mitglieder bearbeiten und entfernen',
        'Kann das Organisations Dashboard einsehen',
      ];
      break;
    default:
      return null;
  }

  return (
    <div style={{ marginTop: marginTop ? '20px' : undefined }}>
      <Styled.NameContainer>
        <Icon path={ORGANIZATION_ROLE_ICONS[role]} size={0.75} />
        <span>Rolle "{ORGANIZATION_ROLE_NAME[role]}"</span>
      </Styled.NameContainer>
      <Styled.Ul>
        {infoPoints.map((point, i) => (
          <li key={`${role}-${i}`}>{point}</li>
        ))}
      </Styled.Ul>
    </div>
  );
};

export default OrganizationRoleInfo;
