const PrivacyField: React.FC = () => {
  return (
    <div className='form-field'>
      <label className='checkbox' htmlFor='register-privacy'>
        <input type='checkbox' id='register-privacy' name='register-privacy' required />
        <span>
          Hiermit bestätige ich, die Hinweise zu{' '}
          <a href='https://www.aeb.com/de-de/datenschutz.php' target='_blank' rel='noopener noreferrer'>
            Datenschutz und Datensicherheit
          </a>{' '}
          gelesen zu haben.
        </span>
      </label>
    </div>
  );
};

export default PrivacyField;
