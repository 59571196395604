import { useContext } from 'react';
import { AuthContext } from '../contexts/auth.context';
import { defineOrganizationAbilitiesFor } from '@solid/shared/permissions/organization.permission';

export const useOrganizationAbility = () => {
  const { auth } = useContext(AuthContext);

  return defineOrganizationAbilitiesFor({
    user: auth.user,
    targetOrganization: auth.org,
    organizationMember: auth.orgMember,
  });
};
