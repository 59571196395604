import { environment } from '../../environments/environment';
import { apiClient } from '@solid/shared/services/api.service';
import { Subject } from 'rxjs';
import { InvoiceDetails } from '@solid/shared';

export const paddleEvent = new Subject<Paddle.CallbackEvent>();

export function setupPaddle() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = `${environment.apiBaseUrl}/v1/payment/client-sdk`;

  script.onload = function () {
    if (!Paddle) {
      console.error(
        'Failed to load Paddle integration. The script was most likely blocked from loading by your browser.'
      );
      return;
    }
    if (environment.mode === 'development') {
      Paddle.Options({ debug: true });
    }
    if (environment.mode !== 'production') {
      Paddle.Environment.set('sandbox');
    }
    Paddle.Setup({
      vendor: environment.paddle.vendorId,
      eventCallback: data => paddleEvent.next(data),
    });
  };

  document.getElementsByTagName('head')[0].appendChild(script);
}

export function openPaddlePopup(url: string) {
  // TODO properly display this error to the user
  if (!Paddle) {
    console.error(
      'Failed to load Paddle integration. The script was most likely blocked from loading by your browser.'
    );
    return;
  }
  /**
   * @see https://developer.paddle.com/reference/paddle-js/parameters#checkout-properties
   */
  Paddle.Checkout.open({
    override: url,
    displayModeTheme: 'dark',
    disableLogout: true, // Prevent user from changing accounts
  });
}

export async function getCheckoutUrl(invoiceComment?: string) {
  const res = await apiClient.get<{ url: string }>(`/v1/payment/checkout`, { params: { invoiceComment } });
  return res.data.url;
}

export async function getManagementURLs() {
  const res = await apiClient.get<{ editBillingInfoUrl: string | null; cancellationUrl: string | null }>(
    `/v1/payment/management`
  );
  return res.data;
}

export async function getInvoices() {
  const res = await apiClient.get<{ invoices: InvoiceDetails[] }>(`/v1/payment/invoices`);
  return res.data.invoices;
}
