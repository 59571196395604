import { Button } from '@solid/shared/ui';
import { useMutation } from '@tanstack/react-query';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { filter } from 'rxjs/operators';
import { AuthContext } from '../../contexts/auth.context';
import { getCheckoutUrl, openPaddlePopup, paddleEvent } from '../../services/payment.service';
import * as StyledPaymentPopup from './PaymentPopup.styles';
import * as Styled from './PaymentPopupCheckout.styles';

interface PaymentPopupCheckoutProps {
  onComplete: () => void;
  onBack: () => void;
}

const PaymentPopupCheckout: React.FC<PaymentPopupCheckoutProps> = ({ onComplete, onBack }) => {
  const { auth } = useContext(AuthContext);
  const [paymentSuccess, setPaymentSuccess] = useState<'paddle' | 'invoice' | null>(null);
  const [invoiceComment, setInvoiceComment] = useState('');

  const {
    mutate: startCheckout,
    isLoading,
    error,
  } = useMutation<void, Error, void>(async () => {
    const url = await getCheckoutUrl(invoiceComment);
    openPaddlePopup(url);
  });

  const mailToLink = useMemo(() => {
    const subject = encodeURIComponent(
      `SOLID | Anfrage PLUS Jahresrechnung (${
        auth.user.companyBillingName ? auth.user.companyBillingName : auth.user.companyName
      })`
    );
    const body = encodeURIComponent(
      `Guten Tag,

hiermit buche ich SOLID PLUS für 29 € im Monat ab dem nächsten Monatsersten.

Meine Daten:

Firmenname: ${auth.user.companyName}
Straße und Hausnr.: ${auth.user.companyStreet}
Stadt: ${auth.user.companyCity}
PLZ: ${auth.user.companyZipCode}
Land: ${auth.user.companyCountry}
Ust-IdNr: ${auth.user.companyVatId}
Rechnungsempfänger E-Mail-Adresse: ${auth.user.companyEmail ? auth.user.companyEmail : auth.user.email}${
        auth.user.companyDifferentBillingAddress
          ? `

Abweichende Rechnungsadresse:

Firmenname: ${auth.user.companyBillingName}
Straße und Hausnr.: ${auth.user.companyBillingStreet}
Stadt: ${auth.user.companyBillingCity}
PLZ: ${auth.user.companyBillingZipCode}
Land: ${auth.user.companyBillingCountry}
`
          : ''
      }
${
  invoiceComment &&
  `
Zusätzliche Angaben: ${invoiceComment}
`
}
Meine User-ID: ${auth.user.id}


Der Vertrag wird bei Erstlizenzierung bis zum Jahresende geschlossen. Der Vertrag verlängert sich danach immer zum 1. Januar eines Jahres.  Einmal im Jahr erhalten Sie eine Jahresrechnung.
Eine ordentliche Kündigung des Abonnements ist jederzeit bis zu vier Wochen vor Jahresende schriftlich möglich. Kündigungen per E-Mail sind an folgende E-Mailadresse zu richten: info@solid-app.de. Preisänderungen lösen ein Sonderkündigungsrecht aus.

Mit freundlichen Grüßen, 

${auth.user.firstName} ${auth.user.lastName}
`
    );
    return `mailto:info@solid-app.de?subject=${subject}&body=${body}`;
  }, [auth, invoiceComment]);

  useEffect(() => {
    // Show thank you message upon successfully completing the checkout
    paddleEvent
      .pipe(
        filter(data => {
          return (
            data.event === 'Checkout.Close' &&
            (data.eventData as { checkout: { completed: boolean } }).checkout.completed === true
          );
        })
      )
      .subscribe(() => setPaymentSuccess('paddle'));
  }, []);

  return (
    <Styled.Wrapper>
      {!paymentSuccess ? (
        <Styled.Headline>
          Bitte wählen Sie eine <span>Bezahlmethode</span>.
        </Styled.Headline>
      ) : (
        <Styled.Headline>
          <span>Vielen Dank</span> für Ihr Vertrauen.
        </Styled.Headline>
      )}

      {!paymentSuccess ? (
        <div>
          <Styled.InvoiceCommentContainer className='form-row'>
            <div className='form-field'>
              <label htmlFor='invoiceComment'>Zusätzliche Angaben (optional)</label>
              <div className='control'>
                <input
                  type='text'
                  name='invoiceComment'
                  id='invoiceComment'
                  placeholder='Bestellnummer 123ABC'
                  maxLength={500}
                  value={invoiceComment}
                  onChange={e => setInvoiceComment(e.currentTarget.value)}
                />
              </div>
            </div>
          </Styled.InvoiceCommentContainer>

          <Styled.PaymentMethodContainer>
            <Button onClick={() => setPaymentSuccess('invoice')} href={mailToLink}>
              Rechnung / Überweisung
            </Button>
            <Button isLoading={isLoading} onClick={() => startCheckout()}>
              Kreditkarte / PayPal
            </Button>
          </Styled.PaymentMethodContainer>
        </div>
      ) : paymentSuccess === 'invoice' ? (
        // Invoice success
        <Styled.SuccessMessage>
          <p>
            Es hat sich eine E-Mail Maske in ihrem Standard-E-Mail-Programm geöffnet. Bitte senden Sie diese ab - wir
            melden uns zeitnah bei Ihnen.
            <br />
            Im Anschluss wird der Plan Ihres Accountes auf PLUS hochgestuft.
          </p>

          <Button variant='outlined' href={mailToLink}>
            E-Mail-Maske erneut öffnen
          </Button>

          <p>
            Bei Fragen können Sie uns unter <a href='mailto:info@solid-app.de'>info@solid-app.de</a> erreichen.
          </p>

          <Link to='/' onClick={() => onComplete()}>
            <Button>Zu den Prüfungen</Button>
          </Link>
        </Styled.SuccessMessage>
      ) : (
        // Paddle success
        <Styled.SuccessMessage>
          <p>Ihr Account wird automatisch auf den PLUS Plan hochgestuft, sobald Ihre Zahlung eingegangen ist.</p>

          <p>
            Bei Fragen können Sie uns unter <a href='mailto:info@solid-app.de'>info@solid-app.de</a> erreichen.
          </p>

          <Link to='/' onClick={() => onComplete()}>
            <Button>Zu den Prüfungen</Button>
          </Link>
        </Styled.SuccessMessage>
      )}

      {error && <div className='notification is-error'>{error.message}</div>}

      {!paymentSuccess && (
        <StyledPaymentPopup.BtnContainer $justifyContent='left'>
          <Button variant='outlined' onClick={() => onBack()}>
            Zurück
          </Button>
        </StyledPaymentPopup.BtnContainer>
      )}
    </Styled.Wrapper>
  );
};

export default PaymentPopupCheckout;
