import styled from 'styled-components';
import DataCard from '@solid/shared/components/analytics/DataCard';
import { Color } from '@solid/shared/styles/colors';

export const CardContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;
  color: ${Color.Neutral_1000_Black};
  @media (${props => props.theme.breakpoint.md.up}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const ChartContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;
  margin: 2rem 0;
  color: ${Color.Neutral_1000_Black};
`;

export const ChartCard = styled(DataCard)`
  min-height: 378px;
`;
