import styled, { createGlobalStyle, css } from 'styled-components';
import { ThemeType } from './theme';
import { Color } from './colors';
import { transparentize } from 'polished';

export const GlobalReactDataGridStyles = createGlobalStyle<{ theme: ThemeType }>`${css`
  .rdg {
    --rdg-border-color: ${Color.Neutral_300};
    border-radius: ${props => props.theme.borderRadius.small};
    border-color: ${Color.Neutral_300};
  }

  .rdg-header-row {
    background-color: ${Color.Neutral_100};
    color: ${transparentize(0.15, Color.Neutral_800)};
  }

  .rdg-cell {
    &:last-child {
      border-right: none;
    }
  }
`}
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 100%;
`;

export const BadgesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2px;
  height: 100%;
`;
