import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAbsolvedCheckAbility } from '../../hooks/useAbsolvedCheckAbility';
import ChecksList from '../checks/ChecksList';

const OrgChecksPage: React.FC = () => {
  const ability = useAbsolvedCheckAbility();

  if (ability.cannot('read', 'AbsolvedCheck')) {
    return <Navigate to='/' />;
  }

  return (
    <>
      <h2>Unternehmensarchiv</h2>

      <ChecksList variant='org' />
    </>
  );
};

export default OrgChecksPage;
