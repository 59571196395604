import React from 'react';
import * as Styled from './Button.styles';
import LoadingSpinner from './LoadingSpinner';

export interface ButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement | HTMLAnchorElement>, 'type' | 'ref' | 'as' | 'forwardedAs'> {
  children: React.ReactNode;
  themeColor?: Styled.ButtonProps['$themeColor'];
  isSmall?: boolean;
  hasIconOnly?: boolean;
  variant?: Styled.ButtonProps['$variant'];
  isLoading?: boolean;
  type?: string;
  href?: string;
}

const Button: React.FC<ButtonProps> = ({
  themeColor = 'primary',
  isSmall = false,
  isLoading = false,
  children,
  href,
  hasIconOnly = false,
  variant,
  ...props
}) => {
  return (
    <Styled.Button
      as={href ? 'a' : 'button'}
      href={href}
      {...props}
      $themeColor={themeColor}
      $isSmall={isSmall}
      $isLoading={isLoading}
      $hasIconOnly={hasIconOnly}
      $variant={variant}>
      <Styled.Content>{children}</Styled.Content>
      {isLoading && <LoadingSpinner themeColor='contrast' />}
    </Styled.Button>
  );
};

export default Button;
