import { USER_SETTING, UserSettingValueMap } from '@solid/shared';
import { useEffect, useState } from 'react';
import { getSettingFromApi, getSettingPlaceholderValue, setSettingValue } from '../services/setting.service';

export function useSetting<T extends USER_SETTING>(
  setting: USER_SETTING
): [UserSettingValueMap[T] | null, React.Dispatch<React.SetStateAction<UserSettingValueMap[T]>>] {
  const [value, setValue] = useState<UserSettingValueMap[T] | null>(getSettingPlaceholderValue(setting));

  // TODO can we make this any nicer?

  /**
   * Get setting value from API
   */
  useEffect(() => {
    (async () => {
      const settingValue = await getSettingFromApi(setting);
      setValue(settingValue);
    })();
  }, []);

  /**
   * Sync setting value with API
   */
  useEffect(() => {
    (async () => {
      try {
        await setSettingValue(setting, JSON.stringify(value));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [value]);

  return [value, setValue];
}
