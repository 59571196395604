import { Yup } from '@solid/shared/services/validation.service';
import { useMutation } from '@tanstack/react-query';
import countries from 'i18n-iso-countries';
import React, { useEffect, useState } from 'react';
import { ReactComponent as IconSuccess } from '../../../assets/images/icons/checkup-success.svg';
import {
  AddressMatchSchema,
  getAddressMatch,
  getMultiAddressMatch,
  saveAddressMultiMatch,
} from '../../../services/check.service';
import * as Styled from './CheckIndividualMultiTest.styles';

// Load german translations for countries
import { CHECK_TYPE_SLUG, IndividualTestMatch } from '@solid/shared';
import { Button, LoadingSpinner } from '@solid/shared/ui';
import countriesGerman from 'i18n-iso-countries/langs/de.json';
import countriesEnglish from 'i18n-iso-countries/langs/en.json';
import { useNavigate } from 'react-router-dom';
import CheckIndividualTestMatches from '../invidiual-test/CheckIndividualTestMatches';
import CheckIndividualTestSearchForm from '../invidiual-test/CheckIndividualTestSearchForm';
import SaveForm, { Values as SaveFormValues } from '../invidiual-test/SaveForm';
import CheckIndividualMultiTestFileUpload from './CheckIndividualMultiTestFileUpload';
import { useKirbyChecks } from '../../../hooks/useKirbyData';
import CheckNotFound from '../CheckNotFound';
countries.registerLocale(countriesGerman);
countries.registerLocale(countriesEnglish);

const CheckIndividualMultiTest: React.FC = () => {
  const [fileName, setFileName] = useState('');
  const [addresses, setAddresses] = useState<Yup.InferType<typeof AddressMatchSchema>[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentHitIndex, setCurrentHitIndex] = useState<number>(0);
  const [saveFormValues, setSaveFormValues] = useState<SaveFormValues[]>([]);
  const navigate = useNavigate();

  const {
    mutate: checkMultiAdresses,
    error: checkAdressMultiError,
    isLoading: isLoadingMulti,
    data: resultMulti,
  } = useMutation<Awaited<ReturnType<typeof getMultiAddressMatch>>, Error>(() => getMultiAddressMatch(addresses), {
    onSuccess: data => {
      const firstHitIndex = data.findIndex(entry => entry.hasMatches === true);
      setCurrentIndex(firstHitIndex);
      setCurrentHitIndex(0);
      setSaveFormValues(new Array(data.length).fill({ isHit: false, note: '', hasMatches: false }));
    },
  });

  const { data: checks } = useKirbyChecks();

  const {
    mutate: checkAddressSingle,
    error: checkAdressSingleError,
    isLoading: isLoadingSingle,
    data: resultSingle,
  } = useMutation<{ matchesFound: IndividualTestMatch[] }, Error, Yup.InferType<typeof AddressMatchSchema>>(values =>
    getAddressMatch(values)
  );

  const {
    mutate: saveResult,
    error: saveResultError,
    isLoading: isLoadingSaveResult,
    data: resultSaveResult,
  } = useMutation<{ checkId: string }, Error>(
    () => {
      const resultToSave = resultMulti.map((entry, i) => {
        const hitFormValuesEntry = saveFormValues[i];
        return {
          ...entry.address,
          hasMatches: hitFormValuesEntry.hasMatches,
          isHit: hitFormValuesEntry.isHit,
          note: hitFormValuesEntry.note,
        };
      });

      return saveAddressMultiMatch(resultToSave, fileName);
    },
    {
      onSuccess: data => {
        navigate(`/checks/${data.checkId}/report`);
      },
    }
  );

  const hits = (resultMulti ?? []).filter(entry => entry.hasMatches === true);
  const currentHit = (resultMulti ?? [])[currentIndex];

  useEffect(() => {
    if (currentHit) {
      checkAddressSingle(currentHit.address);
    }
  }, [currentHit, checkAddressSingle]);

  const handleSaveFormSubmit = (values: SaveFormValues) => {
    setSaveFormValues(currentValues => {
      const newValues = [...currentValues];
      newValues[currentIndex] = values;
      return newValues;
    });

    const nextHitIndex = resultMulti.findIndex((entry, i) => i > currentIndex && entry.hasMatches === true);

    if (nextHitIndex === -1) {
      saveResult();
    } else {
      setCurrentIndex(nextHitIndex);
      setCurrentHitIndex(currentHitIndex + 1);
    }
  };

  const multiTestCheck = checks?.find(check => check.slug === CHECK_TYPE_SLUG.INDIVIDUAL_TEST_MULTI);
  if (!multiTestCheck) {
    return <CheckNotFound />;
  }

  const resultText = multiTestCheck.howToTextAlternative
    .replace(/{{addressesCount}}/g, addresses.length.toString())
    .replace(/{{hitsCount}}/g, hits.length.toString());
  return (
    <>
      <Styled.Headline>{multiTestCheck.title}</Styled.Headline>
      <Styled.Container>
        {!resultMulti ? (
          <Styled.InfoText dangerouslySetInnerHTML={{ __html: multiTestCheck.howToText }}></Styled.InfoText>
        ) : (
          <Styled.InfoText
            dangerouslySetInnerHTML={{
              __html: resultText,
            }}></Styled.InfoText>
        )}

        {(!resultMulti || (!!resultMulti && hits.length === 0)) && (
          <CheckIndividualMultiTestFileUpload
            onSubmit={(data, fileName) => {
              setAddresses(data);
              setFileName(fileName);
              checkMultiAdresses();
            }}
          />
        )}

        {currentHit && <CheckIndividualTestSearchForm prefillValues={currentHit.address} />}
      </Styled.Container>

      <Styled.ResultContainer>
        {isLoadingMulti && (
          <Styled.NoResultContainer>
            <LoadingSpinner />
          </Styled.NoResultContainer>
        )}

        {checkAdressMultiError && (
          <div className='form-field'>
            <div className='notification is-error'>{checkAdressMultiError.message}</div>
          </div>
        )}

        {resultMulti &&
          (hits.length === 0 ? (
            <Styled.NoResultContainer>
              <Styled.NoResult>
                <span>Keine Treffer / No match</span>
                <IconSuccess />
              </Styled.NoResult>

              <Button themeColor='primary' onClick={() => saveResult()}>
                Save result
              </Button>
            </Styled.NoResultContainer>
          ) : (
            <Styled.CurrentHitInfo>
              Sie bearbeiten jetzt Treffer {currentHitIndex + 1} von {hits.length}.
              <br />
              You are now processing match {currentHitIndex + 1} of {hits.length}.
            </Styled.CurrentHitInfo>
          ))}

        {/* TODO add loading state while single address result is loading */}

        {resultSingle && (
          // TODO show information, when hit has already been tested some time ago
          <SaveForm
            matches={resultSingle.matchesFound}
            onSubmit={values => handleSaveFormSubmit(values)}
            btnSaveLabel={hits.length - currentHitIndex === 1 ? 'Save all results' : undefined}
          />
        )}

        {resultSingle?.matchesFound.length > 0 && <CheckIndividualTestMatches matches={resultSingle.matchesFound} />}
      </Styled.ResultContainer>
    </>
  );
};

export default CheckIndividualMultiTest;
