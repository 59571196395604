import { User } from '@solid/shared';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useContext } from 'react';
import { Yup } from '@solid/shared/services/validation.service';
import { AuthContext } from '../../contexts/auth.context';
import * as authService from '../../services/auth.service';
import styles from '../common/Forms.module.scss';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Ungültige E-Mail-Adresse').required('Bitte geben Sie eine E-Mail-Adresse an'),
  password: Yup.string().required('Bitte geben Sie ein Passwort an'),
  keepLoggedIn: Yup.boolean(),
});

const LoginForm: React.FC = () => {
  const { refetchAuthState } = useContext(AuthContext);

  const {
    mutate: login,
    error,
    isLoading,
  } = useMutation<User, Error, { email: string; password: string; keepLoggedIn: boolean }>(
    ({ email, password, keepLoggedIn }: { email: string; password: string; keepLoggedIn: boolean }) =>
      authService.loginUser(email, password, keepLoggedIn),
    {
      // We are refetching the user auth state -> this automatically redirects them to the home screen
      onSuccess: () => refetchAuthState(),
    }
  );

  return (
    <Formik
      initialValues={{ email: '', password: '', keepLoggedIn: false }}
      onSubmit={values => login(values)}
      validationSchema={LoginSchema}>
      {({ isValid }) => (
        <Form>
          <div className='form-field'>
            <label htmlFor='login-email'>E-Mail-Adresse</label>
            <div className='control'>
              <Field type='email' name='email' id='login-email' autoComplete='email' required />
            </div>
            <ErrorMessage component='p' className='help is-danger' name='email' />
          </div>

          <div className='form-field'>
            <label htmlFor='login-password'>Passwort</label>
            <div className='control'>
              <Field type='password' name='password' id='login-password' autoComplete='off' required />
            </div>
            <ErrorMessage component='p' className='help is-danger' name='password' />
          </div>

          <div className='form-field'>
            <label className='checkbox' htmlFor='login-keep-me-logged-in'>
              <Field type='checkbox' id='login-keep-me-logged-in' name='keepLoggedIn' />
              <span>Angemeldet bleiben</span>
            </label>
          </div>

          <div className='form-field'>{error && <div className='notification is-error'>{error.message}</div>}</div>

          <div className='form-field'>
            <button
              className={classNames('btn is-primary', styles.submitBtn, { 'is-loading': isLoading })}
              type='submit'
              disabled={isLoading || !isValid}>
              Einloggen
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
