import { mdiStarOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { USER_SETTING, getCheckTypeFromSlug } from '@solid/shared';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/auth.context';
import { useKirbyCheckGroups, useKirbyChecks } from '../../hooks/useKirbyData';
import { useSetting } from '../../hooks/useSetting';
import { canUseFavorites } from '../../services/setting.service';
import Layout from '../global/Layout';
import CheckGroup from './CheckGroup';
import * as Styled from './HomePage.styles';

// Virtual slug for favorites
const FAVORITES_SLUG = 'favoriten';
const ORGANIZATION_SLUG = 'organisation';

const HomePage: React.FC = () => {
  const { auth } = useContext(AuthContext);
  const firstTimeUser = auth.user.lastActive === null;
  const { data: checkGroups } = useKirbyCheckGroups();
  const { data: kirbyChecks } = useKirbyChecks();
  const [favorites, setFavorites] = useSetting(USER_SETTING.CHECK_FAVORITES);

  const [openGroups, setOpenGroups] = useState(() => {
    /**
     * Get initial state:
     * - If user has favorites, open favorites group
     * - If user has no favorites, open first group
     * - If url has a hash, open group with that slug
     */
    const urlHash = window.location.hash.replace('#', '');
    if (urlHash) {
      const foundGroup = checkGroups?.find(group => group.slug === urlHash);
      if (foundGroup) {
        return [foundGroup.slug];
      }
    }
    if (favorites?.length > 0) {
      return [FAVORITES_SLUG];
    }
    return [checkGroups?.[0].slug ?? null];
  });

  const favoriteChecks = kirbyChecks?.filter(check => favorites?.includes(getCheckTypeFromSlug(check.slug)));
  const orgChecks = kirbyChecks?.filter(check => check.isOrgExclusive === true);

  const toggleGroup = (slug: string) => {
    if (openGroups.includes(slug)) {
      setOpenGroups(openGroups.filter(s => s !== slug));
    } else {
      setOpenGroups([...openGroups, slug]);
    }
  };

  const groups = [
    ...(canUseFavorites(auth.user.plan)
      ? [{ name: 'Favoriten', slug: FAVORITES_SLUG, checks: favoriteChecks, flag: 'favorites' }]
      : []),
    ...(orgChecks?.length > 0
      ? [{ name: 'Individuelle Prüfungen', slug: ORGANIZATION_SLUG, checks: orgChecks, flag: 'organization' }]
      : []),
    ...(checkGroups ?? []),
  ];

  return (
    <Layout showSidebar>
      <div>
        <Styled.Headline>
          Hallo {`${auth.user?.firstName} ${auth.user?.lastName}`},
          <br />
          <span>willkommen {!firstTimeUser && 'zurück'} in Ihrem Bereich!</span>
        </Styled.Headline>

        <Styled.SubHeadline>Wählen Sie unter folgenden Prüfungen aus</Styled.SubHeadline>

        {groups.map(group => (
          <CheckGroup
            key={group.slug}
            isOpen={openGroups.includes(group.slug)}
            onClick={() => toggleGroup(group.slug)}
            group={group}
            favorites={favorites ?? []}
            onFavoriteStateChange={checkType => {
              if ((favorites ?? []).includes(checkType)) {
                setFavorites(favorites.filter(f => f !== checkType));
              } else {
                setFavorites([...(favorites ?? []), checkType]);
              }
            }}
            emptyState={
              group.slug === FAVORITES_SLUG ? (
                <Styled.FavoritesEmptyState>
                  Sie haben noch keine Favoriten ausgewählt.
                  <br />
                  Nutzen Sie die <Icon size='1em' path={mdiStarOutline} /> Schaltfläche, um eine Prüfung zu Ihren
                  Favoriten hinzuzufügen.
                </Styled.FavoritesEmptyState>
              ) : null
            }
          />
        ))}

        <Styled.FeedbackContainer>
          <h4>
            Wünsche? Verbesserungsvorschläge?
            <br />
            Wir freuen uns auf Ihr{' '}
            <a href='https://aebcom.typeform.com/to/juAcK9' target='_blank' rel='noopener noreferrer'>
              Feedback
            </a>
            .
          </h4>
        </Styled.FeedbackContainer>
      </div>
    </Layout>
  );
};

export default HomePage;
