import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { CHECK_TYPE } from '@solid/shared';
import { LoadingSpinner } from '@solid/shared/ui';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { CheckResultResponse, getCheckResult } from '../../services/check.service';
import Layout from '../global/Layout';
import CheckReport from './CheckReport';
import styles from './CheckReportPage.module.scss';
import CheckReportPageMultiTest from './individual-multi-test/CheckReportPageMultiTest';

const CheckReportPage: React.FC = () => {
  const { checkId } = useParams<{ checkId: string }>();
  const [instantPrint, setInstantPrint] = useState(false);
  const [searchParams] = useSearchParams();

  const context = (searchParams.get('context') ?? 'personal') as 'personal' | 'org';
  const parentId = searchParams.get('parentId');

  const {
    data: check,
    isLoading,
    error,
  } = useQuery(
    ['checkResult', checkId],
    async () => {
      if (checkId === 'prefill') {
        const prefillData = searchParams.get('prefillData');
        const parsed = JSON.parse(window.atob(prefillData)) as CheckResultResponse;
        parsed.submittedAt = new Date(parsed.submittedAt);
        return parsed;
      }

      const data = await getCheckResult(checkId);
      return data;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setInstantPrint(searchParams.has('print'));
      },
    }
  );

  useEffect(() => {
    // Run this in a `useEffect` hook to invoke only after rendering and only once
    if (instantPrint) {
      window.print();
    }
  }, [instantPrint]);

  let backLink = '/checks';
  if (parentId) {
    backLink = `/checks/${parentId}/report`;
  } else if (context === 'org') {
    backLink = '/org/checks';
  }

  if (check?.type === CHECK_TYPE.INDIVIDUAL_TEST_MULTI) {
    return <CheckReportPageMultiTest check={check} checkId={checkId} context={context} />;
  }

  return (
    <Layout>
      <div className='wrapper'>
        <h1 className={styles.headline}>Ergebnisprotokoll</h1>

        {isLoading && (
          <div className={styles.center}>
            <LoadingSpinner />
          </div>
        )}

        {error && <div className={classNames('notification is-error', styles.center)}>{(error as Error).message}</div>}

        {check && (
          <>
            <CheckReport check={check} />

            <div className={styles.exportWrapper}>
              <button className='btn is-primary is-outlined' onClick={() => window.print()}>
                Drucken / PDF erstellen
              </button>
              <p className={styles.exportText}>
                <Icon path={mdiInformationOutline} size='20px' />
                Um das Protokoll als PDF zu exportieren, wählen Sie im Drucker-Menü "Als PDF speichern".
              </p>
            </div>
          </>
        )}

        <div className={styles.btnWrapper}>
          <Link className={classNames('btn is-primary is-outlined', styles.btn)} to={backLink}>
            {parentId ? 'Zur Protokollübersicht' : 'Zum Prüfungsarchiv'}
          </Link>
          <Link className={classNames('btn is-primary', styles.btn)} to='/'>
            Zurück zu den Prüfungen
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default CheckReportPage;
