import { CHECK_TYPES_NEEDED_PLANS, getCheckTypeFromSlug, PLAN } from '@solid/shared';
import classNames from 'classnames';
import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PaymentPopupContext } from '../../contexts/payment-popup.context';
import { AuthContext } from '../../contexts/auth.context';
import { useKirbyChecks } from '../../hooks/useKirbyData';
import PlanBadge from '../common/PlanBadge';
import './TutorialPage.scss';
import { LoadingSpinner } from '@solid/shared/ui';

const TutorialPage: React.FC = () => {
  const { tutorialId } = useParams<{ tutorialId: string }>();
  const { auth } = useContext(AuthContext);
  const paymentPopup = useContext(PaymentPopupContext);

  const { data: checks } = useKirbyChecks();

  if (!checks) {
    return (
      <div className='tutorial' style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
        <LoadingSpinner themeColor='primary' />
      </div>
    );
  }

  const check = checks.find(entry => entry.slug === tutorialId);
  const checkType = getCheckTypeFromSlug(check.slug);
  const isPlusCheck = CHECK_TYPES_NEEDED_PLANS[checkType].includes(PLAN.FREE) === false;
  const canStartCheck = CHECK_TYPES_NEEDED_PLANS[checkType].includes(auth.user.plan);

  return (
    <div className='tutorial'>
      <div className={classNames('tutorial-wrapper')}>
        <div className='left'>
          <div className='head-wrapper'>
            {check.flag && (
              <div className='flag-wrapper'>
                <img src={check.flag} alt='' />
              </div>
            )}
            <p className='question' dangerouslySetInnerHTML={{ __html: check.question }}></p>
          </div>
          <hr />
          <div className='body-wrapper'>
            <h3 className='title'>{check.howToHeadline}</h3>
            <div className='description' dangerouslySetInnerHTML={{ __html: check.howToText }}></div>
            <div className='plan-badge-container'>{isPlusCheck && <PlanBadge plan={PLAN.PLUS} />}</div>
            {check.active &&
              (canStartCheck ? (
                <Link className='btn is-primary' to={`/checks/new/${check.slug}`}>
                  Jetzt prüfen
                </Link>
              ) : (
                <button className='btn is-primary' onClick={() => paymentPopup.setOpen(true)}>
                  Prüfung jetzt freischalten
                </button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialPage;
