import styled from 'styled-components';

export const InfoContainer = styled.div`
  margin-top: 4rem;
`;

export const Form = styled.form`
  margin-top: 0 !important;
  width: auto !important;
  text-align: center;

  button[type='submit'] {
    margin: 2rem auto 0;
  }

  input[type='checkbox'] {
    margin-top: 6px;
  }
`;
