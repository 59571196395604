import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import countries from 'i18n-iso-countries';
import React, { useContext, useState } from 'react';
import { Yup } from '@solid/shared/services/validation.service';
import { ReactComponent as IconSuccess } from '../../../assets/images/icons/checkup-success.svg';
import {
  AddressMatchSaveSchema,
  AddressMatchSchema,
  getAddressMatch,
  saveAddressMatch,
} from '../../../services/check.service';
import styles from './CheckIndividualTest.module.scss';

// Load german translations for countries
import { CHECK_TYPE_SLUG, IndividualTestMatch, isPaidPlan, PLAN } from '@solid/shared';
import { LoadingSpinner } from '@solid/shared/ui';
import countriesGerman from 'i18n-iso-countries/langs/de.json';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/auth.context';
import CheckIndividualTestMatches from './CheckIndividualTestMatches';
import CheckIndividualTestSaveForm from './SaveForm';
import CheckIndividualTestSearchForm from './CheckIndividualTestSearchForm';
import { useKirbyChecks } from '../../../hooks/useKirbyData';
countries.registerLocale(countriesGerman);

const CheckIndividualTest: React.FC = () => {
  const { auth } = useContext(AuthContext);
  const [address, setAddress] = useState<Yup.InferType<typeof AddressMatchSchema>>();
  const navigate = useNavigate();
  const { data: checks } = useKirbyChecks();

  const {
    mutate: checkAddress,
    error: checkAdressError,
    isLoading,
    data: result,
  } = useMutation<{ matchesFound: IndividualTestMatch[] }, Error, Yup.InferType<typeof AddressMatchSchema>>(
    values => getAddressMatch(values),
    {
      onSuccess: (_data, address) => {
        setAddress(address);
      },
    }
  );

  const { mutate: saveResult, error: saveAddressMatchError } = useMutation<
    { checkId: string },
    Error,
    Yup.InferType<typeof AddressMatchSaveSchema>
  >(values => saveAddressMatch(address, values), {
    onSuccess: data => {
      navigate(`/checks/${data.checkId}/report`);
    },
  });

  const individualTestCheck = checks?.find(check => check.slug === CHECK_TYPE_SLUG.INDIVIDUAL_TEST);

  return (
    <div className={classNames('wrapper', styles.wrapper)}>
      <h2 className={styles.headline}>{individualTestCheck.title}</h2>
      <div className={styles.textFormContainer}>
        {!individualTestCheck ? null : !result ? (
          <div className={styles.infoText} dangerouslySetInnerHTML={{ __html: individualTestCheck.howToText }}></div>
        ) : (
          <div
            className={styles.infoText}
            dangerouslySetInnerHTML={{ __html: individualTestCheck.howToTextAlternative }}></div>
        )}

        <CheckIndividualTestSearchForm onSubmit={values => checkAddress(values)} />
      </div>

      <div className={styles.result}>
        {isLoading && !result && (
          <div className={styles.noResult}>
            <LoadingSpinner />
          </div>
        )}

        {(checkAdressError || saveAddressMatchError) && (
          <div className='form-field'>
            <div className='notification is-error'>
              {checkAdressError ? checkAdressError.message : saveAddressMatchError.message}
            </div>
          </div>
        )}

        {(!result || isPaidPlan(auth.user.plan) === false) && (
          <div className={styles.backBtnContainer}>
            <Link className='btn is-primary is-outlined' to='/'>
              Cancel
            </Link>
          </div>
        )}

        {result && isPaidPlan(auth.user.plan) && (
          <CheckIndividualTestSaveForm matches={result.matchesFound} onSubmit={values => saveResult(values)} />
        )}

        {result?.matchesFound.length <= 0 && (
          <div className={styles.noResult}>
            <span>Kein Treffer / No match</span>
            <IconSuccess />
          </div>
        )}
        {result?.matchesFound.length > 0 && <CheckIndividualTestMatches matches={result.matchesFound} />}
      </div>
    </div>
  );
};

export default CheckIndividualTest;
