import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { ChartDataEntry } from '../../services/analytics.service';
import EmptyState from './EmptyState';

export interface PieChartProps {
  data: ChartDataEntry[];
}

const getColorByHue = (hue: number) => `hsl(${hue}deg 70% 60%)`;

const PieChartComponent: React.FC<PieChartProps> = ({ data }) => {
  const sumValue = data.reduce((acc, curr) => acc + curr.value, 0);
  return (
    <ResponsiveContainer height={300}>
      {sumValue === 0 ? (
        <EmptyState />
      ) : (
        <PieChart margin={{ top: 10 }}>
          <Pie data={data} dataKey='value' label>
            {data.map((_, index) => (
              <Cell key={index} fill={getColorByHue((360 * index) / data.length)} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      )}
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
