import PiwikPro from '@piwikpro/react-piwik-pro';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import { checkConsentState, sendLinkedInPageView } from '../../services/tracking.service';

/**
 * Listen to any modifications to the `dataLayer` array and broadcast it through a CustomEvent to whoever wants to be notified about it.
 */
window.dataLayer = new Proxy([], {
  set: (obj, prop, value) => {
    if (prop !== 'length') {
      window.dispatchEvent(
        new CustomEvent('dataLayerPush', {
          detail: value,
        })
      );
    }
    return Reflect.set(obj, prop, value);
  },
});

if (environment.piwikPro.containerId && environment.piwikPro.containerUrl) {
  PiwikPro.initialize(environment.piwikPro.containerId, environment.piwikPro.containerUrl);
}

const Tracking: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const listener = event => {
      if (['stg.domReady', 'stg.consentDecisionMade'].includes(event.detail?.event)) {
        checkConsentState();
      }
    };

    window.addEventListener('dataLayerPush', listener);
    return () => {
      window.removeEventListener('dataLayerPush', listener);
    };
  }, []);

  useEffect(() => {
    sendLinkedInPageView();
  }, [location.pathname]);

  return null;
};

export default Tracking;
