import styled from 'styled-components';

export const ContentWrapper = styled.div`
  > h1 {
    margin-bottom: 0;
  }

  h4 {
    margin-top: 40px;
  }
`;
