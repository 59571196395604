import styled, { css } from 'styled-components';
import { Color } from '../styles/colors';
import * as StyledLoadingSpinner from './LoadingSpinner.styles';
import { transparentize } from 'polished';

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;
export interface ButtonProps {
  $themeColor: 'primary' | 'danger' | 'grey' | 'warning';
  $isLoading?: boolean;
  $isSmall?: boolean;
  $hasIconOnly?: boolean;
  $variant?: 'transparent' | 'outlined';
}

const buttonThemeColor = {
  primary: {
    normal: Color.Blue_500_Primary,
    hover: Color.Blue_600,
  },
  danger: {
    normal: Color.Red_500_Danger,
    hover: Color.Red_700,
  },
  grey: {
    normal: Color.Neutral_500,
    hover: Color.Neutral_600,
  },
  warning: {
    normal: Color.Yellow_500_Warning,
    hover: Color.Yellow_600,
  },
};

export const Content = styled.span`
  line-height: 1;
`;

export const Button = styled.button<ButtonProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: ${props => (props.$isSmall === true ? props.theme.buttonMinHeightSmall : props.theme.buttonMinHeight)};
  min-width: 120px;
  line-height: 1.1;
  padding: ${props => (props.$isSmall === true ? '6px 10px' : '12px 20px')};
  color: ${props =>
    props.$variant === 'outlined' ? buttonThemeColor[props.$themeColor].normal : Color.Neutral_0_White};
  font-weight: ${props => props.theme.fontWeight.medium};
  background-color: ${props => buttonThemeColor[props.$themeColor].normal};
  border-radius: ${props => props.theme.borderRadius.small};
  outline: none;
  text-decoration: none !important;
  border: ${props =>
    props.$variant === 'outlined' ? `2px solid ${buttonThemeColor[props.$themeColor].normal}` : 'none'};
  transition: background-color ${props => props.theme.transitionTiming};
  cursor: pointer;
  z-index: 1;

  ${props =>
    props.$isLoading &&
    css`
      cursor: wait !important;
      ${Content} {
        visibility: hidden;
      }

      &:active {
        pointer-events: none;
      }
    `}

  ${props =>
    props.$isSmall &&
    css`
      line-height: 1;
    `}

  ${props =>
    props.$hasIconOnly === false &&
    css`
      img,
      svg {
        display: inline-block;
        vertical-align: middle;
        width: 12px;
        height: 12px;
        margin-left: 4px;
        margin-bottom: 2px;
      }
    `}

  ${props =>
    props.$hasIconOnly &&
    css`
      width: 60px;
      width: ${props.$isSmall ? props.theme.buttonMinHeightSmall : props.theme.buttonMinHeight};
      height: ${props.$isSmall ? props.theme.buttonMinHeightSmall : props.theme.buttonMinHeight};
      padding: 0;
      min-width: 0;

      ${Content} {
        line-height: 0;
      }
    `}

    ${props =>
    (props.$variant === 'transparent' || props.$variant === 'outlined') &&
    css`
      background-color: transparent;
    `}

  &:hover:not(:disabled) {
    background-color: ${props =>
      props.$variant === 'transparent' || props.$variant === 'outlined'
        ? transparentize(0.8, Color.Neutral_0_White)
        : buttonThemeColor[props.$themeColor].hover};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;

    * {
      cursor: not-allowed;
    }
  }

  ${StyledLoadingSpinner.Indicator} {
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &::after {
      width: 18px;
      height: 18px;
      border-width: 3px;
    }
  }

  @media (${props => props.theme.breakpoint.xl.up}) {
    padding: ${props => props.theme.btnPadding};
  }
`;
