import { CheckResultDualUse } from '@solid/shared';
import React from 'react';
import { DUAL_USE_CATEGORY_LINK_MAP, DUAL_USE_CATEGORY_NAME_MAP, DUAL_USE_RESULT } from '../../services/check.service';
import CheckNoteForm from './CheckNoteForm';
import * as Styled from './CheckResultDualUse.styles';

interface CheckResultDualUseProps {
  result: CheckResultDualUse;
  checkId: string | number;
  note: string;
}

const CheckResultPageDualUse: React.FC<CheckResultDualUseProps> = ({ result, checkId, note }) => {
  let header = '';
  let resultingElement: JSX.Element = <p>Ein Fehler ist aufgetreten.</p>;

  switch (result.type) {
    case DUAL_USE_RESULT.NO_DUAL_USE: {
      header = 'Ergebnis ihrer Prüfung';
      resultingElement = (
        <Styled.Result>
          <p>
            Ihr überprüftes Gut <b>ist weder ein Rüstungsgut noch ein Dual-Use-Gut</b>.
          </p>
          <p>
            Das überprüfte Gut ist <b>nicht gelistet</b>.
          </p>
        </Styled.Result>
      );
      break;
    }
    case DUAL_USE_RESULT.MILITARY: {
      header = 'Ergebnis ihrer Prüfung';
      resultingElement = (
        <Styled.Result>
          <p>
            - Öffnen Sie die Rüstungsgüterliste über den Button Teil I Abschnitt A der Ausfuhrliste
            <br />
            - Bitte dokumentieren Sie Ihre Prüfung.
            <br />- Hinterlegen Sie die gefundene Güterlistennummer z.B. <i>0006a für ein Militärfahrzeug</i> in Ihren
            Stammdaten
          </p>
          <a
            className='btn'
            href='https://www.bafa.de/SharedDocs/Downloads/DE/Aussenwirtschaft/afk_gueterlisten_ausfuhrliste_abschnitt_a.pdf?__blob=publicationFile&v=7'
            target='_blank noopener noreferrer'>
            Zu Teil I Abschnitt A der Ausfuhrliste
          </a>
        </Styled.Result>
      );
      break;
    }
    case DUAL_USE_RESULT.NATIONAL: {
      header = 'Ergebnis ihrer Prüfung';
      resultingElement = (
        <Styled.Result>
          <p>
            Ihr Gut <b>ist nicht in Anhang I der Dual-Use-VO gelistet</b>.
          </p>
          <p>
            Ihr überprüftes Produkt <b>ist ein Dual-Use Gut nach Teil I Abschnitt B der Ausfuhrliste.</b>.
          </p>
          <p>Eine Genehmigungspflicht besteht für das/die genannte/n Bestimmungsziel/e.</p>
        </Styled.Result>
      );
      break;
    }
    case DUAL_USE_RESULT.CATEGORIES_LIST: {
      header = '2. Schritt: Technische Überprüfung';
      resultingElement = (
        <Styled.Result>
          <p>Bitte prüfen Sie ihr Gut anhand der ausgewählten Kategorie(n).</p>
          <p>
            Öffnen Sie dazu den Link auf die Kategorie im Anhang 1 der Dual-Use-Güterliste.
            <br />
            Es wird ein PDF heruntergeladen.
          </p>

          <Styled.ResultCategories>
            {result.categories?.map(cat => (
              <a
                key={cat}
                className='btn is-primary'
                href={DUAL_USE_CATEGORY_LINK_MAP[cat]}
                target='_blank'
                rel='noreferrer'>
                Kategorie {cat} - {DUAL_USE_CATEGORY_NAME_MAP[cat]} öffnen
              </a>
            ))}
          </Styled.ResultCategories>
          <p>Über Strg + F können Sie im Dokument nach Stichworten suchen.</p>
          <p>
            Gleichen Sie dann die technischen Eigenschaften Ihres Produktes mit den in der Güterlistennummer
            beschriebenen technischen Eigenschaften ab. Auszugehen ist vom Wortlaut der Listenpositionen. Sollten Sie
            eine Übereinstimmung feststellen, liegt ein Dual-Use-Gut vor.
          </p>
          <p>
            <b>
              TIPP: Haben Sie ein PLUS-Abonnement abgeschlossen, können Sie Ihr Ergebnis im Kommentarfeld dokumentieren.
              Unter „Mein Prüfungsarchiv“ können Sie jederzeit darauf zugreifen.
            </b>
          </p>
        </Styled.Result>
      );
    }
  }

  return (
    <Styled.CheckResultDualUse>
      <h1>{header}</h1>
      {resultingElement}

      <Styled.NoteContainer>
        <CheckNoteForm
          note={note}
          initialNote={`Prüfung durchgeführt durch: 
Geprüfte Position (Spezifikationen): `}
        />
      </Styled.NoteContainer>
    </Styled.CheckResultDualUse>
  );
};

export default CheckResultPageDualUse;
