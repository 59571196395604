import { ORGANIZATION_ROLE, ORGANIZATION_ROLE_NAME } from '@solid/shared';
import { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/auth.context';
import styles from './AccountDeletion.module.scss';
import AccountDeletionForm from './AccountDeletionForm';
import { Button } from '@solid/shared/ui';
import { Link } from 'react-router-dom';

const AccountDeletion: React.FC = () => {
  const [active, setActive] = useState(false);
  const { auth } = useContext(AuthContext);

  return (
    <div className={styles.wrapper}>
      {!active && (
        <button className='btn is-danger' onClick={() => setActive(true)}>
          Account löschen
        </button>
      )}
      {active && (
        <div className={styles.active}>
          {!auth.org && (
            <>
              <p>
                <b>Sie wollen SOLID verlassen?</b>
                <br />
                Die Löschung Ihres Accounts ist endgültig. Bitte stellen Sie sicher, dass Sie PLUS-Abonnements gekündigt
                haben, bevor Sie die Löschung fortsetzen.
              </p>
              <p>
                <b>Wichtiger Hinweis</b>: Ein Account kann nicht wieder hergestellt werden. Damit ist die Übersicht über
                Ihre bislang durchgeführten Prüfungen endgültig gelöscht.
              </p>
              <AccountDeletionForm />
            </>
          )}

          {auth.org &&
            (auth.orgMember.role === ORGANIZATION_ROLE.LOCAL_ADMIN ? (
              <>
                <p>
                  <b>Wichtiger Hinweis</b>: Dieser Account hat die Rolle "{ORGANIZATION_ROLE_NAME[auth.orgMember.role]}"
                  in der Organisation "{auth.org.displayName}" und kann daher nicht gelöscht werden. Bitte wenden Sie
                  sich an unseren Support.
                </p>
                <p>
                  <Link to='mailto:info@solid-app.de'>
                    <Button themeColor='danger'>Support kontaktieren</Button>
                  </Link>
                </p>
              </>
            ) : (
              <>
                <p>
                  <b>Sie wollen SOLID verlassen?</b>
                  <br />
                </p>

                <p>
                  <b>Wichtiger Hinweis</b>: Ihr Account ist Teil der Organisation "{auth.org.displayName}". Ihre
                  Löschanfrage muss von einem Administrator der Organisation bestätigt werden.
                </p>
                <AccountDeletionForm deletionNeedsConfirmation />
              </>
            ))}
        </div>
      )}
    </div>
  );
};

export default AccountDeletion;
