import { Color } from '@solid/shared/styles/colors';
import { Popup } from '@solid/shared/ui';
import styled from 'styled-components';

export const PopupWrapper = styled(Popup)`
  z-index: 99998 !important; // Has to be lower than the z-index of the Paddle checkout popup (which is 99999)
`;

export const Headline = styled.h2`
  margin: 0 0 1%.5;
  text-align: center;
  font-size: ${props => props.theme.fontSize.h3};

  @media (${props => props.theme.breakpoint.md.up}) {
    margin-bottom: 2rem;
  }

  span {
    font-weight: ${props => props.theme.fontWeight.semibold};
    color: ${Color.Blue_500_Primary};
  }
`;

export const BtnContainer = styled.div<{ $justifyContent: 'left' | 'right' | 'space-between' }>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.$justifyContent};
  margin-top: 2.5rem;

  @media (${props => props.theme.breakpoint.md.up}) {
    margin-top: 2rem;
  }
`;
