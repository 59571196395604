import { useContext } from 'react';
import { Navigate, Outlet, useMatch } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth.context';
import Layout from '../global/Layout';
import { useOrganizationAbility } from '../../hooks/useOrganizationAbility';

// This is imported only here as the data-grid is only used here. In case it would be used in other places, it should be moved to a global component such as `AppWrapper.tsx`
import 'react-data-grid/lib/styles.css';
import { StyledTabs } from '@solid/shared/ui';
import { mdiAccountMultiple, mdiChartLine } from '@mdi/js';
import Icon from '@mdi/react';

const OrgWrapperPage: React.FC = () => {
  const { auth } = useContext(AuthContext);

  const ability = useOrganizationAbility();

  const isOrgChecksRoute = !!useMatch('/org/checks');

  if (!auth.org || ability.cannot('read', 'Organization')) {
    return <Navigate to='/' />;
  }

  return (
    <Layout showSidebar>
      <h1>{auth.org.displayName}</h1>

      {/* TODO should we link to the dashboard somewhere if we are in the org checks route? */}
      {!isOrgChecksRoute && (
        <StyledTabs.TabsContainer>
          <StyledTabs.Tab to='/org/analytics'>
            <Icon path={mdiChartLine} size='20px' />
            <span>Statistiken</span>
          </StyledTabs.Tab>
          <StyledTabs.Tab to='/org/users'>
            <Icon path={mdiAccountMultiple} size='20px' />
            <span>Mitglieder</span>
          </StyledTabs.Tab>
        </StyledTabs.TabsContainer>
      )}

      <Outlet />
    </Layout>
  );
};

export default OrgWrapperPage;
