import { mdiAlert, mdiCheckCircle } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { Toaster as HotToaster } from 'react-hot-toast';
import * as Styled from './Toaster.styles';

const Toaster: React.FC = () => {
  return (
    <>
      <Styled.ToastStyles />
      <HotToaster
        position='bottom-center'
        reverseOrder={false}
        toastOptions={{
          className: 'toast',
          duration: 5_000,
          success: {
            icon: <Icon path={mdiCheckCircle} />,
          },
          error: {
            icon: <Icon path={mdiAlert} />,
          },
        }}
      />
    </>
  );
};

export default Toaster;
