import darken from 'polished/lib/color/darken';
import transparentize from 'polished/lib/color/transparentize';
import styled, { css } from 'styled-components';
import { Color } from '../styles/colors';

export const Container = styled.span<{ $color?: Color; $hasTitle?: boolean }>`
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  padding: 6px 8px;
  border-radius: ${props => props.theme.borderRadius.small};
  background-color: ${transparentize(0.85, Color.Neutral_700)};
  color: ${Color.Neutral_700};
  font-weight: ${props => props.theme.fontWeight.semibold};
  line-height: 1;
  letter-spacing: 0.03em;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;

  ${props =>
    props.$color &&
    css`
      background-color: ${transparentize(0.85, props.$color)};
      color: ${darken(0.15, props.$color)};
    `}

  svg {
    flex-shrink: 0;
    width: 16px;
    margin-right: 4px;
  }

  span {
    display: inline-block;
    text-decoration: none;
    font-style: normal;
  }

  ${props =>
    props.$hasTitle &&
    css`
      cursor: help;
    `};
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem 0.125rem;
`;
