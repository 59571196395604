import React from 'react';
import UserCompanyDetailsForm from '../settings/UserCompanyDetailsForm';
import * as StyledPaymentPopup from './PaymentPopup.styles';
import * as Styled from './PaymentPopupCompanyDetails.styles';

interface PaymentPopupCompanyDetailsProps {
  onComplete: () => void;
  onBack: () => void;
}

const PaymentPopupCompanyDetails: React.FC<PaymentPopupCompanyDetailsProps> = ({ onComplete, onBack }) => {
  return (
    <div>
      <StyledPaymentPopup.Headline>
        Bitte <span>vervollständigen</span> Sie nachfolgende Angaben.
      </StyledPaymentPopup.Headline>

      <UserCompanyDetailsForm
        displayTerms
        buttonText='Weiter'
        onComplete={onComplete}
        additionalButton={
          <Styled.ButtonBack variant='outlined' onClick={() => onBack()}>
            Zurück
          </Styled.ButtonBack>
        }
      />
    </div>
  );
};

export default PaymentPopupCompanyDetails;
