// TODO can we remove this? (TS error being thrown for theme at bottom if removed)
import {} from 'styled-components';

export enum Breakpoint {
  Xs = 375,
  Sm = 576,
  Md = 768,
  Lg = 992,
  Xl = 1200,
}

export const theme = {
  // layout
  siteMaxWidth: '1200px',
  smallSectionMaxWidth: '560px',
  wrapperPadding: '32px',
  wrapperPaddingMobile: '24px',
  focusBoxShadow: '0 0 0 2px',
  formElementsPadding: '14px 20px 2px',
  formElementsPaddingX: '20px',
  borderRadius: {
    small: '4px',
    large: '24px',
  },
  navbarHeight: '100px',
  navbarHeightShrunk: '72px',
  navbarHeightMobile: '80px',
  transitionTiming: '0.25s ease',

  // breakpoint
  breakpoint: {
    xs: {
      up: `min-width: ${Breakpoint.Xs}px`,
      down: `max-width: ${Breakpoint.Xs - 0.02}px`,
    },
    sm: {
      up: `min-width: ${Breakpoint.Sm}px`,
      down: `max-width: ${Breakpoint.Sm - 0.02}px`,
    },
    md: {
      up: `min-width: ${Breakpoint.Md}px`,
      down: `max-width: ${Breakpoint.Md - 0.02}px`,
    },
    lg: {
      up: `min-width: ${Breakpoint.Lg}px`,
      down: `max-width: ${Breakpoint.Lg - 0.02}px`,
    },
    xl: {
      up: `min-width: ${Breakpoint.Xl}px`,
      down: `max-width: ${Breakpoint.Xl - 0.02}px`,
    },
  },

  // typo
  fontSize: {
    body: '16px',
    h1: '48px',
    h1Mobile: '32px',
    h2: '40px',
    h2Mobile: '28px',
    h3: '32px',
    h3Mobile: '20px',
    h4: '18px',
    h4Mobile: '18px',
    h5: '16px',
    h5Mobile: '16px',
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },
  lineHeight: {
    small: 1.15,
    medium: 1.25,
    large: 1.65,
  },

  // gradient
  gradient: {
    base: 'linear-gradient(62deg, #05478a, #061c33)',
  },

  buttonMinheight: '40px',
  buttonMinHeightSmall: '32px',
};

type ThemeType = typeof theme;
export type { ThemeType };

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends ThemeType {}
}

export default theme;
