import { subject } from '@casl/ability';
import { mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import {
  AbsolvedCheck,
  CHECK_TYPE,
  CHECK_TYPES_DISABLED,
  CHECK_TYPES_HAS_REPORT,
  CHECK_TYPE_NAME_HTML,
  CHECK_TYPE_SLUG
} from '@solid/shared';
import { ApiError } from '@solid/shared/services/api.service';
import { OrganizationListCheck, isOrganizationListCheck } from '@solid/shared/services/organization.service';
import { Button, Popup } from '@solid/shared/ui';
import ConfirmationDialog from '@solid/shared/ui/ConfirmationDialog';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useAbsolvedCheckAbility } from '../../hooks/useAbsolvedCheckAbility';
import { deleteCheck } from '../../services/check.service';
import OnlyPaidLinkButton from '../common/OnlyPaidLinkButton';
import * as Styled from './CheckEntry.styles';

interface CheckEntryProps {
  check: AbsolvedCheck | OrganizationListCheck;
  onDelete: () => void;
  context: 'personal' | 'org';
}

const CheckEntry: React.FC<CheckEntryProps> = ({ check, onDelete, context }) => {
  const ability = useAbsolvedCheckAbility();
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const { mutate: deleteCheckMutation, isLoading: deleteLoading } = useMutation<void | boolean, ApiError>(
    () => {
      const promise = deleteCheck(check.id);
      toast.promise(promise, {
        loading: 'Lösche Prüfung...',
        success: `Die Prüfung wurde erfolgreich gelöscht.`,
        error: `Die Prüfung konnte nicht gelöscht werden. Versuchen Sie es später bitte erneut`,
      });
      return promise;
    },
    {
      onSuccess: () => {
        onDelete();
      },
    }
  );

  const checkTypeName = <span dangerouslySetInnerHTML={{ __html: CHECK_TYPE_NAME_HTML[check.type] }}></span>;

  const hasParent = !!JSON.parse(check.payload as any)?.parentId;

  return (
    <Styled.Entry $hasParent={hasParent}>
      {!hasParent && (
        <Styled.LeftContainer>
          <Styled.Date title={check.submittedAt.toLocaleString()}>{check.submittedAt.toLocaleDateString()}</Styled.Date>
          {CHECK_TYPES_DISABLED.includes(check.type) ? (
            <Styled.Type disabled>{checkTypeName}</Styled.Type>
          ) : (
            <Link to={`/tutorials/${CHECK_TYPE_SLUG[check.type]}`}>
              <Styled.Type>{checkTypeName}</Styled.Type>
            </Link>
          )}
        </Styled.LeftContainer>
      )}
      <Styled.NameContainer>
        <Styled.ItemName>{check.itemName ?? '-'}</Styled.ItemName>
        {isOrganizationListCheck(check) && (
          <Styled.UserName>
            geprüft durch <b>{check.userName}</b>
          </Styled.UserName>
        )}
      </Styled.NameContainer>
      <Styled.BtnContainer>
        {CHECK_TYPES_HAS_REPORT[check.type] && (
          <OnlyPaidLinkButton to={{ pathname: `/checks/${check.uid}/report`, search: `?context=${context}` }}>
            Protokoll
          </OnlyPaidLinkButton>
        )}
        {ability.can('delete', subject('AbsolvedCheck', check)) && (
          <Button
            themeColor='danger'
            isSmall
            hasIconOnly
            isLoading={deleteLoading}
            onClick={() => setShowDeletePopup(true)}>
            <Icon path={mdiTrashCan} size='16px' />
          </Button>
        )}
      </Styled.BtnContainer>

      <Popup isOpen={showDeletePopup} onClose={() => setShowDeletePopup(false)}>
        <ConfirmationDialog
          headline='Prüfung löschen'
          onCancel={() => setShowDeletePopup(false)}
          onConfirm={() => {
            setShowDeletePopup(false);
            deleteCheckMutation();
          }}
          confirmButtonProps={{ themeColor: 'danger', children: 'Löschen' }}>
          Die Prüfung kann danach nicht wiederhergestellt werden.
        </ConfirmationDialog>
      </Popup>
    </Styled.Entry>
  );
};
export default CheckEntry;
