import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Yup } from '@solid/shared/services/validation.service';
import { AuthContext } from '../../contexts/auth.context';
import { logoutUser } from '../../services/auth.service';
import { deleteUser } from '../../services/user.service';
import styles from './AccountDeletion.module.scss';
import toast from 'react-hot-toast';

const AccountDeletionSchema = Yup.object().shape({
  password: Yup.string().required('Bitte geben Sie Ihr Passwort an'),
});

interface AccountDeletionFormProps {
  deletionNeedsConfirmation?: boolean;
}

const AccountDeletionForm: React.FC<AccountDeletionFormProps> = ({ deletionNeedsConfirmation = false }) => {
  const navigate = useNavigate();
  const { auth, refetchAuthState } = useContext(AuthContext);

  const {
    mutate: submitDelete,
    error,
    isSuccess,
    isLoading,
  } = useMutation<void, Error, string>(async (password: string) => {
    const promise = deleteUser(auth.user.id, password);
    toast.promise(promise, {
      loading: 'Löschanfrage läuft...',
      success: deletionNeedsConfirmation ? 'Löschanfrage erfolgreich eingereicht' : 'Account erfolgreich gelöscht',
      error: e => `Löschanfrage konnte nicht eingereicht werden: ${e.message}`,
    });
    await promise;

    if (deletionNeedsConfirmation === false) {
      await logoutUser();
      await refetchAuthState();
      navigate('/login');
    }
  });

  return (
    <>
      <p>Geben Sie daher zur Bestätigung bitte Ihr aktuelles Passwort an.</p>
      <Formik
        initialValues={{ password: '' }}
        onSubmit={values => submitDelete(values.password)}
        validationSchema={AccountDeletionSchema}>
        <Form>
          <div className='form-field'>
            <label htmlFor='account-deletion-password'>Passwort</label>
            <div className='control'>
              <Field
                type='password'
                name='password'
                className={styles.dangerInput}
                id='account-deletion-password'
                autoComplete='off'
                required
              />
            </div>
            <ErrorMessage component='p' className='help is-danger' name='password' />
          </div>

          {error && (
            <div className='form-field'>
              <div className='notification is-danger'>{(error as Error).message}</div>
            </div>
          )}

          <div className='form-field'>
            <button
              className={classNames('btn is-danger', { 'is-loading': isLoading })}
              type='submit'
              disabled={isSuccess || isLoading}>
              {deletionNeedsConfirmation ? 'Löschanfrage absenden' : 'Account endgültig löschen'}
            </button>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default AccountDeletionForm;
