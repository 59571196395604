import { useContext, useEffect, useState } from 'react';
import { PaymentPopupContext } from '../../contexts/payment-popup.context';
import * as Styled from './PaymentPopup.styles';
import PaymentPopupCheckout from './PaymentPopupCheckout';
import PaymentPopupCompanyDetails from './PaymentPopupCompanyDetails';
import PaymentPopupPlanDetails from './PaymentPopupPlanDetails';

enum PAYMENT_STEP {
  PLAN_DETAILS = 'PLAN_DETAILS',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  CHECKOUT = 'CHECKOUT',
}

const PaymentPopup: React.FC = () => {
  const { open, setOpen } = useContext(PaymentPopupContext);
  const [step, setStep] = useState<PAYMENT_STEP>(PAYMENT_STEP.PLAN_DETAILS);

  useEffect(() => {
    if (open) {
      setStep(PAYMENT_STEP.PLAN_DETAILS);
    }
  }, [open]);

  return (
    <Styled.PopupWrapper isOpen={open} onClose={() => setOpen(false)}>
      {step === PAYMENT_STEP.PLAN_DETAILS && (
        <PaymentPopupPlanDetails onComplete={() => setStep(PAYMENT_STEP.COMPANY_DETAILS)} />
      )}
      {step === PAYMENT_STEP.COMPANY_DETAILS && (
        <PaymentPopupCompanyDetails
          onComplete={() => setStep(PAYMENT_STEP.CHECKOUT)}
          onBack={() => setStep(PAYMENT_STEP.PLAN_DETAILS)}
        />
      )}
      {step === PAYMENT_STEP.CHECKOUT && (
        <PaymentPopupCheckout onComplete={() => setOpen(false)} onBack={() => setStep(PAYMENT_STEP.COMPANY_DETAILS)} />
      )}
    </Styled.PopupWrapper>
  );
};

export default PaymentPopup;
