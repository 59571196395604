import { getInviteByToken } from '@solid/shared/services/organization.service';
import { Button, LoadingSpinner } from '@solid/shared/ui';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { Link, useLocation, useParams, Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth.context';
import singlePageStyles from '../common/SinglePage.module.scss';
import Layout from '../global/Layout';
import OrgAcceptInviteForm from '../org/OrgAcceptInviteForm';
import * as Styled from './OrgInvitePage.styles';
import RegisterForm from './RegisterForm';
import toast from 'react-hot-toast';

const OrgInvitePage: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const { auth } = useContext(AuthContext);

  const {
    data,
    error,
    isLoading: inviteIsLoading,
  } = useQuery(['invite-by-token', token], () => getInviteByToken(token), {
    enabled: !!token,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const isLoading = inviteIsLoading || auth === undefined;

  if (auth === null && data?.userExists === true) {
    // An account exists, but the user is not logged in. We redirect them to the login page, so they can log in and will return to this page
    toast.error('Bitte loggen Sie sich ein, um diese Einladung zu akzeptieren.', { duration: 10_000 });
    return (
      <Navigate
        to={{
          pathname: '/login',
          search: `redirect=${encodeURIComponent(window.location.pathname)}`,
        }}
      />
    );
  }

  return (
    <Layout>
      <div className={classNames('wrapper', singlePageStyles.wrapper)}>
        {isLoading && <LoadingSpinner />}
        {error && (
          <>
            <h1>Etwas ist schiefgelaufen</h1>
            <p>Wir konnten diese Einladung leider nicht verarbeiten.</p>
            <div className='notification is-error'>{(error as Error).message}</div>
            <Styled.BtnContainer>
              <Link to='mailto:info@solid-app.de'>
                <Button variant='outlined'>Support</Button>
              </Link>
            </Styled.BtnContainer>
          </>
        )}

        {!isLoading && data?.invite && (
          <>
            <h1>{data.invite.organization.displayName}</h1>
            <p>
              Sie wurden eingeladen, "{data.invite.organization.displayName}" beizutreten.
              {auth === null ? ' Bitte erstellen Sie einen Account für diese Organisation.' : ''}
            </p>

            {auth === null ? (
              <RegisterForm invite={{ email: data.invite.email, token }} />
            ) : (
              <OrgAcceptInviteForm organization={data.invite.organization} token={token} />
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default OrgInvitePage;
