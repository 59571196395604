import React from 'react';
import { Link } from 'react-router-dom';
import ResetPasswordForm from './ResetPasswordForm';
import singlePageStyles from '../common/SinglePage.module.scss';
import Layout from '../global/Layout';

const ResetPasswordPage: React.FC = () => {
  return (
    <Layout>
      <div className={singlePageStyles.wrapper}>
        <h1>Passwort zurücksetzen</h1>

        <ResetPasswordForm />

        <div className={singlePageStyles.otherActions}>
          <Link to='/login'>Zum Login</Link>
          <Link to='/register'>Registrieren</Link>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPasswordPage;
