import { createGlobalStyle } from 'styled-components';

export const ToastStyles = createGlobalStyle`
  .toast {
    hyphens: auto;
    svg {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }
`;
