import React from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartDataEntry } from '../../services/analytics.service';
import EmptyState from './EmptyState';

export interface BarChartProps {
  data: ChartDataEntry[];
  displayName: string;
  color: string;
}

const BarChartComponent: React.FC<BarChartProps> = ({ data, displayName, color }) => {
  return (
    <ResponsiveContainer height={300}>
      {data.length === 0 ? (
        <EmptyState />
      ) : (
        <BarChart data={data}>
          <XAxis dataKey='name' />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend />
          <Bar dataKey='value' fill={color} name={displayName} />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
