import { AbilityBuilder, InferSubjects, PureAbility, createMongoAbility } from '@casl/ability';
import { AbsolvedCheck, USER_ROLE, User } from '../..';
import { ORGANIZATION_ROLE, OrganizationMember } from '../organization';

export type AbsolvedCheckActions = 'manage' | 'read' | 'delete';
export type AbsolvedCheckSubjects = InferSubjects<AbsolvedCheck | 'AbsolvedCheck'>;

export function defineAbsolvedCheckAbilitiesFor({
  user,
  org,
}: {
  user: User;
  org?: {
    reqOrgMember: OrganizationMember;
    checkUserOrgMember: OrganizationMember | null;
  };
}) {
  const { can, build } = new AbilityBuilder<PureAbility<[AbsolvedCheckActions, AbsolvedCheckSubjects]>>(
    createMongoAbility
  );

  if (user.role === USER_ROLE.ADMIN) {
    // Admins can do anything
    can('manage', 'AbsolvedCheck');
  } else {
    if (org) {
      /**
       * We are in an organization context
       */
      if (org.reqOrgMember.organizationId !== org.checkUserOrgMember?.organizationId) {
        // The user is not a member of the organization that the check belongs to
        return build();
      }
      if (org.reqOrgMember?.role === ORGANIZATION_ROLE.MEMBER) {
        // Members can only read their own checks
        can('read', 'AbsolvedCheck', { userId: user.id });
      }
      if (org.reqOrgMember?.role === ORGANIZATION_ROLE.MANAGER) {
        can('read', 'AbsolvedCheck');
      }
      if (org.reqOrgMember?.role === ORGANIZATION_ROLE.LOCAL_ADMIN) {
        can(['read', 'delete'], 'AbsolvedCheck');
      }
    } else {
      /**
       * We are in a standalone context
       */
      can('manage', 'AbsolvedCheck', { userId: user.id });
    }
  }

  return build();
}
