import { Color } from '../../styles/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  ${props => props.theme.breakpoint.sm.down} {
    flex-wrap: wrap;
  }

  > .form-field {
    flex: 0 0 240px;
    margin: 0;
  }

  select {
    color: ${Color.Blue_800} !important;
  }
`;
