import { CHECK_TYPE, GENERIC_RESULT_STATE } from '@solid/shared';
import classNames from 'classnames';
import React from 'react';
import { Helmet } from 'react-helmet';
import sanitizeHTML from 'sanitize-html';
import { ReactComponent as ResultIconInfo } from '../../assets/images/icons/checkup-info-filled.svg';
import { ReactComponent as ResultIconProhibition } from '../../assets/images/icons/checkup-prohibition-filled.svg';
import { ReactComponent as ResultIconSuccess } from '../../assets/images/icons/checkup-success-filled.svg';
import { ReactComponent as ResultIconFailed } from '../../assets/images/icons/checkup-warning-filled.svg';
import { ReactComponent as IconQuestion } from '../../assets/images/icons/question.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { CheckResultResponse, isCheckResultGeneric, parseMarkdown } from '../../services/check.service';
import styles from './CheckReport.module.scss';

const resultIconMap: Record<GENERIC_RESULT_STATE, React.FC<unknown> | null> = {
  [GENERIC_RESULT_STATE.SUCCESS]: ResultIconSuccess,
  [GENERIC_RESULT_STATE.FAILED]: ResultIconFailed,
  [GENERIC_RESULT_STATE.INFO]: ResultIconInfo,
  [GENERIC_RESULT_STATE.PROHIBITION]: ResultIconProhibition,
  [GENERIC_RESULT_STATE.NEUTRAL]: null,
};

interface CheckReportProps {
  check: CheckResultResponse;
}

const CheckReport: React.FC<CheckReportProps> = ({ check }) => {
  let resultText: string;

  if (isCheckResultGeneric(check.result)) {
    resultText = check.result.text;
  } else {
    if (check.note) {
      resultText = parseMarkdown(check.note);
    } else {
      resultText = '<i>Bitte nutzen Sie die Kommentarfunktion, um das Ergebnis dieser Prüfung zu definieren.</i>';
    }
  }

  let ResultIcon: React.FC<any>;
  if (isCheckResultGeneric(check.result)) {
    const icon = resultIconMap[check.result.result];
    if (icon) {
      ResultIcon = icon;
    }
  }

  return (
    <div className={styles.container}>
      <Helmet>
        <title>
          SOLID - Ergebnisprotokoll {check.itemName ? ` ${check.itemName}` : ''} vom{' '}
          {check.submittedAt.toLocaleDateString()}
        </title>
      </Helmet>
      <div className={styles.header}>
        <Logo className={styles.headerLogo} />
        <p className={styles.headerHeadline}>Ergebnisprotokoll</p>
      </div>
      <div className={styles.result}>
        <div className={styles.resultLeft}>
          <div className={styles.productName}>{check.itemName}</div>
          <div className={styles.date}>{check.submittedAt.toLocaleDateString()}</div>
        </div>
        <div className={styles.resultRight}>
          <h2 className={styles.resultHeadline}>Ergebnis Ihrer Prüfung</h2>
          <div className={styles.resultText} dangerouslySetInnerHTML={{ __html: sanitizeHTML(resultText) }} />
          {
            /**
             * Special case for "DUAL_USE".
             * Here the note is actually the result text, as there is no generic result text.
             * If the user has not provided a note, a warning will be displayed.
             */
            check.note && [CHECK_TYPE.DUAL_USE].includes(check.type) === false && (
              <>
                <div className={classNames(styles.resultHeadline, styles.noteHeadline)}>Kommentar</div>
                <div
                  className={classNames(styles.resultText, styles.noteText)}
                  dangerouslySetInnerHTML={{ __html: sanitizeHTML(parseMarkdown(check.note)) }}
                />
              </>
            )
          }
        </div>
        <div className={styles.icon}>{ResultIcon && <ResultIcon className={styles.resultIcon} />}</div>
      </div>
      <table className={styles.questionsTable}>
        <thead>
          <tr>
            <th>Die Fragen im Detail</th>
            <th>Ihre Antwort</th>
          </tr>
        </thead>
        <tbody>
          {check.questions.map(({ question, value }) => (
            <tr key={question}>
              <td dangerouslySetInnerHTML={{ __html: sanitizeHTML(parseMarkdown(question)) }}></td>
              <td
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(value === true ? 'Ja' : value === false ? 'Nein' : value),
                }}></td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.footer}>
        <IconQuestion className={styles.footerIcon} />
        <div className={styles.footerContainer}>
          <div className={styles.footerHeadline}>
            Weitere Fragen?
            <br />
            Wir helfen Ihnen gerne weiter!
          </div>
          <div className={styles.footerContactContainer}>
            <div>
              E-Mail: <a href='mailto:info@solid-app.de'>info@solid-app.de</a>
            </div>
            <div>
              AEB SE
              <br />
              Sigmaringer Strasse 109
              <br />
              D-70567 Stuttgart
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckReport;
