import { Button, LoadingSpinner } from '@solid/shared/ui';
import React from 'react';
import { useKirbyInfoPlus } from '../../hooks/useKirbyData';
import * as StyledPaymentPopup from './PaymentPopup.styles';
import * as Styled from './PaymentPopupPlanDetails.styles';

interface PaymentPopupPlanDetailsProps {
  onComplete: () => void;
}

const PaymentPopupPlanDetails: React.FC<PaymentPopupPlanDetailsProps> = ({ onComplete }) => {
  const { data: infoPlus } = useKirbyInfoPlus();

  return (
    <div>
      <StyledPaymentPopup.Headline>
        Jetzt das ganze <span>PLUS</span>-Paket mit allen Funktionen nutzen.
      </StyledPaymentPopup.Headline>

      {infoPlus ? (
        <>
          <Styled.BenefitsContainer>
            {infoPlus.details.map(detailList => (
              <Styled.BenefitList key={detailList.name}>
                <Styled.BenefitTitle dangerouslySetInnerHTML={{ __html: detailList.name }}></Styled.BenefitTitle>
                <ul>
                  {detailList.entries.map(entry => (
                    <li key={entry} dangerouslySetInnerHTML={{ __html: entry }}></li>
                  ))}
                </ul>
              </Styled.BenefitList>
            ))}
          </Styled.BenefitsContainer>

          <Styled.PriceContainer dangerouslySetInnerHTML={{ __html: infoPlus.price }} />
        </>
      ) : (
        <Styled.LoadingContainer>
          <LoadingSpinner themeColor='primary' />
        </Styled.LoadingContainer>
      )}

      <StyledPaymentPopup.BtnContainer $justifyContent='right'>
        <Button onClick={() => onComplete()} disabled={!infoPlus}>
          Weiter
        </Button>
      </StyledPaymentPopup.BtnContainer>
    </div>
  );
};

export default PaymentPopupPlanDetails;
