import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useContext } from 'react';
import { Yup } from '@solid/shared/services/validation.service';
import { AuthContext } from '../../contexts/auth.context';
import * as userService from '../../services/user.service';

const NameForm: React.FC = () => {
  const { auth } = useContext(AuthContext);

  const NameSchema = Yup.object().shape({
    firstName: Yup.string().required('Bitte geben Sie einen Vornamen an'),
    lastName: Yup.string().required('Bitte geben Sie einen Nachnamen an'),
  });

  const {
    mutate: updateUser,
    error,
    isLoading,
    isSuccess,
  } = useMutation<void, Error, { firstName: string; lastName: string }>(
    ({ firstName, lastName }: { firstName: string; lastName: string }) =>
      userService.updateUser(auth.user.id, { firstName, lastName })
  );

  return (
    <Formik
      initialValues={{ firstName: auth.user.firstName, lastName: auth.user.lastName }}
      onSubmit={values => updateUser(values)}
      validationSchema={NameSchema}>
      {({ isValid }) => (
        <Form id='account-data-name-form'>
          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='account-data-firstName'>Vorname</label>
              <div className='control'>
                <Field
                  type='text'
                  name='firstName'
                  id='account-data-firstName'
                  autoComplete='given-name'
                  required
                  placeholder='Max'
                />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='firstName' />
            </div>
            <div className='form-field'>
              <label htmlFor='account-data-lastName'>Nachname</label>
              <div className='control'>
                <Field
                  type='text'
                  name='lastName'
                  id='account-data-lastName'
                  autoComplete='family-name'
                  required
                  placeholder='Mustermann'
                />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='lastName' />
            </div>
          </div>

          <div className='form-field'>
            {error && <div className='notification is-error'>{error.message}</div>}
            {isSuccess && <div className='notification is-success'>Erfolgreich gespeichert</div>}
          </div>

          <div className='form-field'>
            <button
              className={classNames('btn is-primary', { 'is-loading': isLoading })}
              type='submit'
              disabled={isLoading || !isValid}>
              Name speichern
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NameForm;
