import classNames from 'classnames';
import countries from 'i18n-iso-countries';
import React, { useState } from 'react';
import styles from './CheckIndividualTestMatches.module.scss';
import { ReactComponent as IconDropdown } from '../../../assets/images/icons/icon-chevron-right-black.svg';
import { IndividualTestMatch } from '@solid/shared';
import format from 'date-fns/format';

interface CheckIndividualTestMatchesProps {
  matches: IndividualTestMatch[];
}

const CheckIndividualTestMatches: React.FC<CheckIndividualTestMatchesProps> = ({ matches }) => {
  const [openEntries, setOpenEntries] = useState<string[]>([]);

  return (
    <>
      <div className={styles.matchHeader}>
        <div className={classNames(styles.col, styles.dropdown)}></div>
        <span className={classNames(styles.col, styles.similarity)}>% Ähnlichkeit /<br/>Similarity %</span>
        <span className={classNames(styles.col, styles.name)}>Namensblock /<br/>Name block</span>
        <span className={classNames(styles.col, styles.city)}>Ort /<br/>City</span>
        <span className={classNames(styles.col, styles.country)}>Land /<br/>Country</span>
        <span className={classNames(styles.col, styles.listAbbreviation)}>Verbotsliste /<br/>Restriction list</span>
      </div>
      {matches.map(match => {
        const key = `${match.similarity}_${match.name}_${match.city}_${match.listAbbreviation}_${match.updateId}`;
        return (
          <div
            key={key}
            className={classNames(styles.matchEntry, {
              [styles.matchDetailsOpened]: openEntries.includes(key),
            })}>
            <div className={styles.matchHeader}>
              <div className={classNames(styles.col, styles.dropdown)}>
                <button
                  className={classNames(styles.dropdownBtn, {
                    [styles.dropdownBtnOpened]: openEntries.includes(key),
                  })}
                  onClick={() => {
                    openEntries.includes(key)
                      ? setOpenEntries(openEntries.filter(entry => entry !== key))
                      : setOpenEntries([...openEntries, key]);
                  }}>
                  <IconDropdown className={styles.dropdownIcon} />
                </button>
              </div>
              <div className={classNames(styles.col, styles.similarity)}>
                <span>{match.similarity}</span>
                <div className={classNames(styles.similarityIndicatorContainer)}>
                  <div
                    className={classNames(styles.similarityIndicator, {
                      [styles.similarityIndicator1]: parseInt(match.similarity) > 20,
                      [styles.similarityIndicator2]: parseInt(match.similarity) > 40,
                      [styles.similarityIndicator3]: parseInt(match.similarity) > 60,
                      [styles.similarityIndicator4]: parseInt(match.similarity) > 80,
                      [styles.similarityIndicator5]: parseInt(match.similarity) === 100,
                    })}></div>
                </div>
              </div>
              <span className={classNames(styles.col, styles.name)}>{match.name}</span>
              <span className={classNames(styles.col, styles.city)}>{match.city}</span>
              <span className={classNames(styles.col, styles.country)}>{match.countryISO}</span>
              <span className={classNames(styles.col, styles.listAbbreviation)}>{match.listAbbreviation}</span>
            </div>

            <div
              className={classNames(styles.matchDetails, {
                [styles.matchDetailsOpened]: openEntries.includes(key),
              })}>
              <div className={styles.matchDetailsSection}>
                <h3>Adressquelle / Address Source</h3>
                <div className='form-row'>
                  <div className='form-field'>
                    <label>Kürzel / Abbreviation</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.listAbbreviation} />
                    </div>
                  </div>
                  <div className='form-field'>
                    <label>Name</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.listName} />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.matchDetailsSection}>
                <h3>Name/Info</h3>
                <div className='form-row'>
                  <div className='form-field'>
                    <label>Adresstyp / Type of address</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.addressType} />
                    </div>
                  </div>
                  <div className='form-field'>
                    <label>Namensblock / Name block</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.name} />
                    </div>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-field'>
                    <label>Kommentar / Comment</label>
                    <div className='control'>
                      <textarea readOnly rows={6} value={match.info} />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.matchDetailsSection}>
                <h3>Adressdaten / Address data</h3>
                <div className='form-row'>
                  <div className='form-field'>
                    <label>Straße / Street</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.street} />
                    </div>
                  </div>
                  <div className='form-field'>
                    <label>Ort / City</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.city} />
                    </div>
                  </div>
                  <div className='form-field'>
                    <label>Land / Country</label>
                    <div className='control'>
                      <input
                        readOnly
                        type='text'
                        value={
                          match.countryISO ? match.countryISO + ', ' + countries.getName(match.countryISO, 'de') : ''
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.matchDetailsSection}>
                <h3>Personenbezogene Daten / Personal data</h3>
                <div className='form-row'>
                  <div className='form-field'>
                    <label>Geburtsdatum / Birthday</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.dateOfBirth} />
                    </div>
                  </div>
                  <div className='form-field'>
                    <label>Geburtsstadt / Place of birth</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.cityOfBirth} />
                    </div>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-field'>
                    <label>Ausweisdaten / ID data</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.passportData} />
                    </div>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-field'>
                    <label>Geburtsland / Country of birth</label>
                    <div className='control'>
                      <input
                        readOnly
                        type='text'
                        value={
                          match.countryISO
                            ? match.countryISO + ', ' + countries.getName(match.countryOfBirthISO, 'de')
                            : ''
                        }
                      />
                    </div>
                  </div>
                  <div className='form-field'>
                    <label>Nationalität / Nationality</label>
                    <div className='control'>
                      <input
                        readOnly
                        type='text'
                        value={
                          match.countryISO
                            ? match.countryISO + ', ' + countries.getName(match.nationalityISO, 'de')
                            : ''
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.matchDetailsSection}>
                <h3>Verbotsinformationen</h3>
                <div className='form-row'>
                  <div className='form-field'>
                    <label>Ursprl. Listenname</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.restrictionType} />
                    </div>
                  </div>
                  <div className='form-field'>
                    <label>Verbotsquelle</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.restrictionSource} />
                    </div>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-field'>
                    <label>Veröffentl.-Datum</label>
                    <div className='control'>
                      <input
                        readOnly
                        type='text'
                        value={match.restrictionDate ? format(new Date(match.restrictionDate), 'dd.MM.yyyy') : ''}
                      />
                    </div>
                  </div>
                  <div className='form-field'>
                    <label>Aliasgruppe</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.aliasGroupNo} />
                    </div>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-field'>
                    <label>Änderungsdatum</label>
                    <div className='control'>
                      <input
                        readOnly
                        type='text'
                        value={match.updateDate ? format(new Date(match.updateDate), 'dd.MM.yyyy') : ''}
                      />
                    </div>
                  </div>
                  <div className='form-field'>
                    <label>Adressnr.</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.updateId} />
                    </div>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-field'>
                    <label>Quellenangabe</label>
                    <div className='control'>
                      <input readOnly type='text' value={match.sourceWebLink} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CheckIndividualTestMatches;
