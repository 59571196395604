import React from 'react';
import * as Styled from './ConfirmationDialog.styles';
import Button, { ButtonProps } from './Button';

interface ConfirmationDialogProps {
  headline: string;
  children: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  confirmButtonProps?: Partial<ButtonProps>;
  cancelButtonProps?: Partial<ButtonProps>;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  headline,
  children,
  onConfirm,
  onCancel,
  confirmButtonProps = { children: 'Bestätigen' },
  cancelButtonProps = { children: 'Abbrechen' },
}) => {
  return (
    <div>
      <h3>{headline}</h3>
      {children}

      <Styled.ButtonContainer>
        <Button {...cancelButtonProps} themeColor='grey' onClick={() => onCancel()}>
          {cancelButtonProps.children}
        </Button>
        <Button {...confirmButtonProps} onClick={() => onConfirm()}>
          {confirmButtonProps.children}
        </Button>
      </Styled.ButtonContainer>
    </div>
  );
};

export default ConfirmationDialog;
