import transparentize from 'polished/lib/color/transparentize';
import styled from 'styled-components';
import { Color } from '../styles/colors';
import Button from './Button';

export const Modal = styled.div`
  position: fixed;
  z-index: 2147483632;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @media (${props => props.theme.breakpoint.sm.up}) {
    align-items: center;
  }
`;

export const Fade = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2147483632;
  width: 100%;
  height: 300%;
  background-color: ${transparentize(0.3, Color.Blue_1000_Deep)};
  opacity: ${props => (props.$isVisible ? 1 : 0)};
  transition: opacity 0.5s ease;
  @media (${props => props.theme.breakpoint.sm.up}) {
    height: 100%;
  }
`;

export const Container = styled.div<{ $isVisible: boolean }>`
  z-index: 2147483633;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: auto;
  max-height: 86vh;
  background-color: ${Color.Neutral_0_White};
  color: ${Color.Blue_1000_Deep};
  border-radius: ${props => props.theme.borderRadius.large} ${props => props.theme.borderRadius.large} 0 0;
  opacity: ${props => (props.$isVisible ? 1 : 0)};
  transition: opacity ${props => props.theme.transitionTiming}, transform ${props => props.theme.transitionTiming},
    max-width ${props => props.theme.transitionTiming};
  transform: translateY(${props => (props.$isVisible ? 0 : '16px')});
  overflow-y: auto;

  @media (${props => props.theme.breakpoint.sm.up}) {
    flex-direction: column;
    border-radius: ${props => props.theme.borderRadius.small};
  }
`;

export const Content = styled.div`
  padding: ${props => props.theme.wrapperPaddingMobile};
  @media (${props => props.theme.breakpoint.md.up}) {
    padding: ${props => props.theme.wrapperPadding};
  }
`;

export const ButtonClose = styled(Button)`
  position: absolute;
  top: 6px;
  right: 6px;
  min-width: 0;
  width: 40px;
  height: 40px;
  color: ${Color.Neutral_700};

  &:hover {
    background-color: ${Color.Neutral_200_OffWhite} !important;
  }
`;
