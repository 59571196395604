import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { toast } from 'react-hot-toast';
import { Yup } from '../../services/validation.service';
import { ORGANIZATION_ROLE, ORGANIZATION_ROLE_NAME } from '../../organization';
import { OrganizationListMembersEntry, updateOrganizationMember } from '../../services/organization.service';
import { Button, StyledButton } from '../../ui';
import { UpdateOrganizationMember } from '../../validation/organization';
import OrganizationRoleInfo from './OrganizationRoleInfo';
import * as Styled from './EditMemberForm.styles';

interface EditMemberFormProps {
  orgId: number;
  member: OrganizationListMembersEntry;
  onClose: (updated: boolean) => void;
}

const EditMemberForm: React.FC<EditMemberFormProps> = ({ orgId, member, onClose }) => {
  const submitForm = async (values: Yup.InferType<typeof UpdateOrganizationMember>) => {
    const promise = updateOrganizationMember(orgId, member.id, values);
    toast.promise(promise, {
      loading: 'Mitglied wird bearbeitet...',
      success: 'Mitglied erfolgreich bearbeitet.',
      error: e => `Mitglied konnte nicht bearbeitet werden: ${e.message}`,
    });
    return promise.then(() => onClose(true));
  };

  return (
    <Formik
      initialValues={
        {
          role: member?.role ?? '',
        } as Yup.InferType<typeof UpdateOrganizationMember>
      }
      onSubmit={submitForm}
      validationSchema={UpdateOrganizationMember}>
      {({ values, isSubmitting }) => (
        <Form>
          <Styled.Container>
            <div className='form-field'>
              <label htmlFor='create-org-invite-role'>Rolle</label>
              <div className='control'>
                <Field as='select' name='role' id='create-org-invite-role' multiple={false}>
                  <option value='' disabled>
                    Bitte auswählen...
                  </option>
                  {Object.values(ORGANIZATION_ROLE).map(role => (
                    <option key={role} value={role}>
                      {ORGANIZATION_ROLE_NAME[role]}
                    </option>
                  ))}
                </Field>
              </div>
              <ErrorMessage component='p' className='help is-error' name='role' />
            </div>
            <OrganizationRoleInfo role={values.role} />
          </Styled.Container>

          <StyledButton.ButtonsContainer>
            <Button themeColor='grey' type='button' onClick={() => onClose(false)}>
              Abbrechen
            </Button>
            <Button themeColor='primary' type='submit' disabled={isSubmitting}>
              Mitglied speichern
            </Button>
          </StyledButton.ButtonsContainer>
        </Form>
      )}
    </Formik>
  );
};

export default EditMemberForm;
