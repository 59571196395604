import { format, isValid, isWithinInterval } from 'date-fns';
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import { Column, SortColumn } from 'react-data-grid';
import toast from 'react-hot-toast';

export enum ColumnFilterType {
  Text = 'Text',
  List = 'List',
  Date = 'Date',
  Number = 'Number',
  Boolean = 'Boolean',
}

export interface FilterListOption {
  value: string;
  label: string;
}

type CustomFilterFunction<T> = (row: T, filter: any) => boolean;

/**
 * This interface holds additional properties that are specific to our data tables.
 */
export interface DataGridColumn<TRow> {
  filter?: {
    customFilterFunction?: CustomFilterFunction<TRow>;
  } & (
    | {
        type: ColumnFilterType.Text;
      }
    | {
        type: ColumnFilterType.List;
        options: FilterListOption[];
        customFilterFunction?: CustomFilterFunction<TRow>;
      }
    | {
        type: ColumnFilterType.Date;
      }
    | {
        type: ColumnFilterType.Number;
      }
    | {
        type: ColumnFilterType.Boolean;
      }
  );
  sortFunction?: (a: TRow, b: TRow) => number;
  renderExportValue?: ((value: TRow) => string) | false;
}

export function filterRows<TRow>(
  rows: TRow[],
  columns: readonly (Column<TRow> & DataGridColumn<TRow>)[],
  filters: any
): TRow[] {
  return [...rows].filter(row => {
    return Object.keys(filters).every(columnKey => {
      const column = columns.find(column => column.key === columnKey);
      const filter = filters[columnKey];
      if (filter === null || filter === undefined) {
        // If the filter is null, it always means that it's not active
        return true;
      }

      if (typeof column.filter.customFilterFunction === 'function') {
        return column.filter.customFilterFunction(row, filter);
      }

      if (!column) {
        // Some column keys are virtual and do not exist on the data
        return true;
      }
      switch (column.filter?.type) {
        case ColumnFilterType.Text:
          return ((row[columnKey] as string) ?? '').toLowerCase().includes((filter as string).toLowerCase());
        case ColumnFilterType.Number:
          return ((row[columnKey] as number) ?? 0) === (filter as number);
        case ColumnFilterType.List:
          return ((filter as string[]) ?? []).includes(row[columnKey] as string);
        case ColumnFilterType.Boolean:
          return (row[columnKey] as boolean) === (filter as boolean);
        case ColumnFilterType.Date: {
          const rowDate = row[columnKey] as Date;
          const filterDates = filter as { startDate: Date; endDate: Date };
          return isWithinInterval(rowDate, { start: filterDates.startDate, end: filterDates.endDate });
        }
        default:
          return true;
      }
    });
  });
}

export const defaultSort = (a: any, b: any): number => {
  if (typeof a === 'string') {
    // String
    return a.localeCompare(typeof b === 'string' ? b : '', 'de', { sensitivity: 'base' });
  } else if (typeof a === 'number' && typeof b === 'number') {
    // Number
    return a - b;
  } else if (isValid(a) && isValid(b)) {
    // Date
    return (a as Date).getTime() - (b as Date).getTime();
  }
  return 0;
};

export function sortRows<TRow>(
  rows: TRow[],
  columns: readonly (Column<TRow> & DataGridColumn<TRow>)[],
  sortColumns: readonly SortColumn[]
): TRow[] {
  if (sortColumns.length === 0) {
    return rows;
  }
  return [...rows].sort((a, b) => {
    for (const sort of sortColumns) {
      let comparator = 0;
      const customSort = columns.find(column => column.key === sort.columnKey)?.sortFunction;
      if (customSort) {
        comparator = customSort(a, b);
      } else {
        comparator = defaultSort(a[sort.columnKey], b[sort.columnKey]);
      }
      if (comparator !== 0) {
        return sort.direction === 'DESC' ? comparator * -1 : comparator;
      }
    }
    return 0;
  });
}

const EMPTY_VALUE = Symbol('EMPTY_VALUE');

export function exportAsCSV(
  name: string,
  columns: readonly (Column<unknown> & DataGridColumn<unknown>)[],
  rows: readonly unknown[]
) {
  const headersArray = columns.filter(col => col.renderExportValue !== false).map(header => header.name as string);
  const rowsArray = rows.map(row =>
    columns
      .map(col => {
        if (col.renderExportValue === false) {
          return EMPTY_VALUE;
        }
        const value = typeof col.renderExportValue === 'function' ? col.renderExportValue(row) : row[col.key];
        return value;
      })
      .filter(value => value !== EMPTY_VALUE)
  );
  saveAsCSV(headersArray, rowsArray, name);
  toast.success(`Tabelle "${name}" erfolgreich exportiert.`);
}

export function saveAsCSV(headersArray: string[], rowsArray: string[][], name: string) {
  const fileContent = unparse([headersArray, ...rowsArray], { delimiter: ';' });
  // Hack to get Excel to recognize the content as UTF-8: https://stackoverflow.com/a/16231345/13180424
  const blob = new Blob([`\uFEFF${fileContent}`], { type: 'text/csv;charset=UTF-8' });
  saveAs(blob, `solid-${name}-${new Date().toISOString()}.csv`);
}

export function formatDateForCSVExport(date: Date): string {
  return format(date, 'dd.MM.yyyy HH:mm:ss');
}
