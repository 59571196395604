import { Environment } from './environment';

export const environment: Environment = {
  mode: 'production',
  apiBaseUrl: 'https://api.solid-app.de',
  kirbyBaseUrl: 'https://solid-app.de',
  linkedInInsightsPartnerId: '1934121',
  piwikPro: {
    containerId: '24a2a987-c2f4-4906-914d-7a7d598ca9a1',
    containerUrl: 'https://aeb.containers.piwik.pro',
  },
  cookieDomain: '.solid-app.de',
  seminarIframeUrl: {
    regular: 'https://app1.edoobox.com/de/AEBSE/Kostenpflichtige%20Seminare/?edtag=SOLID&edref=aebse',
    free: 'https://app1.edoobox.com/de/AEBSE/Schnupperkurse/?edtag=SOLID&edref=aebse',
  },
  sentry: {
    dsn: 'https://a3e87d5de8a18a23362ac9ecf372f198@o430313.ingest.sentry.io/4506506975379456',
  },
  paddle: {
    vendorId: 123527,
  },
  discourse: {
    baseUrl: 'https://forum.solid-app.de',
  },
};
