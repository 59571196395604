import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { confirmEmail } from '../../services/auth.service';
import singlePageStyles from '../common/SinglePage.module.scss';
import Layout from '../global/Layout';
import LoadingSpinner from '@solid/shared/ui/LoadingSpinner';
import * as Styled from './EmailConfirmationPage.styles';
import { Button } from '@solid/shared/ui';

const EmailConfirmationPage: React.FC = () => {
  const { token } = useParams<{ token: string }>();

  const { error, isLoading, isSuccess } = useQuery(['email-confirmation', token], () => confirmEmail(token), {
    enabled: !!token,
    retry: false,
    refetchOnWindowFocus: false,
  });

  return (
    <Layout>
      <div className={classNames('wrapper', singlePageStyles.wrapper)}>
        {isLoading && <LoadingSpinner />}
        {error && (
          <>
            <h1>Etwas ist schiefgelaufen</h1>
            <p>Wir konnten Ihren Account leider nicht bestätigen.</p>
            <div className='notification is-error'>{(error as Error).message}</div>
            <Styled.BtnContainer>
              <Link to='mailto:info@solid-app.de'>
                <Button variant='outlined'>Support</Button>
              </Link>
            </Styled.BtnContainer>
          </>
        )}
        {isSuccess && (
          <>
            <h1>E-Mail-Adresse bestätigt</h1>
            <p>
              Sie haben Ihren Account erfolgreich bestätigt.
              <br />
              Starten Sie gleich mit der ersten Prüfung.
            </p>
            <Styled.BtnContainer>
              <Link to='/login'>
                <Button>Einloggen</Button>
              </Link>
            </Styled.BtnContainer>
          </>
        )}
        <p></p>
        <div className={singlePageStyles.otherActions}>
          <Link to='/login'>Zum Login</Link>
          <Link to='/register'>Registrieren</Link>
        </div>
      </div>
    </Layout>
  );
};

export default EmailConfirmationPage;
