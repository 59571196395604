import { Color } from '@solid/shared/styles/colors';
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 32rem;
  min-height: 18rem;
  max-width: 100%;
`;

export const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${props => props.theme.breakpoint.md.up}) {
    flex-direction: row;
    justify-content: center;
    margin: 2rem -2rem 0;
  }
`;

export const BenefitList = styled.div`
  margin: 2rem 0;
  max-width: 12rem;

  @media (${props => props.theme.breakpoint.md.up}) {
    margin: 0 2rem;
  }

  ul {
    margin: 0.5rem 0 0;
    padding-left: 1rem;
  }
`;

export const BenefitTitle = styled.h4`
  color: ${Color.Blue_500_Primary};
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: ${props => props.theme.fontWeight.semibold};
`;

export const PriceContainer = styled.div`
  text-align: right;
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: ${props => props.theme.fontWeight.regular};

  em {
    margin-right: 0.125rem;
    margin-left: 0.25rem;
    font-size: 2rem;
    font-style: normal;
    color: ${Color.Blue_500_Primary};
  }
`;
