import { HttpStatusCode } from 'axios';
import validator from 'validator';
import { Yup } from '../services/validation.service';
import { Organization, OrganizationInvite, OrganizationMember } from '../organization';
import {
  CreateOrganizationMultiInvite,
  UpdateOrganizationMember,
  UpsertOrganization,
} from '../validation/organization';
import { apiClient } from './api.service';
import { User } from '../user';
import { AbsolvedCheck } from '../check';

export interface OrganizationWithMemberCount extends Organization {
  memberCount: number;
  activeInvitesCount: number;
}

export async function getOrganizations(): Promise<OrganizationWithMemberCount[]> {
  const res = await apiClient.get<{ organizations: OrganizationWithMemberCount[] }>(`/v1/organizations`);
  return res.data.organizations;
}

export async function createOrganization(newOrg: Yup.InferType<typeof UpsertOrganization>) {
  await apiClient.post(`/v1/organizations`, newOrg);
}

export async function updateOrganization(orgId: number, newInfo: Yup.InferType<typeof UpsertOrganization>) {
  await apiClient.patch(`/v1/organizations/${orgId}`, newInfo);
}

export async function deleteOrganization(orgId: number) {
  await apiClient.delete(`/v1/organizations/${orgId}`);
}

export async function getOrganizationInvites(orgId: number): Promise<OrganizationInvite[]> {
  const res = await apiClient.get<{ invites: OrganizationInvite[] }>(`/v1/organizations/${orgId}/invites`);
  return res.data.invites;
}

export function getEmailsFromMultilineText(text: string): string[] {
  const emails = new Set<string>();
  text
    .split('\n')
    .filter(email => validator.isEmail(email))
    .forEach(email => emails.add(email));
  return [...emails];
}

export interface InviteResult {
  email: string;
  error?: string;
}

export async function createOrganizationMultiInvite(
  orgId: number,
  values: Yup.InferType<typeof CreateOrganizationMultiInvite>
): Promise<InviteResult[]> {
  const emails = getEmailsFromMultilineText(values.emails);

  if (!emails.length) {
    return [];
  }

  const results = await Promise.all(
    emails.map(email =>
      apiClient.post<{ success: true }>(
        `/v1/organizations/${orgId}/invites`,
        { email, role: values.role },
        { validateStatus: () => true }
      )
    )
  );

  return results.map((res, i) => ({
    email: emails[i],
    error:
      res.status !== HttpStatusCode.Created ? (res.data as any)?.error?.message ?? 'Unbekannter Fehler' : undefined,
  }));
}

export async function deleteOrganizationInvite(orgId: number, inviteId: number) {
  await apiClient.delete(`/v1/organizations/${orgId}/invites/${inviteId}`);
}

export interface OrganizationListMembersEntry extends OrganizationMember {
  user: Pick<User, 'firstName' | 'lastName' | 'email' | 'lastActive' | 'createdAt'>;
  absolvedChecksCount: number;
}

export async function getOrganizationMembers(orgId: number): Promise<OrganizationListMembersEntry[]> {
  const res = await apiClient.get<{ members: OrganizationListMembersEntry[] }>(`/v1/organizations/${orgId}/members`);
  return res.data.members;
}

export async function updateOrganizationMember(
  orgId: number,
  memberId: number,
  newInfo: Yup.InferType<typeof UpdateOrganizationMember>
) {
  await apiClient.patch(`/v1/organizations/${orgId}/members/${memberId}`, newInfo);
}

interface DeleteOrganizationMemberOptions {
  mode: DELETE_MODE;
}

export enum DELETE_MODE {
  PERMANENT = 'PERMANENT',
  SOFT = 'SOFT',
}

export async function deleteOrganizationMember(
  orgId: number,
  memberId: number,
  options: DeleteOrganizationMemberOptions
) {
  await apiClient.delete(`/v1/organizations/${orgId}/members/${memberId}`, { params: { mode: options.mode } });
}

interface OrganizationInviteWithOrganization extends OrganizationInvite {
  organization: Organization;
}

export async function getInviteByToken(token: string) {
  const response = await apiClient.get<{ invite: OrganizationInviteWithOrganization; userExists: boolean }>(
    `/v1/organizations/invite-by-token/${token}`
  );
  return response.data;
}

export async function acceptOrgInvite(token: string) {
  await apiClient.post<{ success: true }>(`/v1/organizations/invite-by-token/${token}/accept`);
}

export interface OrganizationListCheck extends AbsolvedCheck {
  userName: string;
}

export async function getOrganizationChecks(orgId: number) {
  const res = await apiClient.get<{ checks: OrganizationListCheck[] }>(`/v1/organizations/${orgId}/checks`);
  return res.data.checks;
}

export function isOrganizationListCheck(check: AbsolvedCheck | OrganizationListCheck): check is OrganizationListCheck {
  return (check as OrganizationListCheck).userName !== undefined;
}
