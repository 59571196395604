import { PLAN, USER_SETTING, UserSettingValueMap, getUserSettingValue } from '@solid/shared';
import { apiClient } from '@solid/shared/services/api.service';
import { getLocalStorageKey, setLocalStorageItem } from './local-storage.service';

const getCacheKey = (setting: USER_SETTING) => `_SETTING__${setting}`;

export function getSettingPlaceholderValue<T extends USER_SETTING>(setting: T): UserSettingValueMap[T] | null {
  const entry = getLocalStorageKey(getCacheKey(setting));
  return getUserSettingValue(setting, entry);
}

export async function getSettingFromApi<T extends USER_SETTING>(setting: T): Promise<UserSettingValueMap[T] | null> {
  const res = await apiClient.get<{ value: string }>('/v1/users/me/settings', { params: { type: setting } });
  const { value } = res.data;
  setLocalStorageItem(getCacheKey(setting), value);
  return getUserSettingValue(setting, value);
}

export async function setSettingValue<T extends USER_SETTING>(setting: T, value: string) {
  await apiClient.patch('/v1/users/me/settings', { type: setting, value });
  setLocalStorageItem(getCacheKey(setting), value);
}

export function canUseFavorites(userPlan: PLAN) {
  return [PLAN.PLUS, PLAN.PLUS_TRIAL].includes(userPlan);
}
