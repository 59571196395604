import { Color } from '@solid/shared/styles/colors';
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

export const LastFetchContainer = styled.div`
  font-size: 0.875rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin: 0 0 2rem 0;
  @media (${props => props.theme.breakpoint.md.up}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const LastFetchText = styled.span`
  font-style: italic;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  cursor: help;

  @media (${props => props.theme.breakpoint.md.up}) {
    margin-top: 0;
  }

  svg {
    margin-right: 0.25rem;
  }
`;

export const FiltersContainer = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  gap: 16px 8px;
  flex-wrap: wrap;

  .form-field {
    margin-top: 0;
    margin-bottom: 0;
    width: auto;
    min-width: 0;
    flex: 1 1 auto;
  }

  /* TODO why do we need to set this styling to have all fields aligned? */
  .control {
    input,
    select {
      height: 42px;
    }
  }

  select {
    color: ${Color.Neutral_0_White};
    option {
      color: ${Color.Neutral_1000_Black};
    }
  }
`;

export const FilterSearchInput = styled.div`
  && {
    flex-grow: 1;
  }
`;

export const FilterDateToText = styled.span`
  display: inline-block;
  margin: 8px 8px 0;
`;

export const FiltersInfoText = styled.p`
  margin-top: 0.5rem;
  font-size: 0.875rem;
`;

export const NoChecks = styled.div`
  text-align: center;

  svg {
    display: block;
    margin: 3rem auto 2rem;
    width: auto;
    color: ${Color.Blue_100};
  }

  a {
    display: inline-block;
    margin: 1rem auto 0;
  }
`;

export const ShowMoreButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
