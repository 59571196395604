import React from 'react';
import * as Styled from './EmptyState.styles';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';

const EmptyState: React.FC = () => {
  return (
    <Styled.Container>
      <Icon path={mdiInformationOutline} size='3rem' />
      <br />
      Keine Daten vorhanden
    </Styled.Container>
  );
};

export default EmptyState;
