import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth.context';
import { useOrganizationAbility } from '../../hooks/useOrganizationAbility';
import { isStandaloneAccount } from '../../services/user.service';
import PlanSettings from './PlanSettings';
import * as StyledSettings from './Settings.styles';

const SettingsBillingPage: React.FC = () => {
  const ability = useOrganizationAbility();
  const { auth } = useContext(AuthContext);

  if (!isStandaloneAccount(auth) && !ability.can('read', 'Organization', 'billing')) {
    return <Navigate to='/settings/account' />;
  }

  return (
    <>
      <h2>Rechnungen</h2>
      <StyledSettings.Section>
        <PlanSettings />
      </StyledSettings.Section>
    </>
  );
};

export default SettingsBillingPage;
