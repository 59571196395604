import React, { HTMLProps } from 'react';
import { Color } from '../styles/colors';
import * as Styled from './Badge.styles';

export interface BadgeProps {
  color?: Color;
}

const Badge: React.FC<BadgeProps & HTMLProps<HTMLDivElement>> = props => {
  return (
    <Styled.Container $color={props.color} $hasTitle={!!props.title} {...props}>
      {props.children}
    </Styled.Container>
  );
};

export default Badge;
