import { mdiHistory } from '@mdi/js';
import Icon from '@mdi/react';
import { AbsolvedCheck, CHECK_TYPE, CHECK_TYPES_DISABLED, CHECK_TYPE_NAME_TEXT } from '@solid/shared';
import {
  OrganizationListCheck,
  getOrganizationChecks,
  isOrganizationListCheck,
} from '@solid/shared/services/organization.service';
import { Button, LoadingSpinner } from '@solid/shared/ui';
import { useQuery } from '@tanstack/react-query';
import { parse } from 'date-fns';
import { debounce } from 'lodash';
import ms from 'ms';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconNoChecks } from '../../assets/images/icons/checks-empty.svg';
import { AuthContext } from '../../contexts/auth.context';
import { getPersonalChecks } from '../../services/check.service';
import CheckEntry from './CheckEntry';
import * as Styled from './ChecksList.styles';

const initialAmountShown = 10;

interface ChecksListProps {
  variant: 'personal' | 'org';
}

const ChecksList: React.FC<ChecksListProps> = ({ variant }) => {
  const { auth } = useContext(AuthContext);

  const [filterType, setFilterType] = useState<CHECK_TYPE | null>(null);
  const [filterUserId, setFilterUserId] = useState<number | null>(null);
  const [filterItemName, setFilterItemName] = useState('');
  const [filterDateFrom, setFilterDateFrom] = useState<Date | null>(null);
  const [filterDateTo, setFilterDateTo] = useState<Date | null>(null);
  const [amountShown, setAmountShown] = useState(initialAmountShown);
  const showMoreBtnRef = useRef<HTMLDivElement>(null);

  // TODO Mark individual checks depending on hit state:
  // no hits at all -> green
  // hits, but marked as "no hit" -> orange
  // hits, marked as hit -> red

  const { data, isLoading, error, refetch, dataUpdatedAt } = useQuery<
    unknown,
    unknown,
    (AbsolvedCheck | OrganizationListCheck)[]
  >(
    ['checks', variant, auth.org?.id],
    () => {
      if (variant === 'personal') {
        return getPersonalChecks();
      } else {
        return getOrganizationChecks(auth.org.id);
      }
    },
    {
      retry: false,
      refetchInterval: ms('10sec'),
    }
  );

  const checks = useMemo(() => {
    return (data ?? [])
      .filter(check => {
        if (filterType !== null && check.type !== filterType) {
          return false;
        }
        if (filterItemName?.length > 0) {
          if (!check.itemName) {
            return false;
          }
          if (check.itemName.toLowerCase().includes(filterItemName.trim().toLowerCase()) === false) {
            return false;
          }
        }
        if (filterUserId !== null) {
          if (check.userId !== filterUserId) {
            return false;
          }
        }
        if (filterDateFrom !== null) {
          if (check.submittedAt < filterDateFrom) {
            return false;
          }
        }
        if (filterDateTo !== null) {
          if (check.submittedAt > filterDateTo) {
            return false;
          }
        }
        return true;
      })
      .sort((a, b) => b.submittedAt.getTime() - a.submittedAt.getTime());
  }, [data, filterType, filterItemName, filterUserId, filterDateFrom, filterDateTo]);

  useEffect(() => {
    // Reset amount shown when filters change
    setAmountShown(initialAmountShown);
  }, [filterType, filterItemName, filterUserId, filterDateFrom, filterDateTo]);

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (showMoreBtnRef.current) {
        const { top } = showMoreBtnRef.current.getBoundingClientRect();
        const offset = 200;
        if (top + offset < window.innerHeight) {
          setAmountShown(amount => amount + 10);
        }
      }
    }, 250);
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [showMoreBtnRef]);

  const uniqueUsers = useMemo(() => {
    const users = new Map<number, string>();
    (data ?? []).forEach(check => {
      if (isOrganizationListCheck(check)) {
        users.set(check.userId, check.userName);
      }
    });
    return [...users.entries()].map(([id, name]) => ({ id, name }));
  }, [data]);

  if (isLoading) {
    return (
      <Styled.LoadingContainer>
        <LoadingSpinner />
      </Styled.LoadingContainer>
    );
  }

  const shown = checks.slice(0, amountShown);

  return (
    <>
      {error && <div className='notification is-error'>{(error as Error).message}</div>}

      {/* No checks completed yet */}
      {data?.length === 0 && (
        <div>
          <p>Abgeschlossene Prüfungen können Sie nach Beendigung hier einsehen.</p>
          <Styled.NoChecks>
            <IconNoChecks height='8rem' />
            <Link to='/'>
              <Button>Zu den Prüfungen</Button>
            </Link>
          </Styled.NoChecks>
        </div>
      )}

      {/* More than one check completed */}
      {data?.length > 0 && (
        <>
          {variant === 'personal' ? (
            <p>
              Sie haben insgesamt{' '}
              <b>
                {data.length} Prüfung{data.length === 1 ? '' : 'en'}
              </b>{' '}
              abgeschlossen.
            </p>
          ) : (
            <p>
              Nutzer in dieser Organisation haben insgesamt{' '}
              <b>
                {data.length} Prüfung{data.length === 1 ? '' : 'en'}
              </b>{' '}
              abgeschlossen.
            </p>
          )}

          <Styled.LastFetchContainer className='notification is-info'>
            <span>
              Diese Ansicht aktualisert sich automatisch.
              <br />
              Neue abgeschlossene Prüfungen werden hier aufgelistet.
            </span>
            <Styled.LastFetchText title='Letzte Aktualisierung der Daten'>
              <Icon path={mdiHistory} size={0.75} />
              <span>{new Date(dataUpdatedAt).toLocaleString()}</span>
            </Styled.LastFetchText>
          </Styled.LastFetchContainer>

          <Styled.FiltersContainer>
            <div className='form-field'>
              <label htmlFor='filter-type'>Prüfungsart</label>
              <div className='control'>
                <select
                  id='filter-type'
                  name='filter-type'
                  defaultValue=''
                  onChange={e => {
                    const value = (e.currentTarget.value as CHECK_TYPE) ?? '';
                    if (value === '') {
                      setFilterType(null);
                    } else {
                      setFilterType(value);
                    }
                  }}>
                  <option value=''>Beliebig</option>
                  {Object.keys(CHECK_TYPE).map((type: CHECK_TYPE) => (
                    <option key={type} value={type}>
                      {CHECK_TYPE_NAME_TEXT[type]}
                      {CHECK_TYPES_DISABLED.includes(type) && ' (alt)'}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {variant === 'org' && (
              <div className='form-field'>
                <label htmlFor='filter-user-id'>Prüfer</label>
                <div className='control'>
                  <select
                    id='filter-user-id'
                    name='filter-user-id'
                    defaultValue=''
                    onChange={e => {
                      const value = (e.currentTarget.value as CHECK_TYPE) ?? '';
                      if (value === '') {
                        setFilterUserId(null);
                      } else {
                        setFilterUserId(+value);
                      }
                    }}>
                    <option value=''>Beliebig</option>
                    {[...uniqueUsers].map(user => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div className='form-field'>
              <label htmlFor='filter-date-from'>Datum</label>
              <div className='control'>
                <input
                  type='date'
                  name='filter-date-from'
                  id='filter-date-from'
                  onChange={e => {
                    try {
                      const parsed = parse(e.currentTarget.value, 'yyyy-MM-dd', new Date());
                      parsed.setHours(0, 0, 0, 0);
                      setFilterDateFrom(parsed);
                    } catch (e) {
                      setFilterDateFrom(null);
                    }
                  }}
                />
                <Styled.FilterDateToText>bis</Styled.FilterDateToText>
                <input
                  type='date'
                  name='filter-date-to'
                  id='filter-date-to'
                  onChange={e => {
                    try {
                      const parsed = parse(e.currentTarget.value, 'yyyy-MM-dd', new Date());
                      parsed.setHours(23, 59, 59, 999);
                      setFilterDateTo(parsed);
                    } catch (e) {
                      setFilterDateTo(null);
                    }
                  }}
                />
              </div>
            </div>

            <Styled.FilterSearchInput className='form-field'>
              <label htmlFor='filter-name'>Titel</label>
              <div className='control'>
                <input
                  id='filter-name'
                  name='filter-name'
                  type='text'
                  placeholder='Suchen nach ...'
                  value={filterItemName}
                  onChange={e => setFilterItemName(e.currentTarget.value)}
                />
              </div>
            </Styled.FilterSearchInput>
          </Styled.FiltersContainer>

          {shown.length < data.length && (
            <Styled.FiltersInfoText>
              Es wurden <b>{checks.length}</b> Prüfungen gefunden.
            </Styled.FiltersInfoText>
          )}

          {shown.map(check => (
            <CheckEntry key={check.id} check={check} context={variant} onDelete={() => refetch()} />
          ))}

          {shown.length < checks.length && (
            <Styled.ShowMoreButtonContainer ref={showMoreBtnRef}>
              <Button onClick={() => setAmountShown(amountShown + 10)}>Mehr anzeigen</Button>
            </Styled.ShowMoreButtonContainer>
          )}
        </>
      )}
    </>
  );
};

export default ChecksList;
