import { Navigate, createBrowserRouter } from 'react-router-dom';
import { environment } from '../environments/environment';
import App from './App';
import ConfirmEmailPage from './components/auth/ConfirmEmailPage';
import EmailConfirmationPage from './components/auth/EmailConfirmationPage';
import ForgotPasswordPage from './components/auth/ForgotPasswordPage';
import ForumLoginPage from './components/auth/ForumLoginPage';
import LoginPage from './components/auth/LoginPage';
import OrgInvitePage from './components/auth/OrgInvitePage';
import RegisterPage from './components/auth/RegisterPage';
import ResetPasswordPage from './components/auth/ResetPasswordPage';
import CheckReportPage from './components/checks/CheckReportPage';
import CheckResultPage from './components/checks/CheckResultPage';
import NewCheckPage from './components/checks/NewCheckPage';
import PersonalChecksPage from './components/checks/PersonalChecksPage';
import FourOhFourPage from './components/global/FourOhFourPage';
import LoggedInRoute from './components/global/LoggedInRoute';
import LoggedOutRoute from './components/global/LoggedOutRoute';
import PaidPlanRoute from './components/global/PaidPlanRoute';
import HomePage from './components/home/HomePage';
import OrgAnalyticsPage from './components/org/OrgAnalyticsPage';
import OrgChecksPage from './components/org/OrgChecksPage';
import OrgUsersPage from './components/org/OrgMembersPage';
import OrgWrapperPage from './components/org/OrgWrapperPage';
import SeminarPage from './components/seminars/SeminarPage';
import SettingsAccountPage from './components/settings/SettingsAccountPage';
import SettingsBillingPage from './components/settings/SettingsBillingPage';
import SettingsWrapperPage from './components/settings/SettingsWrapperPage';
import TutorialPage from './components/tutorials/TutorialPage';
import TutorialsOverviewPage from './components/tutorials/TutorialsOverviewPage';
import TutorialsWrapperPage from './components/tutorials/TutorialsWrapperPage';
import CheckReportPageMultiTest from './components/checks/individual-multi-test/CheckReportPageMultiTest';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <FourOhFourPage />,
    children: [
      /**
       * Redirect old routes to new ones
       */
      { path: '/account', element: <Navigate to='/' /> },
      { path: '/account/checks', element: <Navigate to='/checks' /> },
      { path: '/account/settings', element: <Navigate to='/settings/account' /> },
      { path: '/account/tutorials', element: <Navigate to='/tutorials' /> },
      { path: '/account/tutorials/:tutorialId', element: <Navigate to='/tutorials' /> },
      { path: '/account/seminars/free', element: <Navigate to='/seminars/free' /> },
      { path: '/account/seminars/regular', element: <Navigate to='/seminars/regular' /> },

      /**
       * Routes
       */
      {
        path: '',
        element: (
          <LoggedInRoute>
            <HomePage />
          </LoggedInRoute>
        ),
      },
      {
        path: 'checks',
        element: (
          <LoggedInRoute>
            <PersonalChecksPage />
          </LoggedInRoute>
        ),
      },
      {
        path: 'org',
        element: (
          <LoggedInRoute>
            <OrgWrapperPage />
          </LoggedInRoute>
        ),
        children: [
          { path: '', element: <Navigate to='/org/analytics' /> },
          {
            path: 'checks',
            element: (
              <LoggedInRoute>
                <OrgChecksPage />
              </LoggedInRoute>
            ),
          },
          {
            path: 'analytics',
            element: (
              <LoggedInRoute>
                <OrgAnalyticsPage />
              </LoggedInRoute>
            ),
          },
          {
            path: 'users',
            element: (
              <LoggedInRoute>
                <OrgUsersPage />
              </LoggedInRoute>
            ),
          },
        ],
      },
      {
        path: 'settings',
        element: (
          <LoggedInRoute>
            <SettingsWrapperPage />
          </LoggedInRoute>
        ),
        children: [
          { path: '', element: <Navigate to='/settings/account' /> },
          {
            path: 'account',
            element: (
              <LoggedInRoute>
                <SettingsAccountPage />
              </LoggedInRoute>
            ),
          },
          {
            path: 'billing',
            element: (
              <LoggedInRoute>
                <SettingsBillingPage />
              </LoggedInRoute>
            ),
          },
        ],
      },
      {
        path: 'tutorials',
        element: (
          <LoggedInRoute>
            <TutorialsWrapperPage />
          </LoggedInRoute>
        ),
        children: [
          {
            path: '',
            element: (
              <LoggedInRoute>
                <TutorialsOverviewPage />
              </LoggedInRoute>
            ),
          },
          {
            path: ':tutorialId',
            element: (
              <LoggedInRoute>
                <TutorialPage />
              </LoggedInRoute>
            ),
          },
        ],
      },
      {
        path: 'seminars/free',
        element: (
          <LoggedInRoute>
            <SeminarPage title='Online-Demo' url={environment.seminarIframeUrl.free} />
          </LoggedInRoute>
        ),
      },
      {
        path: 'seminars/regular',
        element: (
          <LoggedInRoute>
            <SeminarPage title='Seminare' url={environment.seminarIframeUrl.regular} />
          </LoggedInRoute>
        ),
      },
      {
        path: 'login',
        element: (
          <LoggedOutRoute>
            <LoginPage />
          </LoggedOutRoute>
        ),
      },
      {
        path: 'register',
        element: (
          <LoggedOutRoute>
            <RegisterPage />
          </LoggedOutRoute>
        ),
      },
      {
        path: 'confirm-email',
        element: (
          <LoggedOutRoute>
            <ConfirmEmailPage />
          </LoggedOutRoute>
        ),
      },
      {
        path: 'email-confirmation/:token',
        element: (
          <LoggedOutRoute>
            <EmailConfirmationPage />
          </LoggedOutRoute>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <LoggedOutRoute>
            <ForgotPasswordPage />
          </LoggedOutRoute>
        ),
      },
      {
        path: 'password-reset/:token',
        element: (
          <LoggedOutRoute>
            <ResetPasswordPage />
          </LoggedOutRoute>
        ),
      },
      {
        path: 'forum/login',
        element: (
          <LoggedInRoute>
            <ForumLoginPage />
          </LoggedInRoute>
        ),
      },
      {
        path: 'accept-invite/:token',
        element: <OrgInvitePage />,
      },
      {
        path: 'checks/new/:checkType',
        element: (
          <LoggedInRoute>
            <NewCheckPage />
          </LoggedInRoute>
        ),
      },
      {
        path: 'checks/:checkId/report',
        element: (
          <LoggedInRoute>
            <PaidPlanRoute>
              <CheckReportPage />
            </PaidPlanRoute>
          </LoggedInRoute>
        ),
      },
      {
        path: 'checks/:checkId',
        element: (
          <LoggedInRoute>
            <CheckResultPage />
          </LoggedInRoute>
        ),
      },
    ],
  },
]);
