import styled from 'styled-components';
import DataCard from './DataCard';
import { Color } from '../../styles/colors';

export const Card = styled(DataCard)`
  text-align: center;
  align-items: center;
  justify-content: flex-start;

  p {
    position: relative;
    padding-top: 0.75rem;
    margin: 0.5rem auto 0.75rem;
    max-width: 18rem;
    color: ${Color.Neutral_600};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 20px;
      height: 1px;
      background-color: ${Color.Neutral_600};
    }
  }
`;

export const Value = styled.span`
  font-size: 2.5rem;
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: center;
`;
