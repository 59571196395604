import { mdiLock } from '@mdi/js';
import Icon from '@mdi/react';
import { PLAN } from '@solid/shared';
import { Button } from '@solid/shared/ui';
import { ButtonProps } from '@solid/shared/ui/Button';
import React, { useContext } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { PaymentPopupContext } from '../../contexts/payment-popup.context';
import { AuthContext } from '../../contexts/auth.context';

interface OnlyPaidLinkButtonProps {
  children: string;
  buttonProps?: ButtonProps;
}

const OnlyPaidLinkButton: React.FC<OnlyPaidLinkButtonProps & LinkProps> = ({
  children,
  buttonProps = {},
  ...props
}) => {
  const { auth } = useContext(AuthContext);
  const paymentPopup = useContext(PaymentPopupContext);

  const hasPaidPlan = [PLAN.PLUS, PLAN.PLUS_TRIAL].includes(auth.user.plan);

  const handleOnClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    if (hasPaidPlan === false) {
      e.preventDefault();
      paymentPopup.setOpen(true);
    }
  };

  return (
    <Link {...props} className={props.className} onClick={handleOnClick}>
      <Button {...buttonProps}>
        {children}
        {hasPaidPlan === false && <Icon path={mdiLock} size='12px' />}
      </Button>
    </Link>
  );
};

export default OnlyPaidLinkButton;
