import styled from 'styled-components';

export const CheckResultDualUse = styled.div`
  text-align: center;
  font-size: 1.125rem;
  margin: 0 auto;
`;

export const Result = styled.div`
  max-width: 52rem;
  margin: 0 auto;
`;

export const ResultCategories = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;

  > a {
    margin: 0.5em 0;
  }
`;

export const NoteContainer = styled.div`
  max-width: 32rem;
  margin: 4rem auto 6rem;
`;
