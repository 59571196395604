import transparentize from 'polished/lib/color/transparentize';
import styled, { css } from 'styled-components';
import { Color } from '../styles/colors';
import { NotificationMessageProps } from './NotificationMessage';
import Icon from '@mdi/react';

export const Container = styled.div<{
  $state?: NotificationMessageProps['state'];
  $hasBrighterText?: NotificationMessageProps['hasBrighterText'];
}>`
  position: relative;
  padding: 10px 20px 10px 38px;
  margin-top: 16px;
  border-radius: ${props => props.theme.borderRadius.small};

  ${props =>
    props.$state === 'success' &&
    css`
      background-color: ${transparentize(0.85, Color.Green_500_Success)};
      color: ${props.$hasBrighterText ? Color.Green_400 : Color.Green_600};
    `};

  ${props =>
    props.$state === 'warning' &&
    css`
      background-color: ${transparentize(0.85, Color.Yellow_500_Warning)};
      color: ${props.$hasBrighterText ? Color.Yellow_400 : Color.Yellow_600};
    `};

  ${props =>
    props.$state === 'danger' &&
    css`
      background-color: ${transparentize(0.85, Color.Red_500_Danger)};
      color: ${props.$hasBrighterText ? Color.Red_400 : Color.Red_600};
    `};

  ${props =>
    props.$state === 'info' &&
    css`
      background-color: ${transparentize(0.85, Color.Blue_500_Primary)};
      color: ${props.$hasBrighterText ? Color.Blue_400 : Color.Blue_600};
    `};

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const AdjustedIcon = styled(Icon)`
  position: absolute;
  top: 12px;
  left: 10px;
`;
