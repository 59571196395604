import Icon from '@mdi/react';
import styled from 'styled-components';
import { Color } from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  ${props => props.theme.breakpoint.sm.down} {
    flex-wrap: wrap;
  }

  > .form-field {
    flex-basis: 50%;
    margin: 0;
  }

  select,
  textarea {
    color: ${Color.Blue_800} !important;
  }
`;

export const Invites = styled.span`
  opacity: 0.6;
`;

export const InvitesIcon = styled(Icon)`
  margin: 0 0 2px 2px;
  opacity: 0.5;
  vertical-align: middle;
`;
