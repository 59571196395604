import styled, { keyframes } from 'styled-components';
import { Color } from '../styles/colors';

const rotateIndicator = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export interface LoadingIndicatorProps {
  $themeColor?: 'primary' | 'contrast';
}

export const Indicator = styled.div<LoadingIndicatorProps>`
  display: inline-block;
  padding: 0.5rem;

  &::after {
    content: ' ';
    display: block;
    width: 30px;
    height: 30px;
    margin: 1px;
    border-radius: 50%;
    border: 4px solid;
    border-color: ${props => {
      const color = props.$themeColor === 'primary' ? Color.Blue_500_Primary : Color.Neutral_300;
      return `${color} transparent ${color} transparent`;
    }};
    animation: 1.2s linear infinite ${rotateIndicator};
  }
`;
