import React from 'react';
import { environment } from '../../../environments/environment';

const ToSField: React.FC = () => {
  return (
    <div className='form-field'>
      <label className='checkbox' htmlFor='register-terms-of-use'>
        <input type='checkbox' id='register-terms-of-use' name='register-terms-of-use' required />
        <span>
          Hiermit erkläre ich mich mit den{' '}
          <a href={`${environment.kirbyBaseUrl}/terms-of-use/free`} target='_blank' rel='noopener noreferrer'>
            Nutzungsbedingungen
          </a>{' '}
          einverstanden.
        </span>
      </label>
    </div>
  );
};

export default ToSField;
