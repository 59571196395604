import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import * as Styled from './Popup.styles';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

export interface PopupProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  /**
   * Set a custom max width for the popup
   */
  maxWidth?: string;
  className?: string;
}

const Popup: React.FC<PopupProps> = ({ isOpen, onClose, children, maxWidth = '736px', className = '' }) => {
  const [modalContainer, setModalContainer] = useState<HTMLDivElement | null>(null);

  const [isInDOM, setIsInDOM] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [visualViewport, setVisualViewport] = useState<Partial<
    Pick<VisualViewport, 'offsetTop' | 'offsetLeft' | 'width' | 'height'>
  > | null>(null);

  useEffect(() => {
    // Create a wrapper component and inject into body
    const createdDiv = document.createElement('div');
    createdDiv.setAttribute('data-modal-container', '');
    document.body.appendChild(createdDiv);
    setModalContainer(createdDiv);

    return () => {
      // Clean up the generated element from the DOM
      window.setTimeout(() => createdDiv.parentNode?.removeChild(createdDiv), 300);
    };
  }, []);

  /**
   * Toggle modal with a delay to enable css transistions
   */
  useEffect(() => {
    let timeout: number;
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
      timeout = window.setTimeout(() => {
        setIsInDOM(true);
        setIsVisible(true);
      }, 10);
    } else {
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('touchAction');
      setIsVisible(false);
      timeout = window.setTimeout(() => setIsInDOM(false), 300);
    }

    return () => {
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('touchAction');
      window.clearTimeout(timeout);
    };
  }, [isOpen]);

  /**
   * Enable close with keyboard
   */
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        onClose();
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  /**
   * Set correct position and height on mobile devices
   * This has to be done because the viewport size differs from the rendered height
   */
  useEffect(() => {
    function onResize() {
      setVisualViewport({
        offsetTop: window.visualViewport ? Math.round(window.visualViewport.offsetTop) : undefined,
        offsetLeft: window.visualViewport ? Math.round(window.visualViewport.offsetLeft) : undefined,
        width: window.visualViewport?.width,
        height: window.visualViewport?.height,
      });
    }

    if (!window.visualViewport) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    window.visualViewport.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.visualViewport?.removeEventListener('resize', onResize);
    };
  }, []);

  if (!isOpen && !isInDOM) {
    return null;
  }

  if (!modalContainer) {
    return null;
  }

  return ReactDOM.createPortal(
    <Styled.Modal
      aria-hidden={!isVisible}
      style={{
        top: visualViewport?.offsetTop ? `${visualViewport?.offsetTop}px` : 0,
        left: visualViewport?.offsetLeft ? `${visualViewport?.offsetLeft}px` : 0,
        width: visualViewport?.width ? `${visualViewport?.width}px` : '100vw',
        height: visualViewport?.height ? `${visualViewport?.height}px` : '100vw',
      }}
      onClick={e => e.stopPropagation()}
      className={className}>
      <Styled.Container $isVisible={isVisible} style={{ maxWidth: maxWidth }}>
        <Styled.ButtonClose hasIconOnly variant='transparent' onClick={onClose}>
          <Icon path={mdiClose} size={1} />
        </Styled.ButtonClose>
        <Styled.Content>{children}</Styled.Content>
      </Styled.Container>
      <Styled.Fade $isVisible={isVisible} onClick={() => onClose()} />
    </Styled.Modal>,
    modalContainer
  );
};

export default Popup;
