import { Button, StyledButton } from '@solid/shared/ui';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useContext } from 'react';
import { Yup } from '@solid/shared/services/validation.service';
import { AuthContext } from '../../contexts/auth.context';
import * as userService from '../../services/user.service';
import { updateOrganization } from '@solid/shared/services/organization.service';

const OrgCompanyDetailsForm: React.FC = () => {
  const { auth, refetchAuthState } = useContext(AuthContext);

  const {
    mutate: updateUser,
    error,
    isLoading,
    isSuccess,
  } = useMutation<void, Error, Yup.InferType<typeof userService.OrgCompanyDetailsSchema>>(async data => {
    await updateOrganization(auth.org.id, data);
    await refetchAuthState();
  });

  return (
    <Formik
      initialValues={
        {
          companyName: auth.org.companyName,
          companyStreet: auth.org.companyStreet,
          companyZipCode: auth.org.companyZipCode,
          companyCity: auth.org.companyCity,
          companyCountry: auth.org.companyCountry ?? '',
          companyEmail: auth.org.companyEmail,
          companyVatId: auth.org.companyVatId,
          companyBillingName: auth.org.companyBillingName,
          companyBillingStreet: auth.org.companyBillingStreet,
          companyBillingZipCode: auth.org.companyBillingZipCode,
          companyBillingCity: auth.org.companyBillingCity,
          companyBillingCountry: auth.org.companyBillingCountry ?? '',
        } as Yup.InferType<typeof userService.OrgCompanyDetailsSchema>
      }
      onSubmit={values => updateUser(values)}
      validationSchema={userService.OrgCompanyDetailsSchema}>
      {({ isValidating }) => (
        <Form>
          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='companyName'>Firmenname*</label>
              <div className='control'>
                <Field type='text' name='companyName' id='companyName' autoComplete='organization' required />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyName' />
            </div>

            <div className='form-field'>
              <label htmlFor='companyStreet'>Straße und Hausnr.*</label>
              <div className='control'>
                <Field type='text' name='companyStreet' id='companyStreet' required />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyStreet' />
            </div>
          </div>

          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='companyCity'>Stadt*</label>
              <div className='control'>
                <Field type='text' name='companyCity' id='companyCity' required />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyCity' />
            </div>
            <div className='form-field'>
              <label htmlFor='companyZipCode'>PLZ*</label>
              <div className='control'>
                <Field type='text' name='companyZipCode' id='companyZipCode' required />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyZipCode' />
            </div>
          </div>

          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='companyCountry'>Land*</label>
              <div className='control'>
                <Field component='select' type='select' name='companyCountry' id='companyCountry' required>
                  <option value='' disabled>
                    Bitte auswählen...
                  </option>
                  <option value='DE'>Deutschland</option>
                  <option value='AT'>Österreich</option>
                  <option value='CH'>Schweiz</option>
                </Field>
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyCountry' />
            </div>
            <div className='form-field is-ghost'></div>
          </div>

          <h4>Rechnungsempfänger</h4>
          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='companyBillingName'>Name*</label>
              <div className='control'>
                <Field type='text' name='companyBillingName' id='companyBillingName' autoComplete='organization' />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyBillingName' />
            </div>

            <div className='form-field'>
              <label htmlFor='companyBillingStreet'>Straße und Hausnr.*</label>
              <div className='control'>
                <Field type='text' name='companyBillingStreet' id='companyBillingStreet' />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyBillingStreet' />
            </div>
          </div>

          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='companyBillingCity'>Stadt*</label>
              <div className='control'>
                <Field type='text' name='companyBillingCity' id='companyBillingCity' />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyBillingCity' />
            </div>
            <div className='form-field'>
              <label htmlFor='companyBillingZipCode'>PLZ*</label>
              <div className='control'>
                <Field type='text' name='companyBillingZipCode' id='companyBillingZipCode' />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyBillingZipCode' />
            </div>
          </div>

          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='companyBillingCountry'>Land*</label>
              <div className='control'>
                <Field component='select' type='select' name='companyBillingCountry' id='companyBillingCountry'>
                  <option value='' disabled>
                    Bitte auswählen...
                  </option>
                  <option value='DE'>Deutschland</option>
                  <option value='AT'>Österreich</option>
                  <option value='CH'>Schweiz</option>
                </Field>
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyBillingCountry' />
            </div>
            <div className='form-field is-ghost'></div>
          </div>

          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='companyVatId'>Ust-IdNr*</label>
              <div className='control'>
                <Field type='text' name='companyVatId' id='companyVatId' placeholder='DE123456789' required />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyVatId' />
            </div>
            <div className='form-field'>
              <label htmlFor='companyEmail'>E-Mail-Adresse*</label>
              <div className='control'>
                <Field type='text' name='companyEmail' id='companyEmail' placeholder='invoice@firma.de' required />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyEmail' />
            </div>
          </div>

          {isSuccess && (
            <div className='form-field'>
              <div className='notification is-success'>Erfolgreich gespeichert</div>
            </div>
          )}
          {error && (
            <div className='form-field'>
              <div className='notification is-error'>{error.message}</div>
            </div>
          )}

          <StyledButton.ButtonsContainer>
            <Button type='submit' isLoading={isLoading || isValidating} disabled={isLoading || isValidating}>
              Speichern
            </Button>
          </StyledButton.ButtonsContainer>
        </Form>
      )}
    </Formik>
  );
};

export default OrgCompanyDetailsForm;
