import * as Sentry from '@sentry/react';
import { environment } from '../../environments/environment';

type GetComplianceSettingsResult = Parameters<Parameters<Window['ppms']['cm']['api']>[1]>[0];

export function checkConsentState() {
  return new Promise<GetComplianceSettingsResult>((resolve, reject) => {
    window.ppms.cm.api(
      'getComplianceSettings',
      result => {
        if (result?.consents?.analytics?.status === 1) {
          /**
           * Load LinkedIn insights
           */
          const linkedInScriptExists = document.getElementById('script-linkedin-insights');
          if (!linkedInScriptExists) {
            const script = document.createElement('script');
            script.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
            script.id = 'linkedin-insights';
            script.onload = sendLinkedInPageView;
            document.body.appendChild(script);
          }
        }
        resolve(result);
      },
      e => Sentry.captureException(e) && reject(e)
    );
  });
}

export function sendLinkedInPageView() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (environment.linkedInInsightsPartnerId && typeof window.lintrk === 'function') {
    /**
     * Undocumented way to track page views
     * @see https://stackoverflow.com/a/59204485/13180424
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.lintrk('track');
  }
}
