import { isPaidPlan } from '@solid/shared';
import { useContext, useEffect, useState } from 'react';
import { PaymentPopupContext } from '../contexts/payment-popup.context';
import { AuthContext } from '../contexts/auth.context';

const PaymentPopupContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { auth } = useContext(AuthContext);
  const [paymentPopupOpen, setPaymentPopupOpen] = useState(false);

  // Automatically open the upgrade popup, if user is logged in
  useEffect(() => {
    if (!auth) {
      return; // User not logged in or we've already run the check
    }
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('upgrade') && !isPaidPlan(auth.user.plan)) {
      setPaymentPopupOpen(true);
    }
  }, [auth]);

  return (
    <PaymentPopupContext.Provider value={{ open: paymentPopupOpen, setOpen: setPaymentPopupOpen }}>
      {children}
    </PaymentPopupContext.Provider>
  );
};

export default PaymentPopupContainer;
