import { mdiAccount, mdiSecurity, mdiShield } from '@mdi/js';
import { Color } from './styles/colors';
import { allowedCountries } from './user';

export interface Organization {
  id: number;
  displayName: string;
  /**
   * Max. amount of seats this organization has available.
   * When more users are added then seats are available, the team will receive a notification about it.
   */
  maxSeats: number;
  /**
   * Plan of the organization. Automatically determined by `maxSeats`.
   */
  plan: ORGANIZATION_PLAN;
  /**
   * Billing info
   */
  companyName: string;
  companyStreet: string;
  companyZipCode: string;
  companyCity: string;
  companyCountry: (typeof allowedCountries)[number] | null;
  companyEmail: string;
  companyVatId: string;
  companyBillingName: string;
  companyBillingStreet: string;
  companyBillingZipCode: string;
  companyBillingCity: string;
  companyBillingCountry: (typeof allowedCountries)[number] | null;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum ORGANIZATION_ROLE {
  MEMBER = 'MEMBER',
  MANAGER = 'MANAGER',
  LOCAL_ADMIN = 'LOCAL_ADMIN',
}

export const ORGANIZATION_ROLE_NAME: Record<ORGANIZATION_ROLE, string> = {
  [ORGANIZATION_ROLE.MEMBER]: 'PLUS Enterprise',
  [ORGANIZATION_ROLE.MANAGER]: 'PRO Enterprise',
  [ORGANIZATION_ROLE.LOCAL_ADMIN]: 'ADMIN Enterprise',
};

export const ORGANIZATION_ROLE_ICONS: Record<ORGANIZATION_ROLE, string> = {
  [ORGANIZATION_ROLE.MEMBER]: mdiAccount,
  [ORGANIZATION_ROLE.MANAGER]: mdiShield,
  [ORGANIZATION_ROLE.LOCAL_ADMIN]: mdiSecurity,
};

export const ORGANIZATION_ROLE_COLOR: Record<ORGANIZATION_ROLE, Color> = {
  [ORGANIZATION_ROLE.MEMBER]: Color.Neutral_700,
  [ORGANIZATION_ROLE.MANAGER]: Color.Blue_500_Primary,
  [ORGANIZATION_ROLE.LOCAL_ADMIN]: Color.Red_500_Danger,
};

export interface OrganizationInvite {
  id: number;
  /**
   * Organization that this invite belongs to.
   */
  organizationId: number;
  /**
   * User that created this invite.
   */
  createdByUserId: number;
  /**
   * Email address of the user that is invited.
   */
  email: string;
  /**
   * Token that is used to accept the invite.
   */
  token: string;
  role: ORGANIZATION_ROLE;
  /**
   * Date the invite expires.
   */
  expiresAt: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface OrganizationMember {
  id: number;
  /**
   * Organization that this member belongs to.
   */
  organizationId: number;
  /**
   * User that is a member of this organization.
   */
  userId: number;
  /**
   * User that initially invited this user.
   */
  invitedByUserId: number;
  role: ORGANIZATION_ROLE;
  createdAt?: Date;
  updatedAt?: Date | null;
  deletedAt?: Date | null;
}

export enum ORGANIZATION_PLAN {
  BASIC = 'BASIC',
  BUSINESS = 'BUSINESS',
  ENTERPRISE = 'ENTERPRISE',
  CUSTOM = 'CUSTOM',
}

export const ORGANIZATION_PLAN_NAME: Record<ORGANIZATION_PLAN, string> = {
  [ORGANIZATION_PLAN.BASIC]: 'Basic',
  [ORGANIZATION_PLAN.BUSINESS]: 'Business',
  [ORGANIZATION_PLAN.ENTERPRISE]: 'Enterprise',
  [ORGANIZATION_PLAN.CUSTOM]: 'Enterprise Plus',
};

export const ORGANIZATION_PLAN_MAX_SEATS: Record<ORGANIZATION_PLAN, number | null> = {
  [ORGANIZATION_PLAN.BASIC]: 5,
  [ORGANIZATION_PLAN.BUSINESS]: 20,
  [ORGANIZATION_PLAN.ENTERPRISE]: 150,
  [ORGANIZATION_PLAN.CUSTOM]: null,
};

export function getNeededOrganizationPlan(amountSeats: number): ORGANIZATION_PLAN {
  for (const plan in ORGANIZATION_PLAN_MAX_SEATS) {
    if (amountSeats <= ORGANIZATION_PLAN_MAX_SEATS[plan]) {
      return plan as ORGANIZATION_PLAN;
    }
  }
  return ORGANIZATION_PLAN.CUSTOM;
}

export function getOrganizationPlanForMaxSeats(maxSeats: number): ORGANIZATION_PLAN {
  for (const plan in ORGANIZATION_PLAN_MAX_SEATS) {
    if (ORGANIZATION_PLAN_MAX_SEATS[plan] === maxSeats) {
      return plan as ORGANIZATION_PLAN;
    }
  }
  return ORGANIZATION_PLAN.CUSTOM;
}
