import { mdiChevronRight } from '@mdi/js';
import { CHECK_TYPE, getCheckTypeFromSlug } from '@solid/shared';
import React from 'react';
import { ReactComponent as FlagFavorites } from '../../assets/images/icons/flag-favorites.svg';
import { ReactComponent as FlagOrgChecks } from '../../assets/images/icons/flag-orgs.svg';
import { KirbyCheckGroup } from '@solid/shared/kirby';
import CollapseContainer from '../common/CollapseContainer';
import CheckCard from './CheckCard';
import * as StyledCheckCard from './CheckCard.styles';
import * as Styled from './CheckGroup.styles';

interface CheckGroupProps {
  group: KirbyCheckGroup;
  isOpen: boolean;
  onClick: () => void;
  favorites: CHECK_TYPE[];
  onFavoriteStateChange: (checkType: CHECK_TYPE) => void;
  emptyState?: React.ReactNode;
}

const CheckGroup: React.FC<CheckGroupProps> = ({
  group,
  isOpen,
  onClick,
  favorites,
  onFavoriteStateChange,
  emptyState = null,
}) => {
  return (
    <Styled.Group id={group.slug} key={group.slug}>
      <Styled.GroupHeader onClick={() => onClick()}>
        <Styled.Chevron size='1rem' path={mdiChevronRight} $isOpen={isOpen} />
        <Styled.FlagWrapper>
          {group.flag === 'favorites' ? (
            <FlagFavorites />
          ) : group.flag === 'organization' ? (
            <FlagOrgChecks />
          ) : (
            <img src={group.flag} width='40' height='40' alt='Flag icon' />
          )}
        </Styled.FlagWrapper>
        <span>{group.name}</span>
        <Styled.Count>{group.checks?.length ?? 0}</Styled.Count>
      </Styled.GroupHeader>
      <CollapseContainer isOpen={isOpen}>
        <Styled.ChecksContainer>
          {group.checks?.length === 0 && <Styled.EmptyStateWrapper>{emptyState}</Styled.EmptyStateWrapper>}
          {group.checks?.length > 0 && (
            <>
              {group.checks.map(check => {
                const checkType = getCheckTypeFromSlug(check.slug);
                return (
                  <CheckCard
                    key={check.slug}
                    check={check}
                    isFavorite={favorites.includes(checkType)}
                    onFavoriteStateChange={() => onFavoriteStateChange(checkType)}
                    hasShadow={true}
                  />
                );
              })}
              {new Array(3).fill(null).map((_, i) => (
                <StyledCheckCard.Checkup
                  $isLocked={false}
                  $hasShadow={false}
                  $isGhost
                  key={`ghost-${i}`}></StyledCheckCard.Checkup>
              ))}
            </>
          )}
        </Styled.ChecksContainer>
      </CollapseContainer>
    </Styled.Group>
  );
};

export default CheckGroup;
