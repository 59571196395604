import { Color } from '@solid/shared/styles/colors';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

// TODO properly convert to styled-components
export const Navbar = styled.section<{ $isShrunk: boolean; $isOpen: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${props => props.theme.navbarHeightMobile};
  background: ${props => props.theme.gradient.base};
  overflow: visible;
  transition: ${props => props.theme.transitionTiming};
  z-index: 99;
  @media print {
    display: none;
  }
  @media (${props => props.theme.breakpoint.md.up}) {
    ${props =>
      props.$isShrunk === false &&
      css`
        height: ${props => props.theme.navbarHeight};
        background: transparent;
      `}
  }

  @media (${props => props.theme.breakpoint.md.down}) {
    ${props =>
      props.$isOpen &&
      css`
        background: ${props => props.theme.gradient.base};

        nav {
          opacity: 1;
          pointer-events: initial;
          max-height: 100%;
        }

        .nav-item {
          display: flex;
        }

        #nav-toggle {
          display: flex;
        }
      `}
  }

  ${props =>
    props.$isShrunk &&
    css`
      box-shadow: 0 12px 17px 0 ${transparentize(0.85, Color.Blue_1000_Deep)};
      height: 72px;
    `}

  &.disappeared {
    transform: translateY(-100%);
    overflow: hidden;

    ${props =>
      props.$isOpen &&
      css`
        transform: translateY(0);
        overflow: visible;
      `}
  }

  .nav-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: ${props => props.theme.siteMaxWidth};
    margin: 0 auto;
  }

  .brand {
    position: relative;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: 100%;
    max-height: 2.75rem;
    margin-right: 0.5rem;
    opacity: 1;
    transition: opacity ${props => props.theme.transitionTiming};
    color: ${Color.Neutral_0_White};

    .logo {
      height: 100%;
    }

    a {
      display: inline-block;
    }
  }

  .santa-hat {
    position: absolute;
    top: -1rem;
    left: -0.5rem;
    width: 2.375rem;
  }

  .link {
    color: ${Color.Neutral_0_White};
    opacity: 1;
    line-height: 1;
    margin-right: 0.5rem;

    &:hover {
      opacity: 0.65;
      transition: opacity 0.25s ease;
      text-decoration: none;
    }
  }

  .current-plan {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.875rem;
    border: 1px solid ${Color.Blue_500_Primary};
    border-radius: 99px;
    font-size: 0.75rem;
    line-height: 1;
    letter-spacing: 0.03em;
    color: ${Color.Blue_500_Primary};
    font-weight: ${props => props.theme.fontWeight.bold};
    cursor: default;

    @media (${props => props.theme.breakpoint.md.up}) {
      margin-right: 0.25rem;
    }
  }

  .plus-trial-text {
    display: inline-block;
    margin-right: 0.5rem;
    font-size: 0.875rem;
  }

  nav {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    position: fixed;
    height: 100vh;
    max-height: ${props => props.theme.navbarHeightMobile};
    width: 100vw;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: ${props => props.theme.gradient.base};
    transition: ${props => props.theme.transitionTiming};
    opacity: 0;
    pointer-events: none;

    @media (${props => props.theme.breakpoint.md.up}) {
      position: relative;
      height: auto;
      max-height: ${props => props.theme.navbarHeight};
      width: auto;
      transition: none;
      opacity: 1;
      pointer-events: initial;
      background: transparent;
    }
  }

  .nav-list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    margin: -1rem 0;

    @media (${props => props.theme.breakpoint.md.up}) {
      flex-direction: row;
      justify-content: flex-end;
      padding: 0;
      margin: 0 -0.75rem;
    }
  }

  .nav-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;

    @media (${props => props.theme.breakpoint.md.up}) {
      margin: 0 0.75rem;
    }

    .badge {
      margin-left: 0.375rem;
    }
  }

  .nav-link {
    padding: 1.25rem 0.75rem;
    color: ${Color.Neutral_0_White};
    font-size: 1.25rem;
    line-height: 1;
    @media (min-width: 768px) {
      padding: 0.5rem 0.75rem;
      font-size: 1rem;
    }
    &:hover {
      text-decoration: none;
    }
  }

  #nav-toggle {
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    display: inline-block;
    height: 20px;
    width: 24px;
    padding: 1.25rem 1.25rem;
    margin-left: 0.5rem;
    border: 0;
    background: transparent;
    cursor: pointer;

    @media (${props => props.theme.breakpoint.md.up}) {
      display: none;
    }

    span {
      &,
      &::before,
      &::after {
        cursor: pointer;
        border-radius: 1px;
        height: 2px;
        width: 24px;
        background: ${Color.Neutral_0_White};
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0) rotate(0);
        transform-origin: center center;
        display: block;
        content: '';
        transition: all 300ms ease-in-out;
      }
      &::before {
        transform: translate(-50%, -8px) rotate(0);
      }
      &::after {
        transform: translate(-50%, 8px) rotate(0);
      }
    }

    &.active {
      span {
        background: transparent;
        &::before {
          transform: translate(-50%, 0) rotate(45deg);
        }
        &::after {
          transform: translate(-50%, 0) rotate(-45deg);
        }
      }
    }
  }
`;
