import {
  CHECK_TYPE,
  CheckResultGeneric,
  GENERIC_RESULT_STATE,
  IndividualMultiTestPayload
} from '@solid/shared';
import { saveAsCSV } from '@solid/shared/services/data-grid.service';
import { Button } from '@solid/shared/ui';
import React from 'react';
import { Link } from 'react-router-dom';
import { CheckResultResponse } from '../../../services/check.service';
import Layout from '../../global/Layout';
import * as Styled from './CheckReportPageMultiTest.styles';

interface CheckReportPageMultiTestProps {
  checkId: string;
  context: 'personal' | 'org';
  check: CheckResultResponse;
}

const CheckReportPageMultiTest: React.FC<CheckReportPageMultiTestProps> = ({check, checkId, context}) => {
  const result = (check?.result as IndividualMultiTestPayload)?.results ?? [];

  const amountAddresses = result.length;
  const amountMatches = result.filter(r => r.hasMatches).length;
  const amountHits = result.filter(r => r.isHit).length;

  const getReportLink = (singleResult: IndividualMultiTestPayload['results'][number]) => {
    const link = new URL('', window.location.origin);
    if(singleResult.childId) {
      link.pathname = `/checks/${singleResult.childId}/report`;
    } else {
      const prefillData = {
        itemName: singleResult.name,
        result: {
          result: singleResult.isHit ? GENERIC_RESULT_STATE.FAILED : GENERIC_RESULT_STATE.SUCCESS,
          text: '',
        } as CheckResultGeneric,
        type: CHECK_TYPE.INDIVIDUAL_TEST,
        note: singleResult.note,
        questions: [
          { question: 'Name', value: singleResult.name },
          { question: 'Straße und Hausnr.', value: singleResult.street ? singleResult.street : '-' },
          { question: 'Stadt', value: singleResult.zipCode ? singleResult.zipCode : '-' },
          { question: 'PLZ', value: singleResult.city ? singleResult.city : '-' },
          { question: 'Land', value: singleResult.country ? singleResult.country : '-' },
        ],
        submittedAt: check.submittedAt,
      } satisfies CheckResultResponse;

      link.pathname = '/checks/prefill/report';
      link.searchParams.append('prefillData', window.btoa(JSON.stringify(prefillData)));
    }

    link.searchParams.append('context', context);
    link.searchParams.append('parentId', checkId);

    return link.toString();
  };

  const exportAsCSV = () => {
    const headersArray = [
      'Name',
      'Straße und Hausnummer / Street address',
      'Stadt / City',
      'PLZ / ZIP code',
      'Land / Country',
      'Ergebnis',
      'Kommentar',
    ];
    const rowsArray = result.map(r => [
      r.name,
      r.street ? r.street : '-',
      r.city ? r.city : '-',
      r.zipCode ? r.zipCode : '-',
      r.country ? r.country : '-',
      r.isHit ? 'Sanktioniert' : 'Kein Treffer',
      r.note ? r.note : '-',
    ]);
    saveAsCSV(headersArray, rowsArray, 'check-report');
  };

  return (
    <Layout>
      <div className='wrapper'>
        <Styled.Headline>Protokollübersicht</Styled.Headline>

        {check && (
          <Styled.StatisticContainer>
            <Styled.StatisticCard>
              <h4>Anzahl Prüfungen</h4>
              <Styled.StatisticCardValue>{amountAddresses}</Styled.StatisticCardValue>
            </Styled.StatisticCard>
            <Styled.StatisticCard>
              <h4>Anzahl Treffer</h4>
              <Styled.StatisticCardValue>{amountMatches}</Styled.StatisticCardValue>
            </Styled.StatisticCard>
            <Styled.StatisticCard>
              <h4>Sanktionierte Geschäftspartner</h4>
              <Styled.StatisticCardValue>{amountHits}</Styled.StatisticCardValue>
            </Styled.StatisticCard>
          </Styled.StatisticContainer>
        )}

        {check && (
          <Styled.ResultsTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>Ergebnis der Prüfung</th>
                <th>Kommentar</th>
                <th>Aktion</th>
              </tr>
            </thead>
            <tbody>
              {result.map((r, i) => (
                <tr key={i}>
                  <td>{r.name}</td>
                  <td>{r.isHit ? 'Sanktioniert' : 'Kein Treffer'}</td>
                  <td>{r.note ? r.note : '-'}</td>
                  <td>
                    <Link to={getReportLink(r)}>
                      <Button>Protokoll</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Styled.ResultsTable>
        )}

        <Styled.BtnWrapper>
          <Link to={context === 'org' ? '/org/checks' : '/checks'}>
            <Button themeColor='primary' variant='outlined'>
              Zum Prüfungsarchiv
            </Button>
          </Link>
          <Button onClick={() => exportAsCSV()}>CSV Export</Button>
        </Styled.BtnWrapper>
      </div>
    </Layout>
  );
};

export default CheckReportPageMultiTest;
