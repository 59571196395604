import { Outlet } from 'react-router-dom';
import Layout from '../global/Layout';

const TutorialsWrapperPage: React.FC = () => {
  return (
    <Layout showSidebar>
      <h1>Tutorials</h1>
      <Outlet />
    </Layout>
  );
};

export default TutorialsWrapperPage;
