import { Yup } from '@solid/shared/services/validation.service';
import { apiClient } from '@solid/shared/services/api.service';
import { User } from '@solid/shared';
import { RegisterSchema } from '@solid/shared/validation/auth';
import { omit } from 'lodash';

export enum USER_ROLE {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export async function loginUser(email: string, password: string, keepLoggedIn = false): Promise<User> {
  const response = await apiClient.post<{ user: User }>('/v1/auth/login', {
    email,
    password,
    keepLoggedIn: +keepLoggedIn,
  });
  return response.data.user;
}

export async function logoutUser() {
  await apiClient.post('/v1/auth/logout');
}

export async function requestEmailConfirmation(email: string) {
  await apiClient.post('/v1/auth/send-email-confirmation', { email });
}

export async function requestPasswordReset(email: string) {
  await apiClient.post('/v1/auth/send-password-reset', { email });
}

export async function resetPassword(newPassword: string, resetToken: string) {
  await apiClient.post('/v1/auth/reset-password', { password: newPassword, resetToken });
}

export async function register(values: Yup.InferType<typeof RegisterSchema>) {
  await apiClient.post(`/v1/auth/register`, { ...omit(values, 'confirmPassword') });
}

export async function confirmEmail(confirmToken: string) {
  return apiClient.post(`/v1/auth/email-confirmation`, { confirmToken });
}

export async function loginToForum(searchStr: string) {
  const searchParams = new URLSearchParams(searchStr);
  const res = await apiClient.post<{ url: string }>(`/v1/auth/forum/login`, {
    sso: searchParams.get('sso'),
    sig: searchParams.get('sig'),
  });

  return res.data.url;
}
