import { Color } from '../../styles/colors';
import styled from 'styled-components';
import { default as MdiIcon } from '@mdi/react';

export const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${Color.Neutral_600};
  text-align: center;
`;

export const Icon = styled(MdiIcon)`
  margin-bottom: 2rem;
`;
