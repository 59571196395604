import { Color } from '@solid/shared/styles/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;

  > h1 {
    margin-top: 0;
  }
`;

export const Result = styled.p`
  a {
    color: ${Color.Neutral_0_White};
    text-decoration: underline;
    &:hover {
      color: ${Color.Neutral_200_OffWhite};
    }
  }
  ul {
    display: inline-block;
    text-align: left;
  }
`;

export const NoteContainer = styled.div`
  max-width: 32rem;
  margin: 4rem auto;
`;
