import { Yup } from '../services/validation.service';

export const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().required('Bitte geben Sie einen Vornamen an'),
  lastName: Yup.string().required('Bitte geben Sie einen Nachnamen an'),
  email: Yup.string().email('Ungültige E-Mail-Adresse').required('Bitte geben Sie eine E-Mail-Adresse an'),
  password: Yup.string()
    .required('Bitte geben Sie ein Passwort an')
    .min(8, 'Bitte wählen Sie ein längeres Passwort (min. 8 Zeichen)')
    .max(128, 'Bitte wählen Sie ein kürzeres Passwort (max. 128 Zeichen)')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Bitte nutzen Sie mindestens 1 Sonderzeichen (@,!,#,& etc)'),
  confirmPassword: Yup.string().when('password', {
    is: val => (val && val.length > 0 ? true : false),
    then: () => Yup.string().oneOf([Yup.ref('password')], 'Beide Passwörter müssen übereinstimmen'),
  }),
  inviteToken: Yup.string(),
});
