import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { Yup } from '@solid/shared/services/validation.service';
import * as authService from '../../services/auth.service';
import styles from '../common/Forms.module.scss';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Ungültige E-Mail-Adresse').required('Bitte geben Sie eine E-Mail-Adresse an'),
});

const ForgotPasswordForm: React.FC = () => {
  const {
    mutate: requestPasswordReset,
    error,
    isLoading,
    isSuccess,
  } = useMutation<void, Error, string>(authService.requestPasswordReset);

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={values => requestPasswordReset(values.email)}
      validationSchema={ForgotPasswordSchema}>
      {({ isValid }) => (
        <Form>
          <div className='form-field'>
            <label htmlFor='forgot-password-email'>E-Mail-Adresse</label>
            <div className='control'>
              <Field type='email' name='email' id='forgot-password-email' autoComplete='email' required />
            </div>
            <ErrorMessage component='p' className='help is-danger' name='email' />
          </div>

          <div className='form-field'>
            {error && <div className='notification is-error'>{error.message}</div>}
            {isSuccess && (
              <div className='notification is-success'>
                Wir haben Ihnen soeben eine E-Mail zugesandt. Bitte klicken Sie auf den Link in der E-Mail, um Ihr
                aktuelles Passwort zu ändern.
                <br />
                Falls Sie keine E-Mail erhalten haben, sehen Sie bitte auch in Ihrem Spam-Ordner nach.
              </div>
            )}
          </div>

          <div className='form-field'>
            <button
              className={classNames('btn is-primary', styles.submitBtn, { 'is-loading': isLoading })}
              type='submit'
              disabled={isLoading || !isValid}>
              Zurücksetzung anfordern
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
