import { Color } from '@solid/shared/styles/colors';
import styled from 'styled-components';

export const Headline = styled.h1`
  text-align: center;
`;

export const StatisticContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin: 2rem 0 3rem;
`;

export const StatisticCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: ${Color.Neutral_0_White};
  color: ${Color.Neutral_900};
  border-radius: 0.5rem;

  > h4 {
    margin-bottom: 0;
    font-weight: ${props => props.theme.fontWeight.bold};
  }
`;

export const StatisticCardValue = styled.div`
  font-size: 2.5rem;
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 3rem;
`;

export const ResultsTable = styled.table`
  width: 100%;
  margin: 1.5rem 0 3rem;

  th:last-child, td:last-child {
    width: 120px;
  }
`;
