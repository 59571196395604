import { Tab, TabsContainer } from '@solid/shared/ui/Tabs.styles';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth.context';
import { useOrganizationAbility } from '../../hooks/useOrganizationAbility';
import { isStandaloneAccount } from '../../services/user.service';
import Layout from '../global/Layout';
import * as Styled from './SettingsWrapperPage.styles';

const SettingsWrapperPage: React.FC = () => {
  const ability = useOrganizationAbility();
  const { auth } = useContext(AuthContext);

  return (
    <Layout showSidebar>
      <h1>Mein Konto</h1>

      <TabsContainer>
        <Tab to='/settings/account'>Kontoinformation</Tab>
        {(isStandaloneAccount(auth) || ability.can('read', 'Organization', 'billing')) && (
          <Tab to='/settings/billing'>Rechnungen</Tab>
        )}
      </TabsContainer>

      <Styled.ContentWrapper>
        <Outlet />
      </Styled.ContentWrapper>
    </Layout>
  );
};

export default SettingsWrapperPage;
