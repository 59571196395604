import { Button, StyledButton } from '@solid/shared/ui';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useContext } from 'react';
import { Yup } from '@solid/shared/services/validation.service';
import { AuthContext } from '../../contexts/auth.context';
import * as userService from '../../services/user.service';

const PasswordForm: React.FC = () => {
  const { auth } = useContext(AuthContext);

  const PasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Bitte Passwort angeben')
      .min(8, 'Bitte wählen Sie ein längeres Passwort (min. 8 Zeichen)')
      .max(128, 'Bitte wählen Sie ein kürzeres Passwort (max. 128 Zeichen)')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Bitte nutzen Sie mindestens 1 Sonderzeichen (@,!,#,& etc)'),
    confirmPassword: Yup.string().when('password', {
      is: val => (val && val.length > 0 ? true : false),
      then: () => Yup.string().oneOf([Yup.ref('password')], 'Beide Passwörter müssen übereinstimmen'),
    }),
  });

  const {
    mutate: updateUser,
    error,
    isLoading,
    isSuccess,
  } = useMutation<void, Error, Yup.InferType<typeof PasswordSchema>>(data =>
    userService.updateUser(auth.user.id, { password: data.password })
  );

  return (
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      onSubmit={values => updateUser(values)}
      validationSchema={PasswordSchema}>
      {({ isValid }) => (
        <Form id='account-data-password-form'>
          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='account-data-password'>Passwort (mind. 8 Zeichen)</label>
              <div className='control'>
                <Field
                  type='password'
                  name='password'
                  id='account-data-password'
                  autoComplete='new-password'
                  required
                />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='password' />
            </div>
            <div className='form-field'>
              <label htmlFor='account-data-password-repeat'>Passwort wdh.</label>
              <div className='control'>
                <Field
                  type='password'
                  name='confirmPassword'
                  id='account-data-password-repeat'
                  autoComplete='new-password'
                  required
                />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='confirmPassword' />
            </div>
          </div>

          {error && (
            <div className='form-field'>
              <div className='notification is-error'>{error.message}</div>
            </div>
          )}
          {isSuccess && (
            <div className='form-field'>
              <div className='notification is-success'>Erfolgreich gespeichert</div>
            </div>
          )}

          <StyledButton.ButtonsContainer>
            <Button themeColor='primary' type='submit' isLoading={isLoading} disabled={isLoading || !isValid}>
              Passwort speichern
            </Button>
          </StyledButton.ButtonsContainer>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordForm;
