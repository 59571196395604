import { LoadingSpinner } from '@solid/shared/ui';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth.context';
import * as Styled from './LoggedXRoute.styles';

const LoggedInRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { auth } = useContext(AuthContext);

  if (auth === undefined) {
    // User is not yet loaded
    return (
      <div className='wrapper'>
        <Styled.Container>
          <LoadingSpinner />
        </Styled.Container>
      </div>
    );
  }
  if (auth === null) {
    // User is not logged in
    return (
      <Navigate
        to={{
          pathname: '/login',
          search: `redirect=${encodeURIComponent(window.location.pathname)}${encodeURIComponent(
            window.location.search
          )}`,
        }}
      />
    );
  } else {
    // User is logged in
    return children;
  }
};

export default LoggedInRoute;
