import React, { HTMLProps } from 'react';
import * as Styled from './DataCard.styles';
import { LoadingSpinner } from '../../ui';

interface DataCardProps {
  title: string;
  isLoading?: boolean;
  minHeight?: number;
}

const DataCard: React.FC<DataCardProps & HTMLProps<HTMLDivElement>> = ({
  title,
  isLoading = false,
  minHeight = null,
  children,
  className,
}) => {
  return (
    <Styled.Card className={className} $minHeight={minHeight}>
      <Styled.Title>{title}</Styled.Title>
      {isLoading ? (
        <Styled.LoadingSpinnerContainer>
          <LoadingSpinner themeColor='primary' />
        </Styled.LoadingSpinnerContainer>
      ) : (
        children
      )}
    </Styled.Card>
  );
};

export default DataCard;
