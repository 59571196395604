import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import { ReactComponent as LinkedInLogo } from '../../assets/images/icons/linkedin.svg';
import { useKirbyCheckGroups, useKirbyInfoGeneral } from '../../hooks/useKirbyData';
import * as Styled from './Footer.styles';
import { AuthContext } from '../../contexts/auth.context';

const Footer: React.FC = () => {
  const { data: checkGroups } = useKirbyCheckGroups();
  const { data: info } = useKirbyInfoGeneral();
  const { auth } = useContext(AuthContext);
  const userIsLoggedOut = auth === null;

  return (
    <Styled.Footer>
      <Styled.FooterWrapper>
        <Styled.SmallWrapper>
          <Styled.AdjustedLogo />
        </Styled.SmallWrapper>
        <Styled.Line />
        <Styled.SmallWrapper>
          <Styled.Topics>
            <Styled.Col>
              <h5>Prüfungen</h5>
              {(checkGroups ?? []).map((group, i) => (
                <React.Fragment key={group.name}>
                  {i !== 0 && <br />}
                  {userIsLoggedOut ? (
                    <a href={`${environment.kirbyBaseUrl}/#${group.slug}`} target='_blank' rel='noreferrer'>
                      {group.name}
                    </a>
                  ) : (
                    <NavLink to={{ pathname: '/', hash: group.slug }}>{group.name}</NavLink>
                  )}
                </React.Fragment>
              ))}
            </Styled.Col>
            <Styled.Col>
              <h5>Rechtliches</h5>
              <a href={`${environment.kirbyBaseUrl}/legal`} target='_blank' rel='noopener noreferrer'>
                Impressum
              </a>
              <br />
              <a href='https://www.aeb.com/de-de/datenschutz.php' target='_blank' rel='noopener noreferrer'>
                Datenschutz
              </a>
              <br />
              <a href={`${environment.kirbyBaseUrl}/terms-of-use/free`} target='_blank' rel='noopener noreferrer'>
                Nutzungsbedingungen
              </a>
            </Styled.Col>
            {info && (
              <Styled.Col $type='aeb'>
                <h5>
                  <a href='https://www.aeb.com/de-de/' target='_blank' rel='noopener noreferrer'>
                    {info.title}
                  </a>
                </h5>
                <p>
                  {info.address.street}
                  <br />
                  {info.address.city}
                </p>
                <p>
                  E-Mail: <a href={`mailto:${info.email}`}>{info.email}</a>
                </p>
                <p>
                  <Styled.SocialLink href={info.social.linkedin} target='_blank' rel='noreferrer'>
                    <LinkedInLogo />
                  </Styled.SocialLink>
                </p>
              </Styled.Col>
            )}
          </Styled.Topics>
        </Styled.SmallWrapper>
      </Styled.FooterWrapper>
    </Styled.Footer>
  );
};

export default Footer;
