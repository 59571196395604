import { useContext } from 'react';
import { AuthContext } from '../contexts/auth.context';
import { defineAbsolvedCheckAbilitiesFor } from '@solid/shared/permissions/absolved-check.permission';
import { OrganizationMember } from '@solid/shared';

export const useAbsolvedCheckAbility = (checkUserOrgMember?: OrganizationMember) => {
  const { auth } = useContext(AuthContext);

  return defineAbsolvedCheckAbilitiesFor({
    user: auth.user,
    org: auth.orgMember
      ? { reqOrgMember: auth.orgMember, checkUserOrgMember: checkUserOrgMember ?? auth.orgMember }
      : null,
  });
};
