import { CHECK_TYPE, CheckResultDualUse, CheckResultGeneric } from '@solid/shared';
import { LoadingSpinner } from '@solid/shared/ui';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import React from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { getCheckResult } from '../../services/check.service';
import OnlyPaidLinkButton from '../common/OnlyPaidLinkButton';
import CheckResultPageDualUse from './CheckResultDualUse';
import CheckResultPageGeneric from './CheckResultGenericPage';
import styles from './CheckResultPage.module.scss';
import Layout from '../global/Layout';

const CheckResultPage: React.FC = () => {
  const { checkId } = useParams<{ checkId: string }>();

  const {
    data: checkResult,
    isLoading,
    error,
  } = useQuery(['checkResult', checkId], () => getCheckResult(checkId), {
    enabled: !!checkId,
    staleTime: 0,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return (
    <Layout>
      <div className='wrapper'>
        {isLoading && (
          <div className={styles.center}>
            <LoadingSpinner />
          </div>
        )}

        {error && <div className={classNames('notification is-error', styles.center)}>{(error as Error).message}</div>}

        {checkResult &&
          [
            CHECK_TYPE.US_RE,
            CHECK_TYPE.CATCH_ALL,
            CHECK_TYPE.CHINA,
            CHECK_TYPE.LIST_SELECTION,
            CHECK_TYPE.MATCH_EVALUATION,
            CHECK_TYPE.END_USE_CERTIFICATE,
            CHECK_TYPE.EMBARGO_CHECK,
            CHECK_TYPE.US_RE_PERSON,
            CHECK_TYPE.US_RE_PRODUCT,
            CHECK_TYPE.US_RE_DE_MINIMIS,
            CHECK_TYPE.US_RE_FDP_RULES,
            CHECK_TYPE.SOLID_NLR,
            CHECK_TYPE.SUPPLY_CHAIN_ACT,
          ].includes(checkResult.type) && (
            <CheckResultPageGeneric result={checkResult.result as CheckResultGeneric} note={checkResult.note} />
          )}
        {checkResult && checkResult.type === CHECK_TYPE.DUAL_USE && (
          <CheckResultPageDualUse
            result={checkResult.result as CheckResultDualUse}
            checkId={checkId}
            note={checkResult.note}
          />
        )}
        {checkResult && checkResult.type === CHECK_TYPE.INDIVIDUAL_TEST && (
          <Navigate to={`/checks/${checkId}/report`} />
        )}

        <div className={styles.btnWrapper}>
          <Link className={classNames('btn is-primary', styles.btn)} to='/'>
            Zurück zu den Prüfungen
          </Link>
          <OnlyPaidLinkButton to={`/checks/${checkId}/report`}>Protokoll anzeigen</OnlyPaidLinkButton>
          <OnlyPaidLinkButton to={`/checks/${checkId}/report?print`}>
            Protokoll drucken / als PDF speichern
          </OnlyPaidLinkButton>
        </div>
      </div>
    </Layout>
  );
};

export default CheckResultPage;
