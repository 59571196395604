import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getUserProfile } from '../services/user.service';
import { AuthContext, AuthState } from '../contexts/auth.context';

const UserContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [auth, setAuth] = useState<AuthState>(undefined);

  // Load current user upon load
  const { refetch } = useQuery(['user'], getUserProfile, {
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: user => {
      if (!user) {
        setAuth(null);
      } else {
        setAuth({
          user: user,
          org: user.orgMember?.organization ?? null,
          orgMember: user.orgMember ?? null,
        });
      }

      // Automatically set user context for Sentry
      if (!user) {
        Sentry.setUser(null);
      } else {
        Sentry.setUser({
          email: user.email,
          id: user.id.toString(),
          username: `${user.firstName} ${user.lastName}`,
        });
      }
    },
  });

  return <AuthContext.Provider value={{ auth, refetchAuthState: refetch }}>{children}</AuthContext.Provider>;
};

export default UserContainer;
