import { mdiAlert, mdiAlertOctagon, mdiCheckCircle, mdiInformation } from '@mdi/js';
import React, { HTMLProps } from 'react';
import * as Styled from './NotificationMessage.styles';

export interface NotificationMessageProps {
  state: 'info' | 'success' | 'warning' | 'danger';
  hasBrighterText?: boolean;
}

const NotificationMessage: React.FC<NotificationMessageProps & HTMLProps<HTMLDivElement>> = ({
  state,
  hasBrighterText = false,
  ...props
}) => {
  return (
    <Styled.Container $state={state} $hasBrighterText={hasBrighterText} {...props}>
      <Styled.AdjustedIcon
        path={
          state === 'info'
            ? mdiInformation
            : state === 'success'
            ? mdiCheckCircle
            : state === 'warning'
            ? mdiAlert
            : mdiAlertOctagon
        }
        size='20px'
      />
      {props.children}
    </Styled.Container>
  );
};

export default NotificationMessage;
