import { default as MdiIcon } from '@mdi/react';
import { Color } from '@solid/shared/styles/colors';
import styled, { css } from 'styled-components';

export const Badge = styled.span<{ $isLocked: boolean }>`
  display: inline-flex;
  align-items: center;
  height: 1.375rem;
  padding: 0 0.5rem;
  border: 1px solid ${Color.Blue_500_Primary};
  border-radius: 99px;
  font-size: 0.75rem;
  color: ${Color.Blue_500_Primary};
  font-weight: ${props => props.theme.fontWeight.medium};

  ${props =>
    props.$isLocked &&
    css`
      background-color: ${Color.Blue_500_Primary};
      color: ${Color.Neutral_0_White};
    `}
`;

export const Text = styled.span`
  line-height: 0;
`;

export const Icon = styled(MdiIcon)<{ $isLocked: boolean }>`
  margin-left: 4px;
  ${props =>
    props.$isLocked &&
    css`
      color: ${Color.Neutral_0_White};
    `}
`;
