import styled from 'styled-components';
import { StyledButton } from '@solid/shared/ui';
import { Form as FormikForm } from 'formik';
import { Color } from '@solid/shared/styles/colors';

export const Form = styled(FormikForm)<{ $color: string }>`
  .control {
    input,
    select,
    textarea {
      color: ${props => props.$color};
    }

    /* //TODO this should be fixed globally */
    select option {
      color: ${Color.Blue_1000_Deep};
    }
  }
`;

export const DifferentBillingAddress = styled.div`
  margin-bottom: 0 !important;
`;

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${StyledButton.Button}:only-child {
    margin-left: auto;
  }
`;
