import { endOfDay, format, isValid, parse } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { DATE_RANGE_PRESET, DateRangeType } from '../../services/analytics.service';
import * as Styled from './DateRangeSelection.styles';

interface DateRangeSelectionProps {
  startDate: Date | null;
  endDate: Date;
  onChange: ({ startDate, endDate }: { startDate: Date | null; endDate: Date }) => void;
  rootMargin: number;
}

const INPUT_DATE_FORMAT = 'yyyy-MM-dd';

const DateRangeSelection: React.FC<DateRangeSelectionProps> = ({ startDate, endDate, onChange, rootMargin }) => {
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(([e]) => setIsSticky(e.intersectionRatio < 1), {
      threshold: [1],
      rootMargin: `-${rootMargin}px 0px 0px 0px`,
    });
    observer.observe(cachedRef);
    return () => observer.unobserve(cachedRef);
  }, [ref]);

  return (
    <Styled.Container $rootMargin={rootMargin} ref={ref}>
      <Styled.DateRangeContainer $isSticky={isSticky}>
        <div>
          {Object.keys(DATE_RANGE_PRESET).map(id => {
            const range = DATE_RANGE_PRESET[id as keyof DateRangeType];
            return (
              <Styled.SelectionButton
                key={range.id}
                $isActive={
                  startDate?.toString() === range.dateRange.startDate?.toString() &&
                  endDate.toString() === range.dateRange.endDate.toString()
                }
                onClick={() => {
                  onChange({
                    startDate: range.dateRange.startDate,
                    endDate: range.dateRange.endDate,
                  });
                }}>
                {range.name}
              </Styled.SelectionButton>
            );
          })}
        </div>
        <Styled.DateRangePickerContainer>
          <input
            type='date'
            value={isValid(startDate) ? format(startDate ?? new Date(0), INPUT_DATE_FORMAT) : ''}
            onChange={e => {
              const parsed =
                e.currentTarget.value === '' ? null : parse(e.currentTarget.value, INPUT_DATE_FORMAT, new Date());
              onChange({ startDate: parsed, endDate });
            }}
          />
          <span>bis</span>
          <input
            type='date'
            value={isValid(endDate) ? format(endDate ?? new Date(), INPUT_DATE_FORMAT) : ''}
            onChange={e => {
              const parsed = parse(e.currentTarget.value, INPUT_DATE_FORMAT, new Date());
              onChange({ endDate: endOfDay(isValid(parsed) ? parsed : new Date()), startDate });
            }}
          />
        </Styled.DateRangePickerContainer>
      </Styled.DateRangeContainer>
    </Styled.Container>
  );
};

export default DateRangeSelection;
