import React from 'react';
import { Link } from 'react-router-dom';
import singlePageStyles from '../common/SinglePage.module.scss';
import classNames from 'classnames';
import Layout from '../global/Layout';
import { Button } from '@solid/shared/ui';

const ConfirmEmailPage: React.FC = () => {
  return (
    <Layout>
      <div className={classNames('wrapper', singlePageStyles.wrapper)}>
        <h1>E-Mail-Adresse bestätigen</h1>
        <p>
          Ihnen wurde soeben eine E-Mail mit Bestätigungslink zugesandt. Bitte bestätigen Sie Ihre E-Mail-Adresse mit
          einem Klick auf den mitgeschickten Link. Prüfen Sie hierbei bitte auch ihren Spam-Ordner.
        </p>
        <Link to='/login'>
          <Button>Zum Login</Button>
        </Link>
      </div>
    </Layout>
  );
};

export default ConfirmEmailPage;
