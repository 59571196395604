import styled from 'styled-components';
import { Color } from '@solid/shared/styles/colors';

export const Headline = styled.h1`
  font-size: ${props => props.theme.fontSize.h2};
  margin-top: 0;

  span {
    font-weight: ${props => props.theme.fontWeight.semibold};
    color: ${Color.Blue_500_Primary};
  }
`;

export const SubHeadline = styled.h4`
  margin: 3rem 0 1.5em;
`;

export const FavoritesEmptyState = styled.div`
  display: block;
  text-align: center;
  padding: 1.5rem 0;

  svg {
    color: ${Color.Blue_500_Primary};
    vertical-align: middle;
    margin-bottom: 2px;
  }
`;

export const FeedbackContainer = styled.div`
  margin-top: 5rem;

  @media (${props => props.theme.breakpoint.md.up}) {
    margin-top: 8rem;
  }
`;
