import {
  mdiAccountOutline,
  mdiArchiveSearchOutline,
  mdiDomain,
  mdiEmailOutline,
  mdiForumOutline,
  mdiInformationOutline,
  mdiMessageTextOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { throttle } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import { ReactComponent as MenuChecksIcon } from '../../assets/images/icons/menu-checks.svg';
import { ReactComponent as MenuCheckupIcon } from '../../assets/images/icons/menu-checkup.svg';
import { ReactComponent as MenuSeminarsIcon } from '../../assets/images/icons/menu-seminars.svg';
import { AuthContext } from '../../contexts/auth.context';
import { useKirbyChecks } from '../../hooks/useKirbyData';
import { useOrganizationAbility } from '../../hooks/useOrganizationAbility';
import * as Styled from './Sidebar.styles';

const Sidebar: React.FC = () => {
  const { auth } = useContext(AuthContext);
  const org = auth.org;
  const { data: checks } = useKirbyChecks();
  const location = useLocation();

  const ability = useOrganizationAbility();

  const wrapperEl = useRef<HTMLDivElement>(null);
  const topEl = useRef<HTMLDivElement>(null);
  const [topMargin, setTopMargin] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);
  const isOnSeminarPage = useMatch('/seminars/free');
  const isOnTutorialPage = useMatch('/tutorials/*');
  const tutorialSubMenuVisible = isOnSeminarPage || isOnTutorialPage;

  useEffect(() => {
    const handler = throttle(() => {
      if (window.innerWidth < 768) {
        // Nothing to do on mobile
        return;
      }
      if (!wrapperEl.current) {
        return;
      }
      const offsetY = wrapperEl.current.getBoundingClientRect().y;
      if (offsetY > 0) {
        // If the offset is negative, that means it's no longer sticky
        setTopMargin(offsetY);
      }
      setIsScrollable(topEl.current.scrollHeight > topEl.current.clientHeight);
    }, 0);

    window.addEventListener('scroll', handler, { passive: true, capture: true });
    window.addEventListener('resize', handler, { passive: true, capture: true });
    return () => {
      window.removeEventListener('scroll', handler);
      window.removeEventListener('resize', handler);
    };
  }, []);

  return (
    <Styled.Sidebar ref={wrapperEl} style={{ maxHeight: `calc(100vh - ${topMargin}px)` }}>
      <Styled.Top ref={topEl}>
        <Styled.Item to='/'>
          <MenuCheckupIcon />
          Die Prüfungen
        </Styled.Item>

        <Styled.Item to='/checks'>
          <MenuChecksIcon />
          Mein Prüfungsarchiv
        </Styled.Item>

        {org && (
          <>
            {ability.can('read', 'Organization', 'checks') && (
              <Styled.Item to='/org/checks'>
                <Icon path={mdiArchiveSearchOutline} size='20px' />
                Unternehmensarchiv
              </Styled.Item>
            )}

            {ability.can('read', 'Organization', 'analytics') && (
              <Styled.Item to='/org/analytics' className={location.pathname === '/org/users' ? 'active' : undefined}>
                <Icon path={mdiDomain} size='20px' />
                Unternehmens-Dashboard
              </Styled.Item>
            )}
          </>
        )}

        <Styled.Item to='/settings'>
          <Icon path={mdiAccountOutline} size='20px' />
          Mein Konto
        </Styled.Item>

        <Styled.Item to='/tutorials'>
          <Icon path={mdiInformationOutline} size='20px' />
          Tutorials
        </Styled.Item>
        {tutorialSubMenuVisible && (
          <>
            <Styled.Item $isSubItem to='/seminars/free'>
              Online-Demo
            </Styled.Item>
            {(checks ?? []).map(check => (
              <Styled.Item $isSubItem key={check.slug} to={`/tutorials/${check.slug}`}>
                {check.title}
              </Styled.Item>
            ))}
          </>
        )}

        <Styled.Item to='/seminars/regular'>
          <MenuSeminarsIcon />
          Seminare
        </Styled.Item>
      </Styled.Top>
      <Styled.Bottom $isScrollable={isScrollable}>
        <Styled.Item to='https://aebcom.typeform.com/to/iXIE9y9d' target='_blank' rel='noopener noreferrer'>
          <Icon path={mdiMessageTextOutline} size='20px' />
          Feedback geben
        </Styled.Item>
        <Styled.Item to={environment.discourse.baseUrl}>
          <Icon path={mdiForumOutline} size='20px' />
          Forum
          <Styled.Badge className='badge'>NEU</Styled.Badge>
        </Styled.Item>
        <Styled.Item to='mailto:info@solid-app.de'>
          <Icon path={mdiEmailOutline} size='20px' />
          Kontakt
        </Styled.Item>
      </Styled.Bottom>
    </Styled.Sidebar>
  );
};

export default Sidebar;
