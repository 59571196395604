import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import Footer from './components/navigation/Footer';
import Navbar from './components/navigation/Navbar';
import Tracking from './components/global/Tracking';
import Toaster from '@solid/shared/toasts/Toaster';
import PaymentPopup from './components/payment-popup/PaymentPopup';

export const App: React.FC = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />

      <ScrollRestoration />
      <Tracking />
      <Toaster />
      <PaymentPopup />
    </>
  );
};

export default App;
