import { apiClient, kirbyApiClient } from '@solid/shared/services/api.service';
import { getLocalStorageKey, setLocalStorageItem } from './local-storage.service';
import { KirbyCheckGroup, KirbyInfoGeneral, KirbyInfoPlus, KirbyCheck } from '@solid/shared/kirby';

export enum KIRBY_CACHE_KEY {
  CHECK_GROUPS = 'CHECK_GROUPS',
  INFO_GENERAL = 'INFO_GENERAL',
  INFO_PLUS = 'INFO_PLUS',
  CHECKS = 'CHECKS',
}

type KirbyData = KirbyCheckGroup[] | KirbyInfoGeneral | KirbyInfoPlus | KirbyCheck[];

/**
 * Local cache that persist the latest data and can be used to prevent site flashing, as this API's content rarely changes.
 */
const getCacheKey = (cacheKey: KIRBY_CACHE_KEY) => `_KIRBY_DATA__${cacheKey}`;

export function getKirbyPlaceholderDataFromCache(cacheKey: KIRBY_CACHE_KEY): KirbyData {
  const entry = getLocalStorageKey(getCacheKey(cacheKey));
  if (entry) {
    try {
      return JSON.parse(entry);
    } catch {
      return undefined;
    }
  }
}
export async function getKirbyChecks(): Promise<KirbyCheck[]> {
  const res = await apiClient.get<{ data: KirbyCheck[] }>('/v1/checks/info');
  const { data } = res.data;
  setLocalStorageItem(getCacheKey(KIRBY_CACHE_KEY.CHECKS), JSON.stringify(data));
  return data;
}

export async function getKirbyInfoPlus(): Promise<KirbyInfoPlus> {
  const res = await kirbyApiClient.get<{ data: KirbyInfoPlus }>('/api/solid/info/plus');
  const { data } = res.data;
  setLocalStorageItem(getCacheKey(KIRBY_CACHE_KEY.INFO_PLUS), JSON.stringify(data));
  return data;
}

export async function getKirbyInfoGeneral(): Promise<KirbyInfoGeneral> {
  const res = await kirbyApiClient.get<{ data: KirbyInfoGeneral }>('/api/solid/info/general');
  const { data } = res.data;
  setLocalStorageItem(getCacheKey(KIRBY_CACHE_KEY.INFO_GENERAL), JSON.stringify(data));
  return data;
}

export async function getKirbyCheckGroups(): Promise<KirbyCheckGroup[]> {
  const res = await kirbyApiClient.get<{ data: KirbyCheckGroup[] }>('/api/solid/check-groups');
  const { data } = res.data;
  setLocalStorageItem(getCacheKey(KIRBY_CACHE_KEY.CHECK_GROUPS), JSON.stringify(data));
  return data;
}
