import { Color } from '../../styles/colors';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ $rootMargin: number }>`
  position: sticky;
  top: ${props =>
    props.$rootMargin - 1}px; // Has to be one pixel less than the IntersectionObserver uses as rootMargin "top"
  padding: 0.5rem 0;
  z-index: 2;
`;

export const DateRangeContainer = styled.div<{ $isSticky: boolean }>`
  display: flex;
  justify-content: space-between;
  background-color: ${Color.Neutral_0_White};
  border: 1px solid ${Color.Neutral_300};
  border-radius: ${props => props.theme.borderRadius.small};
  overflow: hidden;
  transition: box-shadow 0.25s ease;

  ${props =>
    props.$isSticky &&
    css`
      box-shadow: 0 12px 17px 0 ${transparentize(0.85, Color.Blue_1000_Deep)};
    `}
`;

export const SelectionButton = styled.button<{ $isActive: boolean }>`
  padding: 12px 20px;
  background-color: ${props => (props.$isActive ? Color.Neutral_200_OffWhite : Color.Neutral_0_White)};
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.25s ease;

  &:hover {
    background-color: ${Color.Neutral_200_OffWhite};
  }
`;

export const DateRangePickerContainer = styled.div`
  margin-left: 1.5rem;
  background-color: ${Color.Neutral_0_White};
  border-left: 1px solid ${Color.Neutral_200_OffWhite};
  vertical-align: middle;

  span {
    color: ${props => props.theme.medium};
    margin: 0 0.5rem;
  }

  input[type='date'] {
    padding: 0.25rem 0.5rem;
    outline: none;
    border: none;
    border-width: 0 1px;
    height: 100%;
    background-color: ${Color.Neutral_0_White};
    transition: background-color ${props => props.theme.transitionTiming};

    &:hover {
      background-color: ${Color.Neutral_200_OffWhite};
    }
  }
`;
