import { mdiLock } from '@mdi/js';
import { isPaidPlan, PLAN, PLAN_NAME } from '@solid/shared';
import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/auth.context';
import * as Styled from './PlanBadge.styles';

interface PlanBadgeProps {
  plan: PLAN;
  className?: string;
}

const PlanBadge: React.FC<PlanBadgeProps> = ({ plan, className }) => {
  const { auth } = useContext(AuthContext);

  const locked = auth.user.plan === PLAN.FREE && plan !== PLAN.FREE;

  return (
    <Styled.Badge $isLocked={locked} className={className}>
      <Styled.Text>{PLAN_NAME[plan]}</Styled.Text>
      {isPaidPlan(plan) && <Styled.Icon path={mdiLock} $isLocked={locked} size='12px' />}
    </Styled.Badge>
  );
};

export default PlanBadge;
