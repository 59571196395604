import { AbilityBuilder, InferSubjects, PureAbility, createMongoAbility } from '@casl/ability';
import { Organization, USER_ROLE, User } from '../..';
import { ORGANIZATION_ROLE, OrganizationMember } from '../organization';

export type OrganizationActions = 'manage' | 'read' | 'update' | 'delete';
export type OrganizationSubjects = InferSubjects<Organization | 'Organization'>;

export function defineOrganizationAbilitiesFor({
  user,
  targetOrganization,
  organizationMember,
}: {
  user: User;
  targetOrganization: Organization;
  organizationMember: OrganizationMember;
}) {
  const { can, build } = new AbilityBuilder<PureAbility<[OrganizationActions, OrganizationSubjects]>>(
    createMongoAbility
  );

  if (user.role === USER_ROLE.ADMIN) {
    // Admins can do anything
    can('manage', 'Organization');
  } else {
    if (organizationMember?.organizationId === targetOrganization?.id) {
      if (organizationMember?.role === ORGANIZATION_ROLE.MANAGER) {
        can('read', 'Organization', 'checks');
      }
      if (organizationMember?.role === ORGANIZATION_ROLE.LOCAL_ADMIN) {
        can('update', 'Organization');
        can('read', 'Organization', ['checks', 'analytics', 'members', 'billing']);
      }
    }
  }

  return build();
}
