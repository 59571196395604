import { CheckResultGeneric, GENERIC_RESULT_STATE } from '@solid/shared';
import React from 'react';
import { ReactComponent as IconInfo } from '../../assets/images/icons/checkup-info.svg';
import { ReactComponent as IconProhibition } from '../../assets/images/icons/checkup-prohibition.svg';
import { ReactComponent as IconSuccess } from '../../assets/images/icons/checkup-success.svg';
import { ReactComponent as IconWarning } from '../../assets/images/icons/checkup-warning.svg';
import CheckNoteForm from './CheckNoteForm';
import * as Styled from './CheckResultGenericPage.styles';

interface CheckResultGenericProps {
  result: CheckResultGeneric;
  note: string;
}

const CheckResultGenericPage: React.FC<CheckResultGenericProps> = ({ result, note }) => {
  return (
    <Styled.Wrapper>
      {result.result === GENERIC_RESULT_STATE.SUCCESS && <IconSuccess />}
      {result.result === GENERIC_RESULT_STATE.FAILED && <IconWarning />}
      {result.result === GENERIC_RESULT_STATE.INFO && <IconInfo />}
      {result.result === GENERIC_RESULT_STATE.PROHIBITION && <IconProhibition />}

      <h1>Ergebnis Ihrer Prüfung</h1>

      <Styled.Result dangerouslySetInnerHTML={{ __html: result.text }}></Styled.Result>

      <Styled.NoteContainer>
        <CheckNoteForm note={note} />
      </Styled.NoteContainer>
    </Styled.Wrapper>
  );
};

export default CheckResultGenericPage;
