import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';

const currentProgress = new BehaviorSubject<number>(0);
export const progress = currentProgress.asObservable();

interface APIErrorProps {
  message: string; // Error message.
  status: number; // HTTP status code of error.
}

export class ApiError extends Error implements APIErrorProps {
  status: number;
  message: string;

  constructor({ status, message }: APIErrorProps) {
    super(message);
    this.status = status || 500;
    this.message = message;
  }
}

const apiClient = axios.create({
  withCredentials: true,
  baseURL: environment.apiBaseUrl,
  transformResponse: [],
  onDownloadProgress: event => {
    if (event.loaded > 0 && event.total === undefined) {
      // TODO this is not properly working, as the `Content-Length` header of the server is being omitted, so we simply set it to 1 upon first event where `loaded` > 0
      return currentProgress.next(1);
    }
    currentProgress.next(event.loaded / event.total);
  },
});

apiClient.interceptors.request.use(req => {
  currentProgress.next(0.05); // Show a bit of progress for UX
  return req;
}, console.error);

apiClient.interceptors.response.use(
  res => {
    if (!res.data) {
      return res;
    }
    // Parse JSON and parse date strings
    res.data = JSON.parse(res.data, (_, value) => {
      if (
        typeof value === 'string' &&
        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/.test(value)
      ) {
        return new Date(value);
      }
      return value;
    });
    return res;
  },
  function (e) {
    let error: Error = e;
    try {
      error = new ApiError(JSON.parse(e?.response?.data).error);
    } catch (parseErr) {
      console.error('Failed to parse server response');
      console.error(parseErr);
    }
    console.error(error);
    return Promise.reject(error);
  }
);

const kirbyApiClient = axios.create({ baseURL: environment.kirbyBaseUrl });

export { apiClient, kirbyApiClient };
