import { Yup } from '../services/validation.service';
import { ORGANIZATION_ROLE } from '../organization';
import { allowedCountries } from '../user';
import validator from 'validator';

export const ORGANIZATION_MAX_SEATS = 9999;

export const UpsertOrganization = Yup.object({
  maxSeats: Yup.number().min(1).max(ORGANIZATION_MAX_SEATS).required(),
  displayName: Yup.string().required(),
  companyName: Yup.string().required(),
  companyStreet: Yup.string().required(),
  companyZipCode: Yup.string().required(),
  companyCity: Yup.string().required(),
  companyCountry: Yup.string().oneOf([...allowedCountries, '']),
  companyVatId: Yup.string().required(),
  companyEmail: Yup.string().email().required(),
  companyBillingName: Yup.string().required(),
  companyBillingStreet: Yup.string().required(),
  companyBillingZipCode: Yup.string().required(),
  companyBillingCity: Yup.string().required(),
  companyBillingCountry: Yup.string().oneOf([...allowedCountries, '']),
});

export const CreateOrganizationMultiInvite = Yup.object({
  emails: Yup.string()
    .required()
    .test(function (value: string) {
      const emails = value
        .split('\n')
        .map(email => email.trim())
        .filter(email => email.length > 0);
      for (const email of emails) {
        if (!validator.isEmail(email)) {
          return this.createError({ message: `"${email}" ist eine ungültige E-Mail-Adresse` });
        }
      }
      return true;
    }),
  role: Yup.string().oneOf(Object.values(ORGANIZATION_ROLE)).required(),
});

export const CreateOrganizationInvite = Yup.object({
  email: Yup.string().email().required(),
  role: Yup.string().oneOf(Object.values(ORGANIZATION_ROLE)).required(),
});

export const UpdateOrganizationMember = Yup.object({
  role: Yup.string().oneOf(Object.values(ORGANIZATION_ROLE)).required(),
});
