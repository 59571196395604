import React from 'react';
import Sidebar from './Sidebar';
import * as Styled from './Layout.styles';

interface LayoutProps {
  children: React.ReactNode;
  showSidebar?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, showSidebar = false }) => {
  return (
    <Styled.Layout className='wrapper'>
      {showSidebar && <Sidebar />}
      <Styled.Content>{children}</Styled.Content>
    </Styled.Layout>
  );
};

export default Layout;
