import { PLAN } from './user';

export enum CHECK_TYPE {
  US_RE = 'US_RE',
  DUAL_USE = 'DUAL_USE',
  CATCH_ALL = 'CATCH_ALL',
  CHINA = 'CHINA',
  LIST_SELECTION = 'LIST_SELECTION',
  MATCH_EVALUATION = 'MATCH_EVALUATION',
  END_USE_CERTIFICATE = 'END_USE_CERTIFICATE',
  INDIVIDUAL_TEST = 'INDIVIDUAL_TEST',
  INDIVIDUAL_TEST_MULTI = 'INDIVIDUAL_TEST_MULTI',
  EMBARGO_CHECK = 'EMBARGO_CHECK',
  US_RE_PERSON = 'US_RE_PERSON',
  US_RE_PRODUCT = 'US_RE_PRODUCT',
  US_RE_DE_MINIMIS = 'US_RE_DE_MINIMIS',
  US_RE_FDP_RULES = 'US_RE_FDP_RULES',
  SOLID_NLR = 'SOLID_NLR',
  _TEST_ORG_EXCLUSIVE_CHECK = '_TEST_ORG_EXCLUSIVE_CHECK', // TODO remove again, this is only for demonstration purposes
  SUPPLY_CHAIN_ACT = 'SUPPLY_CHAIN_ACT',
}

export const CHECK_TYPE_NAME_TEXT: { [key in CHECK_TYPE]: string } = {
  [CHECK_TYPE.DUAL_USE]: 'Dual-Use',
  [CHECK_TYPE.US_RE]: 'US-Re',
  [CHECK_TYPE.CATCH_ALL]: 'Catch-all-Clauses',
  [CHECK_TYPE.CHINA]: 'China Export',
  [CHECK_TYPE.LIST_SELECTION]: 'Listenauswahl',
  [CHECK_TYPE.MATCH_EVALUATION]: 'Trefferbewertung',
  [CHECK_TYPE.END_USE_CERTIFICATE]: 'End-Use-Certificate',
  [CHECK_TYPE.INDIVIDUAL_TEST]: 'Einzelprüfung',
  [CHECK_TYPE.INDIVIDUAL_TEST_MULTI]: 'Dateiprüfung',
  [CHECK_TYPE.EMBARGO_CHECK]: 'Embargoprüfung',
  [CHECK_TYPE.US_RE_PERSON]: 'EAR: US-Person',
  [CHECK_TYPE.US_RE_PRODUCT]: 'EAR: US-Produkt',
  [CHECK_TYPE.US_RE_DE_MINIMIS]: 'EAR: De-minimis',
  [CHECK_TYPE.US_RE_FDP_RULES]: 'EAR: FDP-rules',
  [CHECK_TYPE.SOLID_NLR]: 'SOLID-NLR',
  [CHECK_TYPE._TEST_ORG_EXCLUSIVE_CHECK]: '[Test] Org Exclusive Prüfung',
  [CHECK_TYPE.SUPPLY_CHAIN_ACT]: 'LkSG Prüfung',
};

export const CHECK_TYPE_NAME_HTML: { [key in CHECK_TYPE]: string } = {
  [CHECK_TYPE.DUAL_USE]: 'Dual-Use',
  [CHECK_TYPE.US_RE]: 'US-Re',
  [CHECK_TYPE.CATCH_ALL]: 'Catch-all-Clauses',
  [CHECK_TYPE.CHINA]: 'China Export',
  [CHECK_TYPE.LIST_SELECTION]: 'Listen&shy;auswahl',
  [CHECK_TYPE.MATCH_EVALUATION]: 'Treffer&shy;bewertung',
  [CHECK_TYPE.END_USE_CERTIFICATE]: 'End-Use-Certificate',
  [CHECK_TYPE.INDIVIDUAL_TEST]: 'Einzel&shy;prüfung',
  [CHECK_TYPE.INDIVIDUAL_TEST_MULTI]: 'Dateiprüfung',
  [CHECK_TYPE.EMBARGO_CHECK]: 'Embargo&shy;prüfung',
  [CHECK_TYPE.US_RE_PERSON]: 'EAR: US-Person',
  [CHECK_TYPE.US_RE_PRODUCT]: 'EAR: US-Produkt',
  [CHECK_TYPE.US_RE_DE_MINIMIS]: 'EAR: De-minimis',
  [CHECK_TYPE.US_RE_FDP_RULES]: 'EAR: FDP-rules',
  [CHECK_TYPE.SOLID_NLR]: 'SOLID-NLR',
  [CHECK_TYPE._TEST_ORG_EXCLUSIVE_CHECK]: '[Test] Org Exclusive Prüfung',
  [CHECK_TYPE.SUPPLY_CHAIN_ACT]: 'LkSG Prüfung',
};

export const CHECK_TYPE_SLUG: { [key in CHECK_TYPE]: string } = {
  [CHECK_TYPE.US_RE]: 'us-re-exportkontrollrecht',
  [CHECK_TYPE.DUAL_USE]: 'dual-use-oder-rustungsgut',
  [CHECK_TYPE.CATCH_ALL]: 'catch-all-clauses',
  [CHECK_TYPE.CHINA]: 'chinesisches-exportkontrollrecht',
  [CHECK_TYPE.LIST_SELECTION]: 'listenauswahl',
  [CHECK_TYPE.MATCH_EVALUATION]: 'trefferbewertung',
  [CHECK_TYPE.END_USE_CERTIFICATE]: 'endverbleibsdokumente',
  [CHECK_TYPE.INDIVIDUAL_TEST]: 'einzelpruefung',
  [CHECK_TYPE.INDIVIDUAL_TEST_MULTI]: 'dateipruefung',
  [CHECK_TYPE.EMBARGO_CHECK]: 'embargopruefung',
  [CHECK_TYPE.US_RE_PERSON]: 'us-re-us-person',
  [CHECK_TYPE.US_RE_PRODUCT]: 'us-re-us-origin-product',
  [CHECK_TYPE.US_RE_DE_MINIMIS]: 'us-re-de-minimis',
  [CHECK_TYPE.US_RE_FDP_RULES]: 'us-re-fdp-rules',
  [CHECK_TYPE.SOLID_NLR]: 'solid-nlr',
  [CHECK_TYPE._TEST_ORG_EXCLUSIVE_CHECK]: 'org-exklusive-pruefung',
  [CHECK_TYPE.SUPPLY_CHAIN_ACT]: 'lksg',
};

export function getCheckTypeFromSlug(slug: string): CHECK_TYPE | null {
  return (Object.entries(CHECK_TYPE_SLUG).find(([, value]) => value === slug)?.[0] as CHECK_TYPE) ?? null;
}

export const CHECK_TYPES_HAS_REPORT: Record<CHECK_TYPE, boolean> = {
  [CHECK_TYPE.DUAL_USE]: true,
  [CHECK_TYPE.US_RE]: true,
  [CHECK_TYPE.CATCH_ALL]: true,
  [CHECK_TYPE.CHINA]: true,
  [CHECK_TYPE.LIST_SELECTION]: true,
  [CHECK_TYPE.MATCH_EVALUATION]: true,
  [CHECK_TYPE.END_USE_CERTIFICATE]: true,
  [CHECK_TYPE.INDIVIDUAL_TEST]: true,
  [CHECK_TYPE.INDIVIDUAL_TEST_MULTI]: true,
  [CHECK_TYPE.EMBARGO_CHECK]: true,
  [CHECK_TYPE.US_RE_PERSON]: true,
  [CHECK_TYPE.US_RE_PRODUCT]: true,
  [CHECK_TYPE.US_RE_DE_MINIMIS]: true,
  [CHECK_TYPE.US_RE_FDP_RULES]: true,
  [CHECK_TYPE.SOLID_NLR]: true,
  [CHECK_TYPE._TEST_ORG_EXCLUSIVE_CHECK]: true,
  [CHECK_TYPE.SUPPLY_CHAIN_ACT]: true,
};

export const CHECK_TYPES_DISABLED: CHECK_TYPE[] = [CHECK_TYPE.US_RE];

export const CHECK_TYPES_ENABLED: CHECK_TYPE[] = Object.keys(CHECK_TYPE).filter(
  type => CHECK_TYPES_DISABLED.includes(type as CHECK_TYPE) === false
) as CHECK_TYPE[];

/**
 * Defines what plan is required to complete a specific check.
 */
export const CHECK_TYPES_NEEDED_PLANS: { [key in CHECK_TYPE]: PLAN[] } = {
  [CHECK_TYPE.DUAL_USE]: [PLAN.FREE, PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.US_RE]: [PLAN.FREE, PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.CATCH_ALL]: [PLAN.FREE, PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.CHINA]: [PLAN.FREE, PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.LIST_SELECTION]: [PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.MATCH_EVALUATION]: [PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.END_USE_CERTIFICATE]: [PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.INDIVIDUAL_TEST]: [PLAN.FREE, PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.INDIVIDUAL_TEST_MULTI]: [PLAN.PLUS],
  [CHECK_TYPE.EMBARGO_CHECK]: [PLAN.FREE, PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.US_RE_PERSON]: [PLAN.FREE, PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.US_RE_PRODUCT]: [PLAN.FREE, PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.US_RE_DE_MINIMIS]: [PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.US_RE_FDP_RULES]: [PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.SOLID_NLR]: [PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE._TEST_ORG_EXCLUSIVE_CHECK]: [PLAN.PLUS_TRIAL, PLAN.PLUS],
  [CHECK_TYPE.SUPPLY_CHAIN_ACT]: [PLAN.FREE, PLAN.PLUS_TRIAL, PLAN.PLUS],
};

export interface AbsolvedCheck {
  /**
   * The id of the check
   */
  id: number;
  /**
   * User that has completed this check.
   */
  userId: number;
  /**
   * Type of check that was completed.
   */
  type: CHECK_TYPE;
  /**
   * Raw JSON representation of the Typeform completion.
   */
  payload: any | null;
  /**
   * Name of the item (product / person / tech) that was checked. Is `null` in case the user was deleted and the check anonymized.
   */
  itemName: string | null;
  /**
   * Date this form was submitted (provided by Typeform)
   */
  submittedAt: Date;
  /**
   * UID given by Typeform
   */
  uid: string;
  /**
   * Custom note provided by the user.
   */
  note: string;
}

export interface CheckResultGeneric {
  /**
   * HTML string describing why the check has had this result.
   */
  text: string;
  /**
   * There are the following result states: "failed", "success" or "neutral".
   */
  result: GENERIC_RESULT_STATE;
}

export interface CheckResultWithItemName extends CheckResultGeneric {
  /**
   * For this check type, the checked item's name is dynamically calculated based on the result.
   */
  itemName: string;
}

export enum GENERIC_RESULT_STATE {
  /**
   * This is actually considered "warning"
   */
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  NEUTRAL = 'NEUTRAL',
  INFO = 'INFO',
  PROHIBITION = 'PROHIBITION',
}

export enum DUAL_USE_RESULT {
  NATIONAL = 'NATIONAL',
  MILITARY = 'MILITARY',
  NO_DUAL_USE = 'NO_DUAL_USE',
  CATEGORIES_LIST = 'CATEGORIES_LIST',
}

export enum DUAL_USE_CATEGORY {
  C0 = '0',
  C1 = '1',
  C2 = '2',
  C3 = '3',
  C4 = '4',
  C5a = '5a',
  C5b = '5b',
  C6 = '6',
  C7 = '7',
  C8 = '8',
  C9 = '9',
}

export interface CheckResultDualUse {
  type: DUAL_USE_RESULT;
  /**
   * Categories selected in the form.
   * Only available in type CATEGORIES_LIST.
   */
  categories?: DUAL_USE_CATEGORY[];
}

export interface IndividualTestMatch {
  addressType: string;
  aliasGroupNo: string;
  city: string;
  cityOfBirth: string;
  countryISO: string;
  countryOfBirthISO: string;
  dateOfBirth: string;
  info: string;
  listAbbreviation: string;
  listName: string;
  name: string;
  nationalityISO: string;
  passportData: string;
  restrictionDate: string;
  restrictionType: string;
  restrictionSource: string;
  similarity: string;
  sourceWebLink: string;
  street: string;
  updateDate: string;
  updateId: string;
}

export interface IndividualTestPayload {
  name: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
  note: string;
  isHit: boolean;
  hasMatches: boolean;
}

export interface IndividualMultiTestMatch {
  addressType: string;
  aliasGroupNo: string;
  city: string;
  cityOfBirth: string;
  countryISO: string;
  countryOfBirthISO: string;
  dateOfBirth: string;
  info: string;
  listAbbreviation: string;
  listName: string;
  name: string;
  nationalityISO: string;
  passportData: string;
  restrictionDate: string;
  restrictionType: string;
  restrictionSource: string;
  similarity: string;
  sourceWebLink: string;
  street: string;
  updateDate: string;
  updateId: string;
}

export interface IndividualMultiTestPayload {
  results: (IndividualTestPayload & { childId: string })[];
  fileName: string;
}

export const CHECK_ID_PREFIX: { [key in CHECK_TYPE]?: string } = {
  [CHECK_TYPE.INDIVIDUAL_TEST]: 'it',
  [CHECK_TYPE.INDIVIDUAL_TEST_MULTI]: 'itm',
};
