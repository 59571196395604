import { isPaidPlan } from '@solid/shared';
import { Button } from '@solid/shared/ui';
import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { PaymentPopupContext } from '../../contexts/payment-popup.context';
import { AuthContext } from '../../contexts/auth.context';
import { logoutUser } from '../../services/auth.service';
import { isChristmasTime } from '../../services/christmas.service';
import CurrentPlan from '../common/CurrentPlan';
import * as Styled from './Navbar.styles';
import { getForumNotifications } from '../../services/user.service';
import ms from 'ms';

const Navbar: React.FC = () => {
  const { auth, refetchAuthState } = useContext(AuthContext);
  const paymentPopup = useContext(PaymentPopupContext);
  const [shrink, setShrink] = useState(false);
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLEmbedElement>(null);

  const { mutate: logout, isLoading } = useMutation(
    async () => {
      await logoutUser();
      await refetchAuthState();
    },
    { onSuccess: () => setOpen(false) }
  );

  const { data: forumNotifications } = useQuery(
    ['forum-notifications', auth?.user.id],
    getForumNotifications,
    {
      staleTime: ms('1min'),
      refetchOnWindowFocus: true,
      enabled: !!auth,
    }
  );

  useEffect(() => {
    const minShrinkPageOffset = 40;
    const eventHandler = () => {
      const distanceToTop = window.pageYOffset;
      if (distanceToTop > minShrinkPageOffset) {
        setShrink(true);
      } else {
        setShrink(false);
      }
    };
    window.addEventListener('scroll', eventHandler);
    return () => {
      window.removeEventListener('scroll', eventHandler);
    };
  }, []);

  return (
    <Styled.Navbar id='navbar' $isOpen={open} $isShrunk={shrink} ref={ref}>
      <div className='wrapper nav-container'>
        <Link to='/' className='brand'>
          {isChristmasTime() && (
            <img
              className='santa-hat'
              width='38'
              src='/assets/santa-hat.png'
              srcSet='/assets/santa-hat@2x.png 2x'
              alt='Weihnachtsmann Mütze'
            />
          )}
          <Logo className='logo' />
        </Link>

        {auth && (
          <>
            <nav>
              <div className='nav-list'>
                <div className='nav-item'>
                  <a className='link' href={environment.discourse.baseUrl}>
                    Forum
                    {forumNotifications > 0 && <span className='badge'>{forumNotifications > 9 ? '9+' : forumNotifications}</span>}
                  </a>
                </div>

                <CurrentPlan className='current-plan' title='Ihr aktueller Plan' />

                {isPaidPlan(auth.user.plan) === false && (
                  <div className='nav-item'>
                    <Button onClick={() => paymentPopup.setOpen(true)}>Upgrade</Button>
                  </div>
                )}

                <div className='nav-item'>
                  <Button variant='outlined' isLoading={isLoading} onClick={() => logout()}>
                    Logout
                  </Button>
                </div>
              </div>
            </nav>

            <button id='nav-toggle' className={classNames({ active: open })} onClick={() => setOpen(!open)}>
              <span></span>
            </button>
          </>
        )}
      </div>
    </Styled.Navbar>
  );
};

export default Navbar;
