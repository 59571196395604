import { ORGANIZATION_ROLE, ORGANIZATION_ROLE_NAME, PLAN } from '@solid/shared';
import classNames from 'classnames';
import { differenceInDays } from 'date-fns';
import React, { HTMLAttributes, useContext } from 'react';
import { AuthContext } from '../../contexts/auth.context';

interface CurrentPlanProps {
  className?: string;
}

const CurrentPlan: React.FC<CurrentPlanProps & HTMLAttributes<HTMLDivElement>> = ({ className = '', ...props }) => {
  const { auth } = useContext(AuthContext);

  let daysRemaining = auth.user.trialEnd ? differenceInDays(auth.user.trialEnd, new Date()) : null;
  if (daysRemaining < 0) {
    daysRemaining = 0;
  }

  let planName = 'FREE';

  if (auth.user.plan === PLAN.PLUS_TRIAL) {
    planName = 'PLUS';
  } else if (auth.user.plan === PLAN.PLUS) {
    planName = 'PLUS';
    if (auth.orgMember) {
      if ([ORGANIZATION_ROLE.LOCAL_ADMIN, ORGANIZATION_ROLE.MANAGER].includes(auth.orgMember.role)) {
        planName = ORGANIZATION_ROLE_NAME[auth.orgMember.role];
      }
    }
  }

  return (
    <>
      <span className='plus-trial-text'>
        {auth.user.plan === PLAN.PLUS_TRIAL && `Noch ${daysRemaining} Tag${daysRemaining === 1 ? '' : 'e'}`}
      </span>
      <div className={classNames(className, `plan-${auth.user.plan}`)} {...props}>
        <span>{planName.toUpperCase()}</span>
      </div>
    </>
  );
};

export default CurrentPlan;
