import { Color } from '@solid/shared/styles/colors';
import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/auth.context';
import { useOrganizationAbility } from '../../hooks/useOrganizationAbility';
import { isOrganizationAccount, isStandaloneAccount } from '../../services/user.service';
import PasswordForm from '../auth/PasswordForm';
import AccountDeletion from './AccountDeletion';
import NameForm from './NameForm';
import OrgCompanyDetailsForm from './OrgCompanyDetailsForm';
import * as StyledSettings from './Settings.styles';
import UserCompanyDetailsForm from './UserCompanyDetailsForm';

const SettingsAccountPage: React.FC = () => {
  const { auth } = useContext(AuthContext);

  const ability = useOrganizationAbility();

  return (
    <>
      <h2>Kontoinformation</h2>
      <StyledSettings.Section>
        <NameForm />
      </StyledSettings.Section>
      {isStandaloneAccount(auth) && (
        <StyledSettings.Section>
          <UserCompanyDetailsForm color={Color.Neutral_0_White} showSuccess />
        </StyledSettings.Section>
      )}
      {isOrganizationAccount(auth) && ability.can('update', 'Organization') && (
        <StyledSettings.Section>
          <h3>Organisation "{auth.org.displayName}"</h3>
          <OrgCompanyDetailsForm />
        </StyledSettings.Section>
      )}
      <StyledSettings.Section>
        <PasswordForm />
      </StyledSettings.Section>
      <StyledSettings.Section>
        <AccountDeletion />
      </StyledSettings.Section>
    </>
  );
};

export default SettingsAccountPage;
