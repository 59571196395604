import React from 'react';

const TutorialsOverview: React.FC = () => {
  return (
    <>
      <p>
        <b>Sie wollen wissen, welche Prüfungen Sie erwarten?</b>
      </p>
      <p>
        Hier finden Sie kurze Beschreibungen zu den Angeboten von SOLID und Anleitungen für die verschiedenen
        interaktiven Fragenkataloge der Exportkontrolle.
      </p>
    </>
  );
};

export default TutorialsOverview;
