import { Color } from '../../styles/colors';
import { StyledLoadingSpinner } from '../../ui';
import styled from 'styled-components';

export const Card = styled.div<{ $minHeight?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${Color.Neutral_300};
  border-radius: ${props => props.theme.borderRadius.small};
  background-color: ${Color.Neutral_0_White};
  padding: 16px 32px;
  min-height: ${props => (props.$minHeight ? `${props.$minHeight}px` : 'auto')};

  .recharts-responsive-container {
    position: relative;
    margin-top: 16px;
  }

  .recharts-layer path:focus {
    outline: 0;
  }

  ${StyledLoadingSpinner.Indicator} {
    justify-self: center;
  }
`;

export const LoadingSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const Title = styled.h4`
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: center;
  margin-bottom: 0;
`;
