import React from 'react';
import Layout from '../global/Layout';
import ChecksList from './ChecksList';

const PersonalChecksPage: React.FC = () => {
  return (
    <Layout showSidebar>
      <h1>Mein Prüfungsarchiv</h1>

      <ChecksList variant='personal' />
    </Layout>
  );
};

export default PersonalChecksPage;
