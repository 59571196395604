import React from 'react';
import { Link } from 'react-router-dom';
import RegisterForm from './RegisterForm';
import singlePageStyles from '../common/SinglePage.module.scss';
import Layout from '../global/Layout';

const RegisterPage: React.FC = () => {
  return (
    <Layout>
      <div className={singlePageStyles.wrapper}>
        <h1>Kostenlos registrieren</h1>

        <p>SOLID hilft Ihnen bei der Prüfung Ihrer Fragen zum Exportkontrollrecht.</p>

        <RegisterForm />

        <div className={singlePageStyles.otherActions}>
          <Link to='/login'>Zurück zum Login</Link>
        </div>
      </div>
    </Layout>
  );
};

export default RegisterPage;
