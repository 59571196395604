import { Organization } from '@solid/shared';
import { acceptOrgInvite } from '@solid/shared/services/organization.service';
import { Button, Notification } from '@solid/shared/ui';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth.context';
import { getManagementURLs, openPaddlePopup, paddleEvent } from '../../services/payment.service';
import * as Styled from './OrgAcceptInviteForm.styles';

interface OrgAcceptInviteFormProps {
  organization: Organization;
  token: string;
}

/**
 * This component is used to accept an invitation to an organization, if the user already has an account.
 */
const OrgAcceptInviteForm: React.FC<OrgAcceptInviteFormProps> = ({ organization, token }) => {
  const { refetchAuthState } = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    data: managementUrls,
    isLoading: isLoadingUrls,
    refetch: refetchUrls,
  } = useQuery(['management-urls'], () => getManagementURLs(), {
    staleTime: 0,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    const sub = paddleEvent.subscribe(data => {
      // Automatically refetch management urls upon cancellation
      console.log(data.event);
      if (data.event === 'Checkout.Complete') {
        refetchUrls();
      }
    });
    return () => sub.unsubscribe();
  }, [refetchUrls]);

  const {
    mutate: acceptInvite,
    error,
    isLoading: isLoadingAccept,
    isSuccess: isSuccessAccept,
  } = useMutation<void, Error, void>(['accept-org-invite', token], async () => {
    const promise = acceptOrgInvite(token);

    toast.promise(promise, {
      loading: 'Einladung wird akzeptiert...',
      success: `Einladung erfolgreich angenommen.`,
      error: () => `Einladung konnte nicht angenommen werden.`,
    });

    await promise;

    refetchAuthState();
    setTimeout(() => navigate('/'), 1000); // Short delay to allow user to read success message
  });

  const isLoading = isLoadingAccept || isLoadingUrls;
  const hasActivePlan = !!managementUrls?.cancellationUrl;

  return (
    <div>
      <Styled.InfoContainer>
        <Notification state='warning' hasBrighterText>
          Bitte beachten Sie, dass mit dem Beitritt alle Ihre bisher abgeschlossenen Prüfungen der Organisation
          zugewiesen werden. Sollten Sie dies nicht wünschen, können Sie die Prüfungen vor dem Beitritt über das{' '}
          <Link to='/checks'>Prüfungsarchiv</Link> löschen.
        </Notification>
      </Styled.InfoContainer>

      <Styled.Form
        onSubmit={e => {
          e.preventDefault();
          acceptInvite();
        }}>
        <div className='form-field'>
          <label className='checkbox' htmlFor='accept-move-checks-to-org'>
            <input type='checkbox' id='accept-move-checks-to-org' name='accept-move-checks-to-org' required />
            <span>
              Hiermit bestätige ich, dass alle beendeten Prüfungen in den Besitz der Organisation "
              {organization.displayName}" übergehen.
            </span>
          </label>
        </div>

        {hasActivePlan && (
          <Styled.InfoContainer>
            <Notification state='danger' hasBrighterText>
              Dieser Account hat aktuell ein aktives Abonnement. Sie müssen dieses kündigen, bevor Sie der Organisation
              beitreten können.
            </Notification>
            <p></p>
            <Button
              themeColor='danger'
              variant='outlined'
              type='button'
              isLoading={isLoadingUrls}
              onClick={() => openPaddlePopup(managementUrls.cancellationUrl)}>
              Abonnement kündigen
            </Button>
          </Styled.InfoContainer>
        )}

        <div className='form-field'>{error && <div className='notification is-error'>{error.message}</div>}</div>

        {hasActivePlan === false && isSuccessAccept === false && (
          <Button type='submit' themeColor='primary' isLoading={isLoading} disabled={isLoading}>
            Einladung akzeptieren
          </Button>
        )}
      </Styled.Form>
    </div>
  );
};

export default OrgAcceptInviteForm;
