import { LoadingSpinner } from '@solid/shared/ui';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth.context';
import { useSearchParam } from '../../hooks/useSearchParam';
import * as Styled from './LoggedXRoute.styles';

const LoggedOutRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { auth } = useContext(AuthContext);
  const redirect = useSearchParam('redirect');

  if (auth === undefined) {
    // User is not yet loaded
    return (
      <div className='wrapper'>
        <Styled.Container>
          <LoadingSpinner />
        </Styled.Container>
      </div>
    );
  }
  if (auth === null) {
    // User is not logged in
    return children;
  } else {
    // User is logged in
    return <Navigate to={redirect ? decodeURIComponent(redirect) : '/'} />;
  }
};

export default LoggedOutRoute;
