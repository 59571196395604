import Layout from '../global/Layout';
import NotificationMessage from '@solid/shared/ui/NotificationMessage';
import * as Styled from './NewCheckPage.styles';
import { Link } from 'react-router-dom';

const CheckNotFound = () => {
  return (
    <Layout>
      <div style={{ maxWidth: '300px', margin: '0 auto' }}>
        <NotificationMessage state='danger'>Die Prüfung wurde nicht gefunden</NotificationMessage>
      </div>
      <Styled.BtnContainer>
        <Link className='btn is-primary is-outlined' to='/'>
          Zurück zur Übersicht
        </Link>
      </Styled.BtnContainer>
    </Layout>
  );
};

export default CheckNotFound;
