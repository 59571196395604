import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import singlePageStyles from '../common/SinglePage.module.scss';
import { useQuery } from '@tanstack/react-query';
import { loginToForum } from '../../services/auth.service';
import { AuthContext } from '../../contexts/auth.context';
import Layout from '../global/Layout';
import { LoadingSpinner } from '@solid/shared/ui';

const ForumLoginPage: React.FC = () => {
  const { search } = useLocation();
  const { auth } = useContext(AuthContext);

  const { error } = useQuery<string, Error>(['forum-login'], () => loginToForum(search), {
    onSuccess: redirectUrl => {
      window.location.href = redirectUrl;
    },
    staleTime: Infinity,
  });

  return (
    <Layout>
      <div className={singlePageStyles.wrapper}>
        <h1>Forum-Login</h1>

        <p>
          Sie werden als{' '}
          <b>
            {auth.user.firstName} {auth.user.lastName}
          </b>{' '}
          im SOLID Forum angemeldet.
        </p>

        <LoadingSpinner />

        {error && <p className='notification is-error'>{error.message}</p>}
      </div>
    </Layout>
  );
};

export default ForumLoginPage;
