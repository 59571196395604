import React, { useEffect, useRef, useState } from 'react';
import * as Styled from './CollapseContainer.styles';

export interface CollapseContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: any;
  isOpen: boolean;
}

const CollapseContainer: React.FC<CollapseContainerProps> = ({ isOpen = false, children, ...props }) => {
  const [height, setHeight] = useState<number | null>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const transitionDuration = 250;

  useEffect(() => {
    const el = containerRef.current;
    let timeout: number;

    if (el) {
      if (isOpen === true) {
        el.style.display = 'block';
        setHeight(el.scrollHeight);
        timeout = window.setTimeout(() => {
          setHeight(null);
        }, transitionDuration + 20);
      } else {
        setHeight(el.scrollHeight);
        timeout = window.setTimeout(() => {
          setHeight(0);
          timeout = window.setTimeout(() => {
            el.style.display = 'none';
          }, transitionDuration);
        }, 20);
      }
    }

    return () => {
      window.clearTimeout(timeout);
    };
  }, [isOpen, transitionDuration]);

  return (
    <Styled.Container
      ref={containerRef}
      style={{ height: height !== null ? height + 'px' : undefined }}
      aria-hidden={!isOpen}
      {...props}>
      {children}
    </Styled.Container>
  );
};

export default CollapseContainer;
