import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  isSameDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { CHECK_TYPE } from '../check';
import { apiClient } from './api.service';

export interface DateRange {
  startDate: Date | null;
  endDate: Date;
}

export interface DateRangeEntry {
  id: keyof DateRangeType;
  name: string;
  dateRange: DateRange;
}

export type DateRangeType = {
  THIS_WEEK: DateRangeEntry;
  THIS_MONTH: DateRangeEntry;
  THIS_YEAR: DateRangeEntry;
  ALL_TIME: DateRangeEntry;
};

export const DATE_RANGE_PRESET: DateRangeType = {
  THIS_WEEK: {
    id: 'THIS_WEEK',
    name: 'Diese Woche',
    dateRange: {
      startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
      endDate: endOfDay(
        new Date() < endOfWeek(new Date(), { weekStartsOn: 1 })
          ? new Date()
          : endOfWeek(new Date(), { weekStartsOn: 1 })
      ),
    },
  },
  THIS_MONTH: {
    id: 'THIS_MONTH',
    name: 'Dieser Monat',
    dateRange: {
      startDate: startOfMonth(new Date()),
      endDate: endOfDay(new Date() < endOfMonth(new Date()) ? new Date() : endOfMonth(new Date())),
    },
  },
  THIS_YEAR: {
    id: 'THIS_YEAR',
    name: 'Dieses Jahr',
    dateRange: {
      startDate: startOfYear(new Date()),
      endDate: endOfDay(new Date() < endOfYear(new Date()) ? new Date() : endOfYear(new Date())),
    },
  },
  ALL_TIME: {
    id: 'ALL_TIME',
    name: 'Gesamt',
    dateRange: {
      startDate: null,
      endDate: endOfDay(new Date() < endOfDay(new Date()) ? new Date() : endOfDay(new Date())),
    },
  },
};

export interface DateChunk {
  from: Date;
  to: Date;
  value: number;
}

export interface ChartDataEntry {
  name: string;
  value: number;
}

export interface AnalyticStatistics {
  absolvedChecksCount: number;
  absolvedChecksType: { type: CHECK_TYPE; amount: number }[];
  absolvedChecksTimeSeries: DateChunk[];
  usersNewInternal: number;
  usersNew: number;
  usersBeforeFrom: number;
  usersActive: number;
  usersDeleted: number;
  usersTimeSeries: DateChunk[];
}

export async function getStatistics(filters: { from: Date | null; to: Date; orgId?: number }) {
  const params = { ...filters, from: filters.from ? filters.from : new Date(0) };
  const res = await apiClient.get<AnalyticStatistics>(`/v1/analytics/statistics`, { params });
  return res.data;
}

export function convertDateChunkToChartData(dateChunks: DateChunk[]) {
  return dateChunks.map(chunk => ({
    name: getDateName(chunk),
    value: chunk.value,
  }));
}

export function convertDateChunkToCumulativeChartData(dateChunks: DateChunk[], baseValue: number) {
  let currentValue = baseValue;
  return dateChunks.map(chunk => {
    currentValue += chunk.value;
    return {
      name: getDateName(chunk),
      value: currentValue,
    };
  });
}

function getDateName(chunk: DateChunk) {
  if (isSameDay(chunk.from, chunk.to)) {
    return chunk.from.toLocaleDateString();
  }
  return `${chunk.from.toLocaleDateString()} - ${chunk.to.toLocaleDateString()}`;
}
