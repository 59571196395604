/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export const mixed = {
  default: 'Dieser Wert ist ungültig',
  required: 'Dies ist ein Pflichtfeld',
  oneOf: 'Dies muss einem der folgenden Werte entsprechen: ${values}',
  notOneOf: 'Dies darf keinem der folgenden Werte entsprechen: ${values}',
};

export const string = {
  length: 'Dieser Wert muss genau ${length} Zeichen lang sein',
  min: 'Dieser Wert muss mindestens ${min} Zeichen lang sein',
  max: 'Dieser Wert darf höchstens ${max} Zeichen lang sein',
  matches: 'Dieser Wert muss wie folgt aussehen: "${regex}"',
  email: 'Dieser Wert muss eine gültige E-Mail-Adresse enthalten',
  url: 'Dieser Wert muss eine gültige URL sein',
  trim: 'Dieser Wert darf keine Leerzeichen am Anfang oder Ende enthalten',
  lowercase: 'Dieser Wert darf nur Kleinschreibung enthalten',
  uppercase: 'Dieser Wert darf nur Großschreibung enthalten',
};

export const number = {
  min: 'Dieser Wert muss größer oder gleich ${min} sein',
  max: 'Dieser Wert muss kleiner oder gleich ${max} sein',
  lessThan: 'Dieser Wert muss kleiner sein als ${less}',
  moreThan: 'Dieser Wert muss größer sein als ${more}',
  notEqual: 'Dieser Wert darf nicht gleich sein mit "${notEqual}"',
  positive: 'Dieser Wert muss eine positive Zahl sein',
  negative: 'Dieser Wert muss eine negative Zahl sein',
  integer: 'Dieser Wert muss eine ganze Zahl sein',
};

export const date = {
  min: 'Dieses Datum muss später sein als ${min}',
  max: 'Dieses Datum muss früher sein als ${max}',
};

export const boolean = {
  isValue: 'Dieser Wert muss ${value} sein',
};

export const object = {
  noUnknown: '${path}-Feld darf keine Schlüssel verwenden, die nicht im "Objekt-Shape" definiert wurden',
};

export const array = {
  min: 'Dieses Feld muss mindesten ${min} Einträge haben',
  max: 'Dieses Feld darf höchstens ${max} Einträge haben',
};

Yup.setLocale({
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
});

export { Yup };
