import React from 'react';
import * as Styled from './AnalyticsOverviewCard.styles';

const AnalyticsOverviewCard: React.FC<{
  value?: string | number;
  title: string;
  description: React.ReactNode;
}> = ({ value, title, description }) => {
  return (
    <Styled.Card title={title} minHeight={208} isLoading={value === null}>
      <Styled.Value>{value}</Styled.Value>
      <p>{description}</p>
    </Styled.Card>
  );
};

export default AnalyticsOverviewCard;
