import { Organization, OrganizationMember } from './organization';

export enum USER_ROLE {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export enum PLAN {
  FREE = 'FREE',
  PLUS_TRIAL = 'PLUS_TRIAL',
  PLUS = 'PLUS',
}

export enum PAYMENT_METHOD {
  MANUALLY = 'MANUALLY',
  PADDLE = 'PADDLE',
}

export const PLAN_NAME: { [key in PLAN]: string } = {
  [PLAN.FREE]: 'FREE',
  [PLAN.PLUS_TRIAL]: 'PLUS Trial',
  [PLAN.PLUS]: 'PLUS',
};

export function isPaidPlan(plan: PLAN) {
  return [PLAN.PLUS].includes(plan);
}

export const allowedCountries = ['DE', 'CH', 'AT'] as const;

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  emailConfirmed: boolean;
  role: USER_ROLE;
  /**
   * Whether or not this user was a user in the legacy version of SOLID.
   */
  legacyUser: boolean;
  /**
   * Last time the user was active (completed a check)
   */
  lastActive: Date | null;
  /**
   * Current plan of this user.
   */
  plan: PLAN;
  /**
   * Date the trial ends, `null` if the user never had a trial.
   */
  trialEnd: Date | null;
  /**
   * Date the curent subscription ends, `null` if the user never had a subscription.
   */
  subscriptionEnd: Date | null;
  /**
   * Users last used payment method.
   */
  paymentMethod: PAYMENT_METHOD | null;
  paddleUserId: string | null;
  subscriptionId: string | null;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  companyName: string;
  companyStreet: string;
  companyZipCode: string;
  companyCity: string;
  companyCountry: (typeof allowedCountries)[number] | null;
  companyEmail: string;
  companyVatId: string;
  companyBillingName: string;
  companyBillingStreet: string;
  companyBillingZipCode: string;
  companyBillingCity: string;
  companyBillingCountry: (typeof allowedCountries)[number] | null;
  companyDifferentBillingAddress: boolean;
}

export interface UserWithOrgMember extends User {
  orgMember: (OrganizationMember & { organization: Organization }) | null;
}

export interface InvoiceDetails {
  orderId: string;
  /**
   * @example "5.00"
   */
  amount: string;
  /**
   * @example "USD"
   */
  currency: string;
  createdAt: Date;
  /**
   * Link to the reciept of this payment.
   */
  receiptUrl: string;
  /**
   * User defined invoice comment, optional so might be `null`.
   */
  comment: string | null;
  /**
   * The id of the user this invoice belongs to.
   */
  userId: number;
}
