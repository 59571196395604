import { Color } from '@solid/shared/styles/colors';
import styled from 'styled-components';
import * as StyledPaymentPopup from './PaymentPopup.styles';

export const Headline = styled(StyledPaymentPopup.Headline)`
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
`;

export const Wrapper = styled.div`
  .control {
    input,
    select,
    textarea {
      color: ${Color.Blue_1000_Deep};
    }

    select option {
      color: ${Color.Blue_1000_Deep};
    }
  }
`;

export const InvoiceCommentContainer = styled.div`
  max-width: 36rem;
  margin: 0 auto 1rem;
`;

export const PaymentMethodContainer = styled.div`
  text-align: center;

  @media (${props => props.theme.breakpoint.md.up}) {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    margin: 0 -1rem 0.5rem;
  }
`;

export const SuccessMessage = styled.div`
  margin: 2rem auto 2rem;
  text-align: center;
`;
