import { apiClient } from '@solid/shared/services/api.service';
import {
  AbsolvedCheck,
  CheckResultDualUse,
  CheckResultGeneric,
  CHECK_TYPE,
  DUAL_USE_CATEGORY,
  IndividualTestMatch,
  IndividualMultiTestPayload,
} from '@solid/shared';
import { Yup } from '@solid/shared/services/validation.service';
import countries from 'i18n-iso-countries';
import marked from 'marked';
import Papa from 'papaparse';

export enum DUAL_USE_RESULT {
  NATIONAL = 'NATIONAL',
  MILITARY = 'MILITARY',
  NO_DUAL_USE = 'NO_DUAL_USE',
  CATEGORIES_LIST = 'CATEGORIES_LIST',
}

export const DUAL_USE_CATEGORY_NAME_MAP: { [key in DUAL_USE_CATEGORY]: string } = {
  '0': 'KERNTECHNISCHE MATERIALIEN, ANLAGEN UND AUSRÜSTUNG',
  '1': 'BESONDERE WERKSTOFFE UND MATERIALIEN UND ZUGEHÖRIGE AUSRÜSTUNG',
  '2': 'WERKSTOFFBEARBEITUNG',
  '3': 'ALLGEMEINE ELEKTRONIK',
  '4': 'RECHNER',
  '5a': 'TELEKOMMUNIKATION',
  '5b': 'INFORMATIONSSICHERHEIT',
  '6': 'SENSOREN UND LASER',
  '7': 'LUFTFAHRTELEKTRONIK UND NAVIGATION',
  '8': 'MEERES- UND SCHIFFSTECHNIK',
  '9': 'LUFTFAHRT, RAUMFAHRT UND ANTRIEBE',
};

export const DUAL_USE_CATEGORY_LINK_MAP: { [key in DUAL_USE_CATEGORY]: string } = {
  '0': 'https://www.bafa.de/SharedDocs/Downloads/DE/Aussenwirtschaft/afk_gueterlisten_dual_use_anhang_1_kategorie_0.pdf?__blob=publicationFile&v=8',
  '1': 'https://www.bafa.de/SharedDocs/Downloads/DE/Aussenwirtschaft/afk_gueterlisten_dual_use_anhang_1_kategorie_1.pdf?__blob=publicationFile&v=8',
  '2': 'https://www.bafa.de/SharedDocs/Downloads/DE/Aussenwirtschaft/afk_gueterlisten_dual_use_anhang_1_kategorie_2.pdf?__blob=publicationFile&v=8',
  '3': 'https://www.bafa.de/SharedDocs/Downloads/DE/Aussenwirtschaft/afk_gueterlisten_dual_use_anhang_1_kategorie_3.pdf?__blob=publicationFile&v=8',
  '4': 'https://www.bafa.de/SharedDocs/Downloads/DE/Aussenwirtschaft/afk_gueterlisten_dual_use_anhang_1_kategorie_4.pdf?__blob=publicationFile&v=8',
  '5a': 'https://www.bafa.de/SharedDocs/Downloads/DE/Aussenwirtschaft/afk_gueterlisten_dual_use_anhang_1_kategorie_5_teil_1.pdf?__blob=publicationFile&v=9',
  '5b': 'https://www.bafa.de/SharedDocs/Downloads/DE/Aussenwirtschaft/afk_gueterlisten_dual_use_anhang_1_kategorie_5_teil_2.pdf?__blob=publicationFile&v=9',
  '6': 'https://www.bafa.de/SharedDocs/Downloads/DE/Aussenwirtschaft/afk_gueterlisten_dual_use_anhang_1_kategorie_6.pdf?__blob=publicationFile&v=8',
  '7': 'https://www.bafa.de/SharedDocs/Downloads/DE/Aussenwirtschaft/afk_gueterlisten_dual_use_anhang_1_kategorie_7.pdf?__blob=publicationFile&v=8',
  '8': 'https://www.bafa.de/SharedDocs/Downloads/DE/Aussenwirtschaft/afk_gueterlisten_dual_use_anhang_1_kategorie_8.pdf?__blob=publicationFile&v=8',
  '9': 'https://www.bafa.de/SharedDocs/Downloads/DE/Aussenwirtschaft/afk_gueterlisten_dual_use_anhang_1_kategorie_9.pdf?__blob=publicationFile&v=8',
};

export async function getPersonalChecks(): Promise<AbsolvedCheck[]> {
  const response = await apiClient.get('/v1/checks');
  return response.data.checks;
}

export interface CheckResultResponse {
  result: CheckResultGeneric | CheckResultDualUse | IndividualMultiTestPayload;
  type: CHECK_TYPE;
  itemName: string;
  note: string;
  questions: Array<{ value: string | boolean; question: string }>;
  submittedAt: Date;
}

export function isCheckResultGeneric(
  result: CheckResultGeneric | CheckResultDualUse | IndividualMultiTestPayload
): result is CheckResultGeneric {
  return (result as CheckResultGeneric).result !== undefined && (result as CheckResultGeneric).text !== undefined;
}

export function parseMarkdown(text: string) {
  return marked(text ?? '', { gfm: true, breaks: true });
}

export async function getCheckResult(checkId: number | string): Promise<CheckResultResponse> {
  const response = await apiClient.get(`/v1/checks/${checkId}`);
  return response.data;
}

export async function getSignedTypeformUrl(checkType: CHECK_TYPE): Promise<string | null> {
  const response = await apiClient.get('/v1/checks/start', { params: { type: checkType } });
  return response.data.url;
}

export async function deleteCheck(checkId: number | string) {
  const response = await apiClient.delete(`/v1/checks/${checkId}`);
  return response.status === 200 ? true : false;
}

export const CheckNoteSchema = Yup.object().shape({
  note: Yup.string().max(5000, 'Bitte nutzen Sie max. 5000 Zeichen.').required('Bitte geben Sie einen Text an'),
});

export async function udpateCheckNote(checkId: number | string, note: string) {
  await apiClient.post(`/v1/checks/${checkId}/note`, { note });
}

export const AddressMatchSchema = Yup.object().shape({
  name: Yup.string().required('Please provide a name'),
  street: Yup.string(),
  zipCode: Yup.string(),
  city: Yup.string(),
  country: Yup.string().oneOf(Object.values(countries.getAlpha3Codes()), 'Please choose a valid country'),
});

type Address = Yup.InferType<typeof AddressMatchSchema>;

export async function getAddressMatch(params: Address) {
  const res = await apiClient.post<{ matchesFound: IndividualTestMatch[] }>(`/v1/checks/address-match`, params);
  return res.data;
}

export interface AddressMatchMultiResult {
  address: Address;
  hasMatches: boolean;
}

export async function getMultiAddressMatch(addresses: Address[]) {
  const res = await apiClient.post<{ result: AddressMatchMultiResult[] }>(
    '/v1/checks/address-match-multi',
    { data: addresses },
    {
      timeout: 60000,
    }
  );
  return res.data.result.map(result => {
    return {
      address: result.address,
      hasMatches: result.hasMatches,
    };
  });
}

export class IndividualTestCSVParseError extends Error {
  constructor(public message: string) {
    super(message);
  }
}

export async function parseIndividualTestCSVData(csvData: string): Promise<Address[]> {
  return new Promise((resolve, reject) => {
    Papa.parse(csvData, {
      delimiter: ';',
      header: true,
      skipEmptyLines: true,
      complete: results => {
        const columnNames = results.meta.fields;

        try {
          // Silently remove any empty rows
          const parsedData = results.data.filter(
            row => Object.values(row).every(value => value.trim() === '') === false
          );
          if (parsedData.length === 0) {
            throw new IndividualTestCSVParseError('The file does not contain any addresses.');
          }
          if (parsedData.length > 200) {
            throw new IndividualTestCSVParseError('The file contains more than 200 addresses and cannot be processed.');
          }

          // List of allowed column names (case insensitive)
          const COLUMN_NAMES_NAME = ['Name'];
          const COLUMN_NAMES_STREET = ['Straße', 'Straße und Hausnummer', 'Straße und Hausnr.', 'Street and house number', 'Street address'];
          const COLUMN_NAMES_CITY = ['Stadt', 'City'];
          const COLUMN_NAMES_ZIP = ['PLZ', 'Zip code', 'Zip'];
          const COLUMN_NAMES_COUNTRY = ['Land', 'Country'];

          // Validate that the file contains all required columns (case insensitive)
          const validateColumnNames = (expectedColumnNames: string[], columnLabel: string) => {
            expectedColumnNames = expectedColumnNames.map(name => name.toLowerCase());
            if (!columnNames.some(columnName => expectedColumnNames.includes(columnName.toLowerCase()))) {
              throw new IndividualTestCSVParseError(
                `The file does not contain a column "${columnLabel}" (allowed labels: ${expectedColumnNames.join(
                  ', '
                )}).`
              );
            }
          };
          validateColumnNames(COLUMN_NAMES_NAME, 'Name');
          validateColumnNames(COLUMN_NAMES_STREET, 'Street and house number');
          validateColumnNames(COLUMN_NAMES_CITY, 'City');
          validateColumnNames(COLUMN_NAMES_ZIP, 'Zip code');
          validateColumnNames(COLUMN_NAMES_COUNTRY, 'Country');

          // Get the value for a column based on a multiple allowed column names
          const getValueForColumn = (row: any, columnNames: string[]): string => {
            const columnNamesLowerCase = columnNames.map(name => name.toLowerCase());
            const columnName = Object.keys(row).find(key => columnNamesLowerCase.includes(key.toLowerCase()));
            return row[columnName];
          };

          const countryMapDE = countries.getNames('DE');
          const countryMapEN = countries.getNames('EN');

          const addressData: Address[] = [];
          parsedData.forEach((row, i) => {
            const countryRaw = getValueForColumn(row, COLUMN_NAMES_COUNTRY);
            const foundCountry = [...Object.entries(countryMapDE), ...Object.entries(countryMapEN)].find(
              ([_key, value]) => value.toLowerCase() === countryRaw.toLowerCase()
            )?.[0];

            // Name is the only required field
            const name = getValueForColumn(row, COLUMN_NAMES_NAME);
            if (!name) {
              throw new IndividualTestCSVParseError(`Line ${i + 1} contains an empty name.`);
            }

            const address: Address = {
              name: getValueForColumn(row, COLUMN_NAMES_NAME),
              street: getValueForColumn(row, COLUMN_NAMES_STREET),
              city: getValueForColumn(row, COLUMN_NAMES_CITY),
              zipCode: getValueForColumn(row, COLUMN_NAMES_ZIP),
              country: foundCountry,
            };

            addressData.push(address);
          });

          resolve(addressData);
        } catch (error) {
          reject(error);
        }
      },
      error: reject,
    });
  });
}

export const AddressMatchSaveSchema = CheckNoteSchema.concat(
  Yup.object().shape({
    hasMatches: Yup.boolean().required(),
    isHit: Yup.boolean().required('Please choose a result'),
  })
);

export async function saveAddressMatch(
  address: Yup.InferType<typeof AddressMatchSchema>,
  details: Yup.InferType<typeof AddressMatchSaveSchema>
) {
  const res = await apiClient.post<{ checkId: string }>(`/v1/checks/address-match/save`, {
    ...address,
    ...details,
  });
  return res.data;
}

export async function saveAddressMultiMatch(
  results: Array<
    Address & {
      isHit: boolean;
      hasMatches: boolean;
      note?: string;
    }
  >,
  fileName: string
) {
  const res = await apiClient.post<{ checkId: string }>(`/v1/checks/address-match/save-multi`, {
    results,
    fileName,
  });
  return res.data;
}
