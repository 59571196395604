import { CHECK_TYPE_NAME_TEXT } from '@solid/shared';
import AnalyticsOverviewCard from '@solid/shared/components/analytics/AnalyticsOverviewCard';
import BarChartComponent from '@solid/shared/components/analytics/BarChartComponent';
import DateRangeSelection from '@solid/shared/components/analytics/DateRangeSelection';
import PieChartComponent from '@solid/shared/components/analytics/PieChartComponent';
import {
  DATE_RANGE_PRESET,
  convertDateChunkToChartData,
  getStatistics,
} from '@solid/shared/services/analytics.service';
import { Color } from '@solid/shared/styles/colors';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth.context';
import { useOrganizationAbility } from '../../hooks/useOrganizationAbility';
import * as Styled from './OrgAnalyticsPage.styles';
import { useTheme } from 'styled-components';
import { getValueAndUnit } from 'polished';
import { endOfDay, startOfDay } from 'date-fns';

const OrgAnalyticsPage: React.FC = () => {
  const { auth } = useContext(AuthContext);
  const ability = useOrganizationAbility();
  const [startDate, setStartDate] = useState(DATE_RANGE_PRESET.THIS_MONTH.dateRange.startDate);
  const [endDate, setEndDate] = useState(DATE_RANGE_PRESET.THIS_MONTH.dateRange.endDate);
  const theme = useTheme();

  const { data, isLoading } = useQuery(
    ['analytics', startDate?.toISOString() ?? '', endDate?.toISOString() ?? ''],
    () => getStatistics({ from: startDate, to: endDate, orgId: auth.org.id }),
    { enabled: !!endDate }
  );

  useEffect(() => {
    if ((startDate?.getTime() ?? 0) > endDate.getTime()) {
      // End date is older than start date
      setStartDate(startOfDay(new Date(endDate.getTime())));
    }
    if (endDate?.getTime() > endOfDay(new Date()).getTime()) {
      // End date is in future -> set to today for better presentation of data
      setEndDate(new Date());
    }
  }, [startDate, endDate]);

  if (ability.cannot('read', 'Organization', 'analytics')) {
    return <Navigate to='/' />;
  }

  return (
    <>
      <p>Übersicht der abgeschlossenen Prüfungen in Ihrem Unternehmen auf einen Blick.</p>

      <DateRangeSelection
        startDate={startDate}
        endDate={endDate}
        onChange={({ startDate, endDate }) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        rootMargin={getValueAndUnit(theme.navbarHeightShrunk)[0]}
      />

      <Styled.CardContainer>
        <AnalyticsOverviewCard
          value={data?.absolvedChecksCount ?? null}
          title='Prüfungen'
          description='Prüfungen wurden abgeschlossen'
        />
        <AnalyticsOverviewCard
          value={data?.usersActive ?? null}
          title='Aktive Mitglieder'
          description='Mitglieder haben eine Prüfung durchgeführt'
        />
        <AnalyticsOverviewCard
          value={data?.usersNew ?? null}
          title='Neue Mitglieder'
          description='Neue Mitglieder sind der Organisation beigetreten'
        />
      </Styled.CardContainer>

      <Styled.ChartContainer>
        <Styled.ChartCard title='Abgeschlossene Prüfungen' isLoading={isLoading}>
          {data && (
            <BarChartComponent
              data={convertDateChunkToChartData(data.absolvedChecksTimeSeries)}
              displayName='Anzahl abgeschlossener Prüfungen'
              color={Color.Blue_500_Primary}
            />
          )}
        </Styled.ChartCard>
        <Styled.ChartCard title='Prüfungsarten' isLoading={isLoading}>
          {data && (
            <PieChartComponent
              data={data.absolvedChecksType.map(entry => ({
                name: CHECK_TYPE_NAME_TEXT[entry.type],
                value: entry.amount,
              }))}
            />
          )}
        </Styled.ChartCard>
      </Styled.ChartContainer>
    </>
  );
};

export default OrgAnalyticsPage;
