import { Color } from '@solid/shared/styles/colors';
import { Button } from '@solid/shared/ui';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage, Field, Formik } from 'formik';
import React, { useContext, useId } from 'react';
import { Yup } from '@solid/shared/services/validation.service';
import { environment } from '../../../environments/environment';
import { AuthContext } from '../../contexts/auth.context';
import * as userService from '../../services/user.service';
import * as Styled from './UserCompanyDetailsForm.styles';
import { PAYMENT_METHOD } from '@solid/shared';

interface UserCompanyDetailsFormProps {
  color?: string;
  displayTerms?: boolean;
  showSuccess?: boolean;
  buttonText?: string;
  additionalButton?: React.ReactNode;
  onComplete?: () => void;
}

const UserCompanyDetailsForm: React.FC<UserCompanyDetailsFormProps> = ({
  color = Color.Neutral_1000_Black,
  displayTerms = false,
  showSuccess = false,
  buttonText = 'Speichern',
  additionalButton,
  onComplete,
}) => {
  const { auth, refetchAuthState } = useContext(AuthContext);
  const id = useId();

  const {
    mutate: updateUser,
    error,
    isLoading,
    isSuccess,
  } = useMutation<void, Error, Yup.InferType<typeof userService.UserCompanyDetailsSchema>>(async data => {
    const payload = { ...data };
    if (payload.companyDifferentBillingAddress === false) {
      payload.companyBillingName = '';
      payload.companyBillingStreet = '';
      payload.companyBillingZipCode = '';
      payload.companyBillingCity = '';
      payload.companyBillingCountry = null;
    }

    // Remove terms checkbox from payload data, as it's client-side required
    delete (payload as any).terms;

    await userService.updateUser(auth.user.id, payload);
    refetchAuthState();
    if (onComplete) {
      onComplete();
    }
  });

  const emailChangeDisabled = auth.user.paymentMethod === PAYMENT_METHOD.PADDLE && auth.user.companyEmail?.length > 0;

  return (
    <Formik
      initialValues={
        {
          companyName: auth.user.companyName,
          companyStreet: auth.user.companyStreet,
          companyZipCode: auth.user.companyZipCode,
          companyCity: auth.user.companyCity,
          companyCountry: auth.user.companyCountry ?? '',
          companyEmail: auth.user.companyEmail,
          companyVatId: auth.user.companyVatId,
          companyBillingName: auth.user.companyBillingName,
          companyBillingStreet: auth.user.companyBillingStreet,
          companyBillingZipCode: auth.user.companyBillingZipCode,
          companyBillingCity: auth.user.companyBillingCity,
          companyBillingCountry: auth.user.companyBillingCountry ?? '',
          companyDifferentBillingAddress: auth.user.companyDifferentBillingAddress,
        } as Yup.InferType<typeof userService.UserCompanyDetailsSchema>
      }
      onSubmit={values => updateUser(values)}
      validationSchema={userService.UserCompanyDetailsSchema}>
      {({ values, isValidating }) => (
        <Styled.Form $color={color}>
          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='companyName'>Firmenname*</label>
              <div className='control'>
                <Field type='text' name='companyName' id='companyName' autoComplete='organization' required />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyName' />
            </div>

            <div className='form-field'>
              <label htmlFor='companyStreet'>Straße und Hausnr.*</label>
              <div className='control'>
                <Field type='text' name='companyStreet' id='companyStreet' required />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyStreet' />
            </div>
          </div>

          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='companyCity'>Stadt*</label>
              <div className='control'>
                <Field type='text' name='companyCity' id='companyCity' required />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyCity' />
            </div>
            <div className='form-field'>
              <label htmlFor='companyZipCode'>PLZ*</label>
              <div className='control'>
                <Field type='text' name='companyZipCode' id='companyZipCode' required />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyZipCode' />
            </div>
          </div>

          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='companyCountry'>Land*</label>
              <div className='control'>
                <Field component='select' type='select' name='companyCountry' id='companyCountry' required>
                  <option value='' disabled>
                    Bitte auswählen...
                  </option>
                  <option value='DE'>Deutschland</option>
                  <option value='AT'>Österreich</option>
                  <option value='CH'>Schweiz</option>
                </Field>
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyCountry' />
            </div>
            <div className='form-field is-ghost'></div>
          </div>

          <div className='form-row'>
            <div className='form-field'>
              <label htmlFor='companyVatId'>Ust-IdNr*</label>
              <div className='control'>
                <Field type='text' name='companyVatId' id='companyVatId' placeholder='DE123456789' required />
              </div>
              <ErrorMessage component='p' className='help is-danger' name='companyVatId' />
            </div>
            <div className='form-field'>
              <label htmlFor='companyEmail'>Rechnungsempfänger E-Mail-Adresse*</label>
              <div className='control'>
                <Field
                  type='text'
                  name='companyEmail'
                  id='companyEmail'
                  placeholder='invoice@firma.de'
                  required
                  disabled={emailChangeDisabled}
                />
              </div>
              {emailChangeDisabled && (
                <p className='help'>
                  Diese E-Mail-Adresse kann nicht geändert werden. Bitte kontaktieren Sie{' '}
                  <a href='mailto:info@solid-app.de'>info@solid-app.de</a>.
                </p>
              )}
              <ErrorMessage component='p' className='help is-danger' name='companyEmail' />
            </div>
          </div>

          <div className='form-row'>
            <Styled.DifferentBillingAddress className='form-field'>
              <label className='checkbox' htmlFor={`${id}-companyDifferentBillingAddress`}>
                <Field
                  type='checkbox'
                  id={`${id}-companyDifferentBillingAddress`}
                  name='companyDifferentBillingAddress'
                />
                <span>Abweichende Rechnungsadresse</span>
              </label>
            </Styled.DifferentBillingAddress>
          </div>

          {values.companyDifferentBillingAddress && (
            <div>
              <div className='form-row'>
                <div className='form-field'>
                  <label htmlFor='companyBillingName'>Name*</label>
                  <div className='control'>
                    <Field type='text' name='companyBillingName' id='companyBillingName' autoComplete='organization' />
                  </div>
                  <ErrorMessage component='p' className='help is-danger' name='companyBillingName' />
                </div>

                <div className='form-field'>
                  <label htmlFor='companyBillingStreet'>Straße und Hausnr.*</label>
                  <div className='control'>
                    <Field type='text' name='companyBillingStreet' id='companyBillingStreet' />
                  </div>
                  <ErrorMessage component='p' className='help is-danger' name='companyBillingStreet' />
                </div>
              </div>

              <div className='form-row'>
                <div className='form-field'>
                  <label htmlFor='companyBillingCity'>Stadt*</label>
                  <div className='control'>
                    <Field type='text' name='companyBillingCity' id='companyBillingCity' />
                  </div>
                  <ErrorMessage component='p' className='help is-danger' name='companyBillingCity' />
                </div>
                <div className='form-field'>
                  <label htmlFor='companyBillingZipCode'>PLZ*</label>
                  <div className='control'>
                    <Field type='text' name='companyBillingZipCode' id='companyBillingZipCode' />
                  </div>
                  <ErrorMessage component='p' className='help is-danger' name='companyBillingZipCode' />
                </div>
              </div>

              <div className='form-row'>
                <div className='form-field'>
                  <label htmlFor='companyBillingCountry'>Land*</label>
                  <div className='control'>
                    <Field component='select' type='select' name='companyBillingCountry' id='companyBillingCountry'>
                      <option value='' disabled>
                        Bitte auswählen...
                      </option>
                      <option value='DE'>Deutschland</option>
                      <option value='AT'>Österreich</option>
                      <option value='CH'>Schweiz</option>
                    </Field>
                  </div>
                  <ErrorMessage component='p' className='help is-danger' name='companyBillingCountry' />
                </div>
                <div className='form-field is-ghost'></div>
              </div>
            </div>
          )}

          {displayTerms && (
            <div className='form-row'>
              <div className='form-field'>
                <label className='checkbox' htmlFor='terms'>
                  <Field type='checkbox' id='terms' name='terms' required />
                  <span>
                    Hiermit erkläre ich mich mit den{' '}
                    <a href={`${environment.kirbyBaseUrl}/terms-of-use/plus`} target='_blank' rel='noopener noreferrer'>
                      Nutzungsbedingungen
                    </a>{' '}
                    einverstanden.
                  </span>
                </label>
              </div>
            </div>
          )}

          {isSuccess && showSuccess && (
            <div className='form-field'>
              <div className='notification is-success'>Erfolgreich gespeichert</div>
            </div>
          )}
          {error && (
            <div className='form-field'>
              <div className='notification is-error'>{error.message}</div>
            </div>
          )}

          <Styled.BtnContainer className='form-field'>
            {additionalButton}
            <Button type='submit' isLoading={isLoading || isValidating} disabled={isLoading || isValidating}>
              {buttonText}
            </Button>
          </Styled.BtnContainer>
        </Styled.Form>
      )}
    </Formik>
  );
};

export default UserCompanyDetailsForm;
