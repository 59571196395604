import { Color } from '@solid/shared/styles/colors';
import { Notification } from '@solid/shared/ui';
import { Button as StyledButton } from '@solid/shared/ui/Button.styles';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 400px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background: ${transparentize(0.9, Color.Blue_500_Primary)};
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const UploadButton = styled(StyledButton).attrs({ as: 'label' })``;

export const ExampleFileDownloadLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 5px;

  > svg {
    width: 20px;
    height: 20px;
    margin-top: 2px;
  }
`;

export const NotificationInfo = styled(Notification)`
  color: ${Color.Neutral_200_OffWhite};

  hr {
    border-color: ${Color.Neutral_500};
    background-color: ${Color.Neutral_500};
  }
`;
