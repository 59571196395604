import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import jwtDecode from 'jwt-decode';
import ms from 'ms';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Yup } from '@solid/shared/services/validation.service';
import * as authService from '../../services/auth.service';
import styles from '../common/Forms.module.scss';
import PrivacyField from './PrivacyField';
import ToSField from './ToSField';

const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Bitte geben Sie ein neues Passwort an')
    .min(8, 'Bitte wählen Sie ein längeres Passwort (min. 8 Zeichen)')
    .max(128, 'Bitte wählen Sie ein kürzeres Passwort (max. 128 Zeichen)')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Bitte nutzen Sie mindestens 1 Sonderzeichen (@,!,#,& etc)'),
  newPasswordRepeat: Yup.string().when('newPassword', {
    is: val => (val && val.length > 0 ? true : false),
    then: () => Yup.string().oneOf([Yup.ref('newPassword')], 'Beide Passwörter müssen übereinstimmen'),
  }),
});

const ResetPasswordForm: React.FC = () => {
  const { token: resetToken } = useParams<{ token: string }>();
  const [isLegacyUser, setIsLegacyUser] = useState(false);
  const navigate = useNavigate();

  const {
    mutate: resetPassword,
    error,
    isLoading,
    isSuccess,
  } = useMutation<void, Error, string>(async newPassword => {
    await authService.resetPassword(newPassword, resetToken);
    setTimeout(() => navigate('/login'), ms('3sec'));
  });

  useEffect(() => {
    if (!resetToken) {
      return;
    }
    try {
      const tokenPayload = jwtDecode(resetToken);
      setIsLegacyUser((tokenPayload as { termsRequired: boolean }).termsRequired);
    } catch (e) {
      console.error(`Failed to decode password reset JWT: ${e.message}`);
    }
  }, [resetToken]);

  return (
    <Formik
      initialValues={{ newPassword: '', newPasswordRepeat: '' }}
      onSubmit={values => resetPassword(values.newPassword)}
      validationSchema={ResetPasswordSchema}>
      {({ isValid }) => (
        <Form>
          <div className='form-field'>
            <label htmlFor='reset-password-new-password'>Neues Passwort*</label>
            <div className='control'>
              <Field
                type='password'
                name='newPassword'
                id='reset-password-new-password'
                autoComplete='new-password'
                required
              />
            </div>
            <ErrorMessage component='p' className='help is-danger' name='newPassword' />
          </div>
          <div className='form-field'>
            <label htmlFor='reset-password-new-password-repeat'>Neues Passwort (Wdh.)*</label>
            <div className='control'>
              <Field
                type='password'
                name='newPasswordRepeat'
                id='reset-password-new-password-repeat'
                autoComplete='new-password'
                required
              />
            </div>
            <ErrorMessage component='p' className='help is-danger' name='newPasswordRepeat' />
          </div>

          {isLegacyUser === true && (
            <>
              <ToSField />

              <PrivacyField />
            </>
          )}

          <div className='form-field'>
            {error && <div className='notification is-error'>{error.message}</div>}
            {isSuccess && (
              <div className='notification is-success'>
                Sie haben ihr Passwort erfolgreich geändert und können sich nun mit diesem anmelden.
                <br />
                Sie werden in Kürze weitergeleitet.
              </div>
            )}
          </div>
          <div className='form-field'>
            <button
              className={classNames('btn is-primary', styles.submitBtn, { 'is-loading': isLoading })}
              type='submit'
              disabled={isLoading || !isValid}>
              Passwort aktualisieren
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
