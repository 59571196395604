import { PLAN } from '@solid/shared';
import { Yup } from '@solid/shared/services/validation.service';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { AuthContext } from '../../contexts/auth.context';
import { PaymentPopupContext } from '../../contexts/payment-popup.context';
import * as checkService from '../../services/check.service';
import * as Styled from './CheckNoteForm.styles';

interface CheckNoteFormProps {
  note: string;
  initialNote?: string;
}

const CheckNoteForm: React.FC<CheckNoteFormProps> = ({ note, initialNote }) => {
  const { checkId } = useParams<{ checkId: string }>();
  const { auth } = useContext(AuthContext);
  const paymentPopup = useContext(PaymentPopupContext);
  const [readOnly, setReadOnly] = useState(!!note);

  const canUseNotes = auth.user.plan !== PLAN.FREE;

  const {
    mutate: updateNote,
    error,
    isLoading,
    isSuccess,
  } = useMutation<void, Error, Yup.InferType<typeof checkService.CheckNoteSchema>>(
    ({ note }) => checkService.udpateCheckNote(checkId, note),
    {
      onSuccess: () => {
        setReadOnly(true);
      },
    }
  );

  if (note) {
    return (
      <div className='form-field'>
        <label htmlFor='check-note'>Kommentar</label>
        <Styled.ReadOnly>{note}</Styled.ReadOnly>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{ note: note && canUseNotes ? initialNote : '' }}
      onSubmit={values => updateNote(values)}
      validationSchema={checkService.CheckNoteSchema}>
      {({ values, isValid }) => (
        <Form>
          <div className='form-field'>
            <label htmlFor='check-note'>Kommentar</label>

            {readOnly ? (
              <Styled.ReadOnly>{values.note}</Styled.ReadOnly>
            ) : (
              <Styled.TextareaContainer $isLocked={!canUseNotes} className='control'>
                {!canUseNotes && (
                  <Styled.PaywallOverlay>
                    <p>Upgraden Sie zur PLUS Variante, um die Kommentarfunktion nutzen zu können.</p>

                    <button type='button' className='btn is-primary' onClick={() => paymentPopup.setOpen(true)}>
                      Freischalten
                    </button>
                  </Styled.PaywallOverlay>
                )}
                <Field as={Styled.NoteTextarea} rows={4} name='note' id='check-note' required />
              </Styled.TextareaContainer>
            )}

            <ErrorMessage component='p' className='help is-danger' name='note' />
          </div>

          {error && (
            <div className='form-field'>
              <div className='notification is-error'>{error.message}</div>
            </div>
          )}
          {isSuccess && (
            <div className='form-field'>
              <div className='notification is-success'>Kommentar gespeichert.</div>
            </div>
          )}

          {!readOnly && (
            <div className='form-field'>
              <button
                className={classNames('btn is-primary', { 'is-loading': isLoading })}
                type='submit'
                disabled={isLoading || !isValid || !canUseNotes}>
                Kommentar speichern
              </button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CheckNoteForm;
