import { Color } from '@solid/shared/styles/colors';
import { Button } from '@solid/shared/ui';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Entry = styled.div<{ $hasParent: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${Color.Neutral_200_OffWhite};
  cursor: initial;
  border-radius: ${props => props.theme.borderRadius.small};
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  color: ${Color.Neutral_800};

  ${props =>
    props.$hasParent &&
    css`
      margin-left: 6.5rem;
      margin-top: -0.75rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      ${NameContainer} {
        margin-left: 0;
      }
    `}

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  @media (${props => props.theme.breakpoint.md.up}) {
    flex-wrap: nowrap;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 84px;
`;

export const Date = styled.div`
  display: inline-block;
  cursor: help;
  text-align: center;
  font-weight: ${props => props.theme.fontWeight.semibold};
`;

export const Type = styled(Button)`
  padding: 4px 6px;
  min-width: 0;
  border-radius: ${props => props.theme.borderRadius.small};
  background-color: ${transparentize(0.75, Color.Blue_500_Primary)};
  color: ${Color.Blue_500_Primary};
  font-size: 11px;
  font-weight: ${props => props.theme.fontWeight.semibold};
  line-height: 1;
  box-shadow: none !important;

  &:hover:not(:disabled) {
    color: ${Color.Neutral_0_White};
  }
`;

export const NameContainer = styled.div`
  margin: 0 1rem 0 1.25rem;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
`;

export const UserName = styled.div`
  color: ${Color.Neutral_600};
  font-size: 12px;
`;

export const ItemName = styled.div`
  font-size: 18px;
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const Btn = styled.div`
  flex-basis: 100%;
  margin-left: auto;
  margin-top: 1rem;
  box-shadow: none !important;

  @media (${props => props.theme.breakpoint.md.up}) {
    flex-basis: auto;
    margin-top: 0;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;
  flex-grow: 1;
  width: 100%;
  margin-top: 0.75rem;

  @media (${props => props.theme.breakpoint.md.up}) {
    flex-direction: row;
    width: auto;
    margin: 0;
  }
`;
